import React, { useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import { SourceContext } from "../../contexts/Sourcecontext";

const MobileCombCO2MonthlyChart = ({ reportingPeriod }) => {
  const { separatedFleetsT } = useContext(SourceContext);

  const chartData = Object.entries(separatedFleetsT).reduce(
    (acc, [key, facilitiesArray]) => {
      // Extract the reporting period from the key
      const keyParts = key.split("_");
      const keyPeriod = keyParts[keyParts.length - 1]; // Extract the reporting period (e.g., "08/2017 - 08/2018")

      // Skip if the reporting period doesn't match
      if (keyPeriod !== reportingPeriod) {
        return acc;
      }

      facilitiesArray.forEach((fleet) => {
        const emissions = fleet?.data?.emissions || [];

        // Extract month and year based on the key format
        const monthYear = keyParts[keyParts.length - 2]; // Extract "April 2018" part
        const [month, year] = monthYear.split(" ");

        // Calculate the total eqCO2 for this facility
        const fleetTotalCO2 = emissions.reduce(
          (sum, emission) => sum + (emission.eqco2 || 0),
          0
        );

        // Check if the month/year already exists in the labels array
        const existingIndex = acc.labels.findIndex(
          (label) => label === `${month} ${year}`
        );

        if (existingIndex > -1) {
          // If the month already exists, add to its eqCO2 value
          acc.values[existingIndex] += fleetTotalCO2;
        } else {
          // Otherwise, add a new month/year entry
          acc.labels.push(`${month} ${year}`);
          acc.values.push(fleetTotalCO2);
        }
      });

      return acc;
    },
    { labels: [], values: [] }
  );

  const data = {
    labels: chartData.labels, // Month/Year labels
    datasets: [
      {
        label: "CO2 Emissions (eqCO2) by Month",
        data: chartData.values, // eqCO2 values
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        hoverOffset: 4,
      },
    ],
  };

  // Return a message if there is no data for the selected reporting period
  if (chartData.labels.length === 0) {
    return <p>No data available for the selected reporting period.</p>;
  }

  return (
    <div>
      <h3>CO2 Emissions (eqCO2) by Month for Reporting Period: {reportingPeriod}</h3>
      <Doughnut data={data} />
    </div>
  );
};

export default MobileCombCO2MonthlyChart;
