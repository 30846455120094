// import { useContext, useState } from "react";
// import { SourceContext } from "../contexts/Sourcecontext";
// import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, BarElement, Tooltip, Legend, Title, CategoryScale, LinearScale } from "chart.js";

// ChartJS.register(BarElement, Tooltip, Legend, Title, CategoryScale, LinearScale);

// const StationaryCombCO2MonthlyBarChart = () => {
//   const { separatedFacilitiesT } = useContext(SourceContext);
//   const [showPercentage, setShowPercentage] = useState(false); // State to toggle between percentage and actual CO2

//   // Reduce the separatedFacilitiesT data to aggregate eqCO2 by month/year
//   const chartData = Object.entries(separatedFacilitiesT).reduce(
//     (acc, [key, facilitiesArray]) => {
//       facilitiesArray.forEach((facility) => {
//         const emissions = facility?.data?.emissions || [];

//         // Extract month and year from the key
//         const keyParts = key.split("_");
//         const monthYear = keyParts[keyParts.length - 2]; // This should give "April 2018"
//         const [month, year] = monthYear.split(" ");

//         // Calculate the total eqCO2 for this facility
//         const facilityTotalCO2 = emissions.reduce(
//           (sum, emission) => sum + (emission.eqco2 || 0),
//           0
//         );

//         // Check if the month/year already exists in the labels array
//         const existingIndex = acc.labels.findIndex(
//           (label) => label === `${month} ${year}`
//         );

//         if (existingIndex > -1) {
//           // If the month already exists, add to its eqCO2 value
//           acc.values[existingIndex] += facilityTotalCO2;
//         } else {
//           // Otherwise, add a new month/year entry
//           acc.labels.push(`${month} ${year}`);
//           acc.values.push(facilityTotalCO2);
//         }
//       });

//       return acc;
//     },
//     { labels: [], values: [] }
//   );

//   // Calculate the total CO2 across all months to calculate percentages
//   const totalCO2 = chartData.values.reduce((sum, value) => sum + value, 0);

//   // Create the chart data for Chart.js
//   const chartDataForChartJS = {
//     labels: chartData.labels,
//     datasets: [
//       {
//         data: chartData.labels.map((label, index) => {
//           // Toggle between actual total CO2 and percentage
//           if (showPercentage) {
//             return (chartData.values[index] / totalCO2) * 100; // Percentage of total CO2
//           } else {
//             return chartData.values[index]; // Actual total CO2
//           }
//         }),
//         backgroundColor: chartData.labels.map(
//           (_, index) => `hsl(${(index * 360) / chartData.labels.length}, 100%, 60%)`
//         ), // Dynamic colors for each segment
//         borderColor: "white",
//         borderWidth: 2,
//       },
//     ],
//   };

//   // Chart options for horizontal orientation
//   const options = {
//     responsive: true,
//     indexAxis: 'y', // Horizontal bar chart
//     plugins: {
//       legend: {
//         display: false,
//       },
//       tooltip: {
//         callbacks: {
//           label: (tooltipItem) => {
//             const value = tooltipItem.raw;
//             return showPercentage
//               ? `${value.toFixed(2)}% of total CO2`
//               : `${value.toFixed(2)} CO2e`;
//           },
//         },
//       },
//     },
//     scales: {
//       x: {
//         ticks: {
//           color: "white", // X-axis labels color
//         },
//         title: {
//           display: true,
//           text: showPercentage ? "% of Total CO2" : "Total CO2 (CO2e)",
//           color: "white",
//         },
//       },
//       y: {
//         ticks: {
//           color: "white", // Y-axis labels color
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <h2>Total eqCO2 for Each Month (Horizontal Bar Chart)</h2>

//       {/* Button to toggle between percentage and actual CO2 */}
//       <button onClick={() => setShowPercentage(!showPercentage)}>
//         {showPercentage ? "Show Actual CO2" : "Show % of Total CO2"}
//       </button>

//       <div >
//         <Bar data={chartDataForChartJS} options={options} />
//       </div>
//     </div>
//   );
// };

// export default StationaryCombCO2MonthlyBarChart;

import { useContext, useState } from "react";
import { SourceContext } from "../../contexts/Sourcecontext";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register elements globally
ChartJS.register(
  ArcElement, // For Doughnut and Pie Charts
  CategoryScale, // For Bar/Line Charts
  LinearScale, // For Bar/Line Charts
  BarElement, // For Bar Charts
  Title,
  Tooltip,
  Legend
);

const MobileCombCO2MonthlyBarChart = ({ reportingPeriod }) => {
  const { separatedFleetsT } = useContext(SourceContext);
  const [showPercentage, setShowPercentage] = useState(false); // Toggle for percentage vs. actual CO2

  const chartData = Object.entries(separatedFleetsT).reduce(
    (acc, [key, facilitiesArray]) => {
      // Extract reporting period from the key
      const keyParts = key.split("_");
      const keyPeriod = keyParts[keyParts.length - 1]; // Reporting period part of the key

      // Skip if the reporting period doesn't match
      if (keyPeriod !== reportingPeriod) {
        return acc;
      }

      facilitiesArray.forEach((fleet) => {
        const emissions = fleet?.data?.emissions || [];

        // Extract month and year from the key
        const monthYear = keyParts[keyParts.length - 2]; // Example: "April 2018"
        const [month, year] = monthYear.split(" ");

        // Calculate total eqCO2 for this facility
        const fleetTotalCO2 = emissions.reduce(
          (sum, emission) => sum + (emission.eqco2 || 0),
          0
        );

        // Check if the month/year already exists in the labels array
        const existingIndex = acc.labels.findIndex(
          (label) => label === `${month} ${year}`
        );

        if (existingIndex > -1) {
          // If the month already exists, add to its eqCO2 value
          acc.values[existingIndex] += fleetTotalCO2;
        } else {
          // Otherwise, add a new month/year entry
          acc.labels.push(`${month} ${year}`);
          acc.values.push(fleetTotalCO2);
        }
      });

      return acc;
    },
    { labels: [], values: [] }
  );

  // Calculate total CO2 for percentages
  const totalCO2 = chartData.values.reduce((sum, value) => sum + value, 0);

  // Format the data for the bar chart
  const data = {
    labels: chartData.labels, // Month/Year labels
    datasets: [
      {
        label: showPercentage ? "CO2 Emissions (%)" : "CO2 Emissions (eqCO2)",
        data: showPercentage
          ? chartData.values.map((value) => ((value / totalCO2) * 100).toFixed(2))
          : chartData.values,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  };

  return (
    <div>
      <h3>CO2 Emissions (eqCO2) for Mobile Combustion by Month for Reporting Period: {reportingPeriod}</h3>
      <div style={{ marginBottom: "10px" }}>
        <button onClick={() => setShowPercentage(!showPercentage)}>
          {showPercentage ? "Show Absolute Values" : "Show Percentages"}
        </button>
      </div>
      {chartData.labels.length > 0 ? (
        <Bar
          data={data}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: true,
              },
              tooltip: {
                callbacks: {
                  label: (context) =>
                    showPercentage
                      ? `${context.raw}%`
                      : `${context.raw} eqCO2`,
                },
              },
            },
          }}
        />
      ) : (
        <p>No data available for the selected reporting period.</p>
      )}
    </div>
  );
};

export default MobileCombCO2MonthlyBarChart;

