import React, { useState, useEffect, useContext } from 'react';
import {
  TableContainer, Paper, Drawer, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Tabs, Tab, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { ArrowDownward, Delete } from '@mui/icons-material';
import { SourceContext } from '../contexts/Sourcecontext';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import mobilesources_logo from './Logos/Mobile_sources_logo.png';
const apiUrl = process.env.REACT_APP_API_URL_BACKEND


const MobileResourceDialog = ({ open, onClose, onSubmit, source, month, year, company, reportingPeriod }) => {
  const initialFormData = {
    id: '',
    fleetDescription: '',
    vehicles: [{ vehicleType: '', vehicleSubcategory: '',vehicleYear: '', vehicleId :'',vehicleFuel :'',fuelQuantity:'',units: '', distanceTravelled:'' }]
  };
  // const { parentCompany } = useContext(SourceContext);
  const { getAccessTokenSilently } = useAuth0();
  const { parentCompany, fleets = [], setFleets } = useContext(SourceContext);
  const { superStatmobilecomb, separatedFleetsT, fetchAndProcessData } = useContext(SourceContext);
  const [dialogData, setDialogData] = useState(null);


  // const localStorageKey = `fleetData_${company}_${month}_${year}_${reportingPeriod}`;

  // const [fleets, setFleets] = useState([{ id: 1, data: initialFormData }]);
  const [currentTab, setCurrentTab] = useState(0);




  useEffect(() => {
    if (open) {
      fetchAndProcessData();
    }
  }, []);


  useEffect(() => {
    const key = `mobilesources_${company}_${month}_${reportingPeriod}`;
    setDialogData(separatedFleetsT[key] || []);
  }, [separatedFleetsT, company, month, reportingPeriod]);


  useEffect(() => {
    const key = `mobilesources_${company}_${month}_${reportingPeriod}`;
    const dataForKey = separatedFleetsT[key] || [];
    setDialogData(dataForKey);

    setFleets(dataForKey.length > 0 ? dataForKey : [{
      id: '',
      data: {
        fleetDescription: '',
        vehicles: [{ vehicleType: '', vehicleSubcategory: '',vehicleYear: '', vehicleId :'',vehicleFuel :'',fuelQuantity:'',units: '', distanceTravelled:'' }],
      }
    }]); // Reset to empty array if no data
  }, [separatedFleetsT]);


  // useEffect(() => {
  //   const savedFleets = JSON.parse(localStorage.getItem(localStorageKey));
  //   if (savedFleets) {
  //     setFleets(savedFleets);
  //   }
  // }, [localStorageKey]);

  // useEffect(() => {
  //   localStorage.setItem(localStorageKey, JSON.stringify(fleets));
  // }, [fleets, localStorageKey]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };


  const handleChange = (e, fleetIndex, vehicleIndex = null) => {
    const { name, value } = e.target;
    const updatedFleets = fleets.map((fleet, index) => {
      if (index === fleetIndex) {
        if (name === 'id') {
          // Update the facility id
          return { ...fleet, id: value };
      } else if (vehicleIndex !== null) {
          const updatedVehicles = fleet.data.vehicles.map((vehicle, idx) => {
            if (idx === vehicleIndex) {
              if (name === 'vehicleType') {
                if (country === 'USA') {
                return { ...vehicle, [name]: value, vehicleFuel: vehicleTypeToFuelMapUSA[value] || '' }};
                if (country === 'Australia') {
                  return { ...vehicle, [name]: value, vehicleFuel: vehicleTypeToFuelMapAustralia[value] || '' }};
              }
              return { ...vehicle, [name]: value };
            }
            return vehicle;
          });
          return { ...fleet, data: { ...fleet.data, vehicles: updatedVehicles } };
        }
        return { ...fleet, data: { ...fleet.data, [name]: value } };
      }
      return fleet;
    });
    setFleets(updatedFleets);
  };

  const addVehicle = (fleetIndex) => {
    const updatedFleets = fleets.map((fleet, index) => {
      if (index === fleetIndex) {
        return {
          ...fleet,
          data: { ...fleet.data, vehicles: [...fleet.data.vehicles, { vehicleType: '', vehicleSubcategory: '', vehicleYear:'', vehicleId :'',vehicleFuel :'',fuelQuantity:'',units: '', distanceTravelled:'' }] }
        };
      }
      return fleet;
    });
    setFleets(updatedFleets);
  };

  const deleteVehicle = (fleetIndex, vehicleIndex) => {
    const updatedFleets = fleets.map((fleet, index) => {
      if (index === fleetIndex) {
        const updatedVehicles = fleet.data.vehicles.filter((_, idx) => idx !== vehicleIndex);
        return { ...fleet, data: { ...fleet.data, vehicles: updatedVehicles } };
      }
      return fleet;
    });
    setFleets(updatedFleets);
  };

  // const addFleet = () => {
  //   setFleets([...fleets, { id: fleets.length + 1, data: initialFormData }]);
  //   setCurrentTab(fleets.length); // Switch to the new fleet tab
  // };


 
  const addFleet = () => {
    // Generate a new facility ID, ensure it's a string
    const newFacilityId = fleets.length > 0 
      ? (Math.max(...fleets.map(f => parseInt(f.id, 10))) + 1).toString() 
      : " ";
    
      setFleets([...fleets, { id: newFacilityId, data: initialFormData }]);
    setCurrentTab(fleets.length); // Switch to the new facility tab
  };

  const deleteFleet = (fleetId) => {
    const updatedFleets = fleets.filter(fleet => fleet.id !== fleetId);
    setFleets(updatedFleets);
    if (currentTab >= updatedFleets.length) {
      setCurrentTab(updatedFleets.length - 1);
    }
  };
// `${apiUrl}/sourceselection`

  const handleSubmit = async() => {
    onSubmit(fleets.map(fleet => ({ ...fleet.data, month, source })));
    const token = await getAccessTokenSilently();

    try {
      
      // const response =  axios.post('http://localhost:5000/api/mobilecombustion', {
        const response =  axios.post(`${apiUrl}/mobilecombustion`, {
        parentcompany: parentCompany.name,
        parentcompany_country: parentCompany.country,
        company: company,
        period: reportingPeriod,
        month: month, 
        fleets: fleets,
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    }
    catch (error) {
      console.error('Error adding company:', error.response || error.message);
    }
    // localStorage.removeItem(localStorageKey);
    console.log("fleets",fleets)

    onClose();
  };
//////////////////////////////////////////////
  const vehicleTypesUSA = [
      'Diesel Passenger Cars',
      'Diesel Light-Duty Trucks',
      'Diesel Medium- and Heavy-Duty Vehicles',
      'CNG Light-Duty Vehicles',
      'CNG Heavy-Duty Vehicles',
      'CNG Buses',
      'LPG Light-Duty Vehicles',
      'LPG Medium- and Heavy-Duty Vehicles',
      'LPG Non-Road Vehicles',
      'LNG Medium- and Heavy-Duty Vehicles',
      'Ethanol Light-Duty Vehicles',
      'Ethanol Medium- and Heavy-Duty Vehicles',
      'Ethanol Buses',
      'Biodiesel Light-Duty Vehicles',
      'Biodiesel Medium- and Heavy-Duty Vehicles',
      'Biodiesel Buses',
      'Residual Oil Ships and Boats',
      'Diesel Ships and Boats',
      'Gasoline Ships and Boats',
      'Diesel Locomotives',
      'Gasoline Agricultural Equipment',
      'Diesel Agricultural Equipment',
      'Gasoline Construction Equipment',
      'Diesel Construction Equipment',
      'Jet Fuel Aircraft',
      'Aviation Gasoline Aircraft',
      'Biodiesel Non-Road Vehicles',
      'Other Diesel Non-Road Vehicles',
      'Other Gasoline Non-Road Vehicles'
  
  ];


  const vehicleTypeToFuelMapUSA = {
    'Diesel Passenger Cars': 'Diesel',
    'Diesel Light-Duty Trucks': 'Diesel',
    'Diesel Medium- and Heavy-Duty Vehicles': 'Diesel',
    'CNG Light-Duty Vehicles': 'CNG',
    'CNG Heavy-Duty Vehicles': 'CNG',
    'CNG Buses': 'CNG',
    'LPG Light-Duty Vehicles': 'LPG',
    'LPG Medium- and Heavy-Duty Vehicles': 'LPG',
    'LPG Non-Road Vehicles': 'LPG',
    'LNG Medium- and Heavy-Duty Vehicles': 'LNG',
    'Ethanol Light-Duty Vehicles': 'Ethanol',
    'Ethanol Medium- and Heavy-Duty Vehicles': 'Ethanol',
    'Ethanol Buses': 'Ethanol',
    'Biodiesel Light-Duty Vehicles': 'Biodiesel',
    'Biodiesel Medium- and Heavy-Duty Vehicles': 'Biodiesel',
    'Biodiesel Buses': 'Biodiesel',
    'Residual Oil Ships and Boats': 'Residual Oil',
    'Diesel Ships and Boats': 'Diesel',
    'Gasoline Ships and Boats': 'Gasoline',
    'Diesel Locomotives': 'Diesel',
    'Gasoline Agricultural Equipment': 'Gasoline',
    'Diesel Agricultural Equipment': 'Diesel',
    'Gasoline Construction Equipment': 'Gasoline',
    'Diesel Construction Equipment': 'Diesel',
    'Jet Fuel Aircraft': 'Jet Fuel',
    'Aviation Gasoline Aircraft': 'Aviation Gasoline',
    'Biodiesel Non-Road Vehicles': 'Biodiesel',
    'Other Diesel Non-Road Vehicles': 'Diesel',
    'Other Gasoline Non-Road Vehicles': 'Gasoline'
  };
  const fuelTypesUSA = [
    'Diesel',
    'CNG',
    'LPG',
    'LNG',
    'Ethanol',
    'Biodiesel',
    'Residual Oil',
    'Gasoline',
    'Jet Fuel',
    'Aviation Gasoline'
  ];

  const vehicleSubcategoriesUSA = {
    'Passenger Car': ['Compact', 'Mid-size', 'Full-size', 'SUV', 'Minivan', 'Hybrid', 'Electric'],
    'Light Duty Truck': ['Pickup Truck', 'Van', 'SUV'],
    'Heavy Duty Truck': ['Single Unit Truck', 'Combination Truck', 'Tractor Trailer'],
    'Bus': ['School Bus', 'Transit Bus', 'Coach Bus'],
    'Motorcycle': ['Standard', 'Cruiser', 'Sport Bike', 'Touring', 'Scooter'],
    'Passenger Train': ['Light Rail', 'Commuter Rail', 'High-speed Rail'],
    'Freight Train': ['Diesel Locomotive', 'Electric Locomotive'],
    'Airplane': ['Regional Jet', 'Narrow-body Jet', 'Wide-body Jet', 'Cargo Plane'],
    'Ship': ['Container Ship', 'Bulk Carrier', 'Tanker', 'Ferry'],
    'Other': ['Other'],
  };
  //////////////////////////////////////////////////////


  const vehicleTypesAustralia = [
    'Cars and Light Commercial Vehicles - Gasoline',
    'Cars and Light Commercial Vehicles - Diesel oil',
    'Cars and Light Commercial Vehicles - Liquefied petroleum gas (LPG)',
    'Cars and Light Commercial Vehicles - Fuel oil',
    'Cars and Light Commercial Vehicles - Ethanol',
    'Cars and Light Commercial Vehicles - Biodiesel',
    'Cars and Light Commercial Vehicles - Renewable diesel',
    'Cars and Light Commercial Vehicles - Other biofuels',

    'Light Duty Vehicles - Compressed natural gas',
    'Light Duty Vehicles - Liquefied natural gas',

    'Heavy Duty Vehicles - Compressed natural gas',
    'Heavy Duty Vehicles - Liquefied natural gas',
    'Heavy Duty Vehicles - Diesel oil - Euro IV or higher',
    'Heavy Duty Vehicles - Diesel oil - Euro III',
    'Heavy Duty Vehicles - Diesel oil - Euro I',
    'Heavy Duty Vehicles - Renewable diesel – Euro IV or higher',
    'Heavy Duty Vehicles - Renewable diesel – Euro III',
    'Heavy Duty Vehicles - Renewable diesel – Euro I',

    'Aviation - Gasoline for use as fuel in an aircraft',
    'Aviation - Kerosene for use as fuel in an aircraft',
    'Aviation - Renewable aviation kerosene'
  ];

  const vehicleTypeToFuelMapAustralia = {
    'Cars and Light Commercial Vehicles - Gasoline': 'Gasoline',
    'Cars and Light Commercial Vehicles - Diesel oil': 'Diesel oil',
    'Cars and Light Commercial Vehicles - Liquefied petroleum gas (LPG)': 'LPG',
    'Cars and Light Commercial Vehicles - Fuel oil': 'Fuel oil',
    'Cars and Light Commercial Vehicles - Ethanol': 'Ethanol',
    'Cars and Light Commercial Vehicles - Biodiesel': 'Biodiesel',
    'Cars and Light Commercial Vehicles - Renewable diesel': 'Renewable diesel',
    'Cars and Light Commercial Vehicles - Other biofuels': 'Other biofuels',
    
    'Light Duty Vehicles - Compressed natural gas': 'CNG',
    'Light Duty Vehicles - Liquefied natural gas': 'LNG',
    
    'Heavy Duty Vehicles - Compressed natural gas': 'CNG',
    'Heavy Duty Vehicles - Liquefied natural gas': 'LNG',
    'Heavy Duty Vehicles - Diesel oil - Euro IV or higher': 'Diesel oil - Euro IV or higher',
    'Heavy Duty Vehicles - Diesel oil - Euro III': 'Diesel oil - Euro III',
    'Heavy Duty Vehicles - Diesel oil - Euro I': 'Diesel oil - Euro I',
    'Heavy Duty Vehicles - Renewable diesel – Euro IV or higher': 'Renewable diesel – Euro IV or higher',
    'Heavy Duty Vehicles - Renewable diesel – Euro III': 'Renewable diesel – Euro III',
    'Heavy Duty Vehicles - Renewable diesel – Euro I': 'Renewable diesel – Euro I',
    
    'Aviation - Gasoline for use as fuel in an aircraft': 'Aviation Gasoline',
    'Aviation - Kerosene for use as fuel in an aircraft': 'Kerosene',
    'Aviation - Renewable aviation kerosene': 'Renewable aviation kerosene'
  };

  const fuelTypesAustralia = [
    'Gasoline',
    'Diesel oil',
    'Liquefied petroleum gas (LPG)',
    'Fuel oil',
    'Ethanol',
    'Biodiesel',
    'Renewable diesel',
    'Other biofuels',
    'Compressed natural gas (CNG)',
    'Liquefied natural gas (LNG)',
    'Diesel oil - Euro IV or higher',
    'Diesel oil - Euro III',
    'Diesel oil - Euro I',
    'Renewable diesel - Euro IV or higher',
    'Renewable diesel - Euro III',
    'Renewable diesel - Euro I',
    'Aviation Gasoline',
    'Kerosene',
    'Renewable aviation kerosene'
  ];
  const country = parentCompany.country;

  

  const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleUnitChange = (e, fleetIndex, fuelIndex) => {
      const { value } = e.target;
      const updatedFleets = fleets.map((fleets, index) => {
          if (index === fleetIndex) {
              const updatedFuels = fleets.data.fuels.map((fuel, idx) => {
                  if (idx === fuelIndex) {
                      return { ...fuel, units: value };
                  }
                  return fuel;
              });
              return { ...fleets, data: { ...fleets.data, fuels: updatedFuels } };
          }
          return fleets;
      });
      setFleets(updatedFleets);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: '50vw',
          padding: 2,
          height: '100%',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={onClose}
        >
          <ArrowDownward />
        </IconButton>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="fleet tabs">
          {fleets.map((fleet, index) => (
            <Tab key={fleet.id} label={`Fleet ${fleet.id}`} />
          ))}
          <Tab label="Add Fleet" onClick={addFleet} />
          <Tab label="Submit Data" />
        </Tabs>
        {currentTab < fleets.length ? (
          <Box p={2}>
            <Typography>
            Mobile combustion involves the burning of fuels in vehicles and mobile equipment, 
            such as cars, trucks, airplanes, and construction machinery. 
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom>
              Enter Data for Fleet {fleets[currentTab].id}
              <DialogTitle>Enter Data for {source}</DialogTitle>
              <Typography>Company: {company}</Typography>
              <Typography>Reporting Period: {reportingPeriod}</Typography>
              <Typography>Month and Year: {month}</Typography>
         {/* <Typography>Year: {year}</Typography> */}
            </Typography>
            <img src={mobilesources_logo} alt="Logo" style={{ height: '170px', marginLeft: '6px'}} />
             </Box>
            <Typography variant="subtitle1" gutterBottom>
              {month} {year}
            </Typography>
            <TextField
              label="Fleet ID"
              name="id"
              value={fleets[currentTab].id}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Fleet Description"
              name="fleetDescription"
              value={fleets[currentTab].data.fleetDescription}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            {/* <TextField
              label="Source Area"
              name="sourceArea"
              value={fleets[currentTab].data.sourceArea}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            /> */}
            <Typography variant="h6" gutterBottom>
              Vehicle Details
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vehicle Type</TableCell>
                  {/* <TableCell>Vehicle Subcategory</TableCell> */}
                  <TableCell>Vehicle ID</TableCell>
                  <TableCell>Vehicle Fuel</TableCell>
                  <TableCell>Vehicle Year</TableCell>
                  <TableCell>Fuel Quantity</TableCell>
                  <TableCell>Fuel Units</TableCell>
                  <TableCell>Distance Travelled</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fleets[currentTab] && fleets[currentTab].data.vehicles.map((vehicle, vehicleIndex) => (
                  <TableRow key={vehicleIndex}>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel>Vehicle Type</InputLabel>
                        <Select
                          name="vehicleType"
                          value={vehicle.vehicleType}
                          onChange={(e) => handleChange(e, currentTab, vehicleIndex)}
                          fullWidth
                          MenuProps={{ PaperProps: { style: { maxHeight: 300, width: 250 } } }}
                          style={{
                              width: '100%',
                              marginTop: '10px',
                              fontSize: '16px',
                              borderRadius: '4px', // Add border radius for a smoother look
                              borderColor: '#ced4da', // Add border color
                              backgroundColor: '#fff', // Set background color
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
                          }}
                          inputProps={{
                              style: { fontSize: '16px' }, // Set font size for consistency
                          }}
                        >
                          {country === 'USA' && vehicleTypesUSA.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                           {country === 'Australia' && vehicleTypesAustralia.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="vehicleId"
                        value={vehicle.vehicleId}
                        onChange={(e) => handleChange(e, currentTab, vehicleIndex)}
                        fullWidth
                      />
                    </TableCell>
                    
                    <TableCell>
                    <FormControl fullWidth>
                        <InputLabel>Fuel Type</InputLabel>
                        <Select
                          name="vehicleFuel"
                          value={vehicle.vehicleFuel}
                          onChange={(e) => handleChange(e, currentTab, vehicleIndex)}
                          fullWidth
                          MenuProps={{ PaperProps: { style: { maxHeight: 300, width: 250 } } }}
                          style={{
                              width: '100%',
                              marginTop: '10px',
                              fontSize: '16px',
                              borderRadius: '4px', // Add border radius for a smoother look
                              borderColor: '#ced4da', // Add border color
                              backgroundColor: '#fff', // Set background color
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
                          }}
                          inputProps={{
                              style: { fontSize: '16px' }, // Set font size for consistency
                          }}
                        >
                          {country === 'USA' && fuelTypesUSA.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                          {country === 'Australia' && fuelTypesAustralia.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell>
                      <TextField
                        name="vehicleYear"
                        value={vehicle.vehicleYear}
                        onChange={(e) => handleChange(e, currentTab, vehicleIndex)}
                        fullWidth
                      />
                    </TableCell>

                    <TableCell>
                      <TextField
                        name="fuelQuantity"
                        value={vehicle.fuelQuantity}
                        onChange={(e) => handleChange(e, currentTab, vehicleIndex)}
                        fullWidth
                      />
                    </TableCell>

                    <TableCell>
                      <TextField
                        name="units"
                        value={vehicle.units}
                        onChange={(e) => handleChange(e, currentTab, vehicleIndex)}
                        fullWidth
                      />
                    </TableCell>
            
                    <TableCell>
                      <TextField
                        name="distanceTravelled"
                        value={vehicle.distanceTravelled}
                        onChange={(e) => handleChange(e, currentTab, vehicleIndex)}
                        fullWidth
                      />
                    </TableCell>

                    <TableCell>
                      <IconButton onClick={() => deleteVehicle(currentTab, vehicleIndex)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button variant="contained" color="primary" onClick={() => addVehicle(currentTab)}>
              Add Vehicle
            </Button>
          </Box>
        ) : (
          currentTab > fleets.length && (
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Summary
              </Typography>
              {fleets.map((fleet) => (
                <Box key={fleet.id} mb={2} display="flex" alignItems="center">
                  <Box flex={1}>
                    <Typography variant="subtitle1">
                      Fleet {fleet.id}:
                    </Typography>
                    <Typography>Parent Company: {parentCompany.name}</Typography>
                    {/* <Typography>Fleet ID: {fleet.data.fleetId}</Typography> */}
                    <Typography>Fleet Description: {fleet.data.fleetDescription}</Typography>
                    <Typography>Vehicles:</Typography>
                    {/* {fleet.data.vehicles.map((vehicle, idx) => ( */}
                      {/* <Box key={idx} ml={2}> */}

                      <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Vehicle Type</TableCell>
                                {/* <TableCell>Vehicle Sub-category</TableCell> */}
                                <TableCell>Vehicle ID</TableCell>
                                <TableCell>Vehicle Fuel</TableCell>
                                <TableCell>Vehicle Year</TableCell>
                                <TableCell>Fuel Units</TableCell>
                                <TableCell>Fuel Quantity</TableCell>
                                <TableCell>Distance Travelled</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fleet.data.vehicles.map((vehicle, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>{vehicle.vehicleType}</TableCell>
                                  {/* <TableCell>{vehicle.vehicleSubcategory}</TableCell> */}
                                  <TableCell>{vehicle.vehicleId}</TableCell>
                                  <TableCell>{vehicle.vehicleFuel}</TableCell>
                                  <TableCell>{vehicle.vehicleYear}</TableCell>
                                  <TableCell>{vehicle.units}</TableCell>
                                  <TableCell>{vehicle.fuelQuantity}</TableCell>
                                  <TableCell>{vehicle.distanceTravelled}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      {/* </Box> */}
                    {/* // ))} */}
                  </Box>
                  {fleets.length > 1 && (
                  <IconButton onClick={() => deleteFleet(fleet.id)}>
                    <Delete />
                  </IconButton>)}
                </Box>
              ))}
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Submit All Data
                </Button>
                <Button variant="contained" color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          )
        )}
      </Box>
    </Drawer>
  );
};

export default MobileResourceDialog;

