// import React, { useState } from 'react';
// import { Route, Navigate } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';

// const PrivateRoute = ({ component: Component, onRedirecting, ...rest }) => {
//   const { isAuthenticated, isLoading  } = useAuth0();
//   const [showLoading, setShowLoading] = useState(true);

//   if (isLoading || showLoading) {
//     return onRedirecting || <div>Loading...</div>;
//   }

//   setShowLoading(false);

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
        
//         isAuthenticated ? (
          
//           <Component {...props} />
//         ) : (
//           <Navigate to={{ pathname: '/', state: { from: props.location } }} />
//         )
//       }
//     />
//   );
// };

// export default PrivateRoute;
// src/components/PrivateRoute.js
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("A", isAuthenticated)
    // console.log("L", isLoading)
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated]);

  // if (isLoading || !isAuthenticated) {
  //   return <div>Loading...</div>; // Optional loading state
  // }

  return children;
};

export default PrivateRoute;
