import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Doughnut } from "react-chartjs-2";

const Scope3Summary = ({ groupedData }) => {
  // Define upstream and downstream categories
  const upstreamCategories = [
    "Purchased Goods and Services",
    "Capital Goods",
    "Fuel- and Energy-Related Activities",
    "Upstream Transport and Distribution",
    "Business Travel",
    "Employee Commuting",
    "Upstream Leased Assets",
  ];

  const downstreamCategories = [
    "Downstream Transport and Distribution",
    "Processing of Sold Products",
    "Use of Sold Products",
    "End-of-Life Treatment of Sold Products",
    "Downstream Leased Assets",
    "Franchises",
    "Investments",
  ];

  // Helper to calculate emissions
  const calculateEmissions = (categories) => {
    return categories.reduce((total, category) => {
      switch (category) {
        case "Purchased Goods and Services":
          return total + (groupedData?.["Purchased Goods"]?.totalEmissions || 0) +
            (groupedData?.["Purchased Services"]?.totalEmissions || 0);
        case "Capital Goods":
          return total + (groupedData?.["Capital Goods"]?.totalEmissions || 0);
        case "Fuel- and Energy-Related Activities":
          return total + (groupedData?.fuelEnergyEmissions || 0);
        case "Upstream Transport and Distribution":
          return total + (groupedData?.["Upstream Transportation and Distribution"]?.totalEmissions || 0);
        case "Business Travel":
          return total + (groupedData?.businessTravelEmissions || 0);
        case "Employee Commuting":
          return total + (groupedData?.employeeCommutingEmissions || 0);
        case "Upstream Leased Assets":
          return total + (groupedData?.upstreamLeasedAssetsEmissions || 0);
        case "Downstream Transport and Distribution":
          return total + (groupedData?.["Downstream Transportation and Distribution"]?.totalEmissions || 0);
        case "Processing of Sold Products":
          return total + (groupedData?.processingSoldProductsEmissions || 0);
        case "Use of Sold Products":
          return total + (groupedData?.useSoldProductsEmissions || 0);
        case "End-of-Life Treatment of Sold Products":
          return total + (groupedData?.endOfLifeSoldProductsEmissions || 0);
        case "Downstream Leased Assets":
          return total + (groupedData?.downstreamLeasedAssetsEmissions || 0);
        case "Franchises":
          return total + (groupedData?.franchisesEmissions || 0);
        case "Investments":
          return total + (groupedData?.investmentsEmissions || 0);
        default:
          return total;
      }
    }, 0);
  };

  // Calculate upstream and downstream emissions
  const upstreamEmissions = calculateEmissions(upstreamCategories);
  const downstreamEmissions = calculateEmissions(downstreamCategories);
  const totalEmissions = upstreamEmissions + downstreamEmissions;

  // Data for the doughnut chart
  const doughnutData = {
    labels: ["Upstream Emissions", "Downstream Emissions"],
    datasets: [
      {
        data: [
          ((upstreamEmissions / totalEmissions) * 100).toFixed(2),
          ((downstreamEmissions / totalEmissions) * 100).toFixed(2),
        ],
        backgroundColor: ["#4caf50", "#FF5733"], // Upstream (green), Downstream (red)
        hoverBackgroundColor: ["#45a049", "#d94e3f"],
      },
    ],
  };

  return (
    <Box sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      {/* Total Emissions Box */}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h5" sx={{ mb: 1 }}>
          Total Scope 3 Emissions
        </Typography>
        <Typography variant="h4" color="primary">
          {`${(totalEmissions / 1000000).toFixed(3)} MtCO₂e`} {/* Display in Megatonnes */}
        </Typography>
      </Paper>

      {/* Doughnut Chart Box */}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Upstream vs Downstream Emissions
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: 300 }}>
            <Doughnut data={doughnutData} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Scope3Summary;
