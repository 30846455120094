import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
const root = ReactDOM.createRoot(document.getElementById('root'));
const Urlfrontnd = process.env.REACT_APP_API_URL_FRONTEND;

root.render(
  <Auth0Provider
    domain="dev-nswl58leb41j3d0p.us.auth0.com"
    clientId= "UfiH4GSc9Drboxr6nzVNyspue0Euji7v"
    // redirectUri={"http://localhost:3000/app/dashboard"}
    // authorizationParams={{ redirectUri:"http://localhost:3000/app/dashboard" }} this was the working one
    authorizationParams={{ redirectUri:`${Urlfrontnd}/app/dashboard` }}
    cacheLocation="localstorage" // For continuous sessions
  >
    <App />   
  </Auth0Provider>,
  document.getElementById("root")
);    