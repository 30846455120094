import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    // Call loginWithRedirect when the component mounts
    loginWithRedirect();
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts

  return (
    // You can render some loading UI here if needed
    <div>
      Loading...</div>
  );
};

export default LoginButton;
