import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { ResponsiveBar } from '@nivo/bar';
// import { Grid, Box, Typography, Button, Autocomplete, TextField } from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { SourceContext } from '../contexts/Sourcecontext';
import ExcelUploader from './Exceluploader';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box, Button, Table, TableHead, TableBody, TableRow, TableCell, Collapse, Typography, Paper, TableContainer,
  Accordion, AccordionSummary, AccordionDetails
 } from '@mui/material';

 import { Switch } from 'antd';
import * as XLSX from 'xlsx';
import { Flex } from 'antd';
import Scope3EmissionsTable from '../DisplayDash/Scope3_emission_calculated_table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from '../Loadinganimation/Loader';
import Scope3FinalView from '../DisplayDash/Scope3FinalView';
import SupplierTable from './SupplierTable';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL_BACKEND;


const Spendbased_scope3 = () => {
    const [data, setData] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const { parentCompany, company, reportingPeriods, companies, handleCompanySelect } = useContext(SourceContext);
    const [firstReview, setFirstreview]=useState(false);
    const [sortreview, setSortreview]=useState(false);
    const [categoryandsectorview, setCategoryandsectorview]=useState(false);
    const [lastview, setLastview]=useState(false);
    const [calcEmissionView, setcalcEmissionView]=useState(false);
    const [SeparatedScope3Categories, setSeparatedScope3Categories] = useState([]); // Main data from API
    const [expandedCategory2ndview, setexpandedCategory2ndview] = useState(null); // For expanding rows
    const [updatingEntry, setUpdatingEntry] = useState(null); // Track which entry is being updated
    const [expanded, setExpanded] = useState(null);
    const [groupedData, setGroupedData] = useState({});
    const [updatedrow, setUpdatedrow] = useState({});
    const navigate = useNavigate();
    
    const [isFileSelected, setIsFileSelected] = useState(false); // Track file selection


    // State for selected company and reporting period
  const [selectedCompany, setSelectedCompany] = React.useState('');
  const [selectedPeriod, setSelectedPeriod] = React.useState('');
  const periodCompanies = companies[selectedPeriod] || [];
  const [expandedCategories, setExpandedCategories] = useState(new Array(data.length).fill(true)); // Initially set all categories to expanded
   // Options could be from context or a static list
   const companyOptions = company || [];
  //  const periodOptions = reportingPeriods || [];
  const { getAccessTokenSilently } = useAuth0();

  const [firstloading, setfirstLoading] = useState(false);
  const [secondloading, setsecondLoading] = useState(false);
  const [thirdloading, setthirdLoading] = useState(false);
  const [initialloading, setinitialLoading] = useState(false);

  useEffect(() => {
    // Automatically trigger the firstReview after 5 seconds
    if (firstReview) {
      setfirstLoading(true);
    const timer = setTimeout(() => {
      // setFirstReview(true);
      setfirstLoading(false); // Stop loading after the review is triggered
    }, 1000);

    // Clean up the timer in case the component unmounts or something changes
    return () => clearTimeout(timer);
  }

  }, [firstReview]);


  useEffect(() => {
    // Automatically trigger the firstReview after 5 seconds
      setinitialLoading(true);
    const timer = setTimeout(() => {
      // setFirstReview(true);
      setinitialLoading(false); // Stop loading after the review is triggered
    }, 1000);

    // Clean up the timer in case the component unmounts or something changes
    return () => clearTimeout(timer);
  
  }, []);


  useEffect(() => {
    // Automatically trigger the firstReview after 5 seconds
    if (sortreview) {
      setsecondLoading(true);
    const timer = setTimeout(() => {
      // setFirstReview(true);
      setsecondLoading(false); // Stop loading after the review is triggered
    }, 1000);

    // Clean up the timer in case the component unmounts or something changes
    return () => clearTimeout(timer);
  }

  }, [sortreview]);


  useEffect(() => {
    console.log("groupedData.length", groupedData)
    // Automatically trigger the firstReview after 5 seconds
    if ((!groupedData || Object.keys(groupedData).length === 0) && categoryandsectorview ) {
      setthirdLoading(true);
    // const timer = setTimeout(() => {
    //   // setFirstReview(true);
    //   setthirdLoading(false); // Stop loading after the review is triggered
    // }, 3000);

    // Clean up the timer in case the component unmounts or something changes
    // return () => clearTimeout(timer);
  }
   // Stop loading when groupedData is populated
   if (groupedData && Object.keys(groupedData).length > 0) {
    setthirdLoading(false);
  }

  }, [categoryandsectorview, groupedData]);


    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      
      if (file) {
        setIsFileSelected(true); // Set file as selected
      }
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        

         // Create the filterSetData based on the reporting period and company
        const filterSetData = jsonData.filter((entry) => {
          const entryDate = excelSerialDateToJSDate(entry.date); // Convert serial to Date
          const isWithinReportingPeriod = isDateInReportingPeriod(entryDate, selectedPeriod);
          const isCompanySubsidiary = entry.company_name === selectedCompany.subsidiary_name;

          return isWithinReportingPeriod && isCompanySubsidiary;
        }).map((entry) => ({
          ...entry,
          date: formatDate(excelSerialDateToJSDate(entry.date)), // Add formatted date
        }));


        // Summing up the categories
        const categoryTotals = {};
        jsonData.forEach((entry) => {
          const { category, amount } = entry;
          categoryTotals[category] = (categoryTotals[category] || 0) + Number(amount);
        });

         // Summing up the categories for the filtered data
        const filteredCategoryTotals = {};
        filterSetData.forEach((entry) => {
          const { category, amount } = entry;
          filteredCategoryTotals[category] = (filteredCategoryTotals[category] || 0) + Number(amount);
        });
            


        // Sorting categories by total spend
        const sortedData = Object.entries(categoryTotals)
          .sort(([, totalA], [, totalB]) => totalB - totalA)
          .map(([category, total]) => ({
            category,
            total,
            entries: jsonData.filter((entry) => entry.category === category),
          }));

        // Sorting categories by total spend for filtered data
      const sortedFilteredData = Object.entries(filteredCategoryTotals)
      .sort(([, totalA], [, totalB]) => totalB - totalA)
      .map(([category, total]) => ({
        category,
        total,
        entries: filterSetData.filter((entry) => entry.category === category),
      }));  

        console.log("sortedFilteredData", sortedFilteredData)
        setData(sortedData);
        setFilterdata(sortedFilteredData);
        setFirstreview(true);
        setSortreview(false);
      };
  
      reader.readAsBinaryString(file);
    };
  
    const handleCompanyChange = (event) => {
      setSelectedCompany(event.target.value);
      handleCompanySelect(event.target.value); // Trigger any selection logic
    };
 
    const handleCategoryClick = (index) => {
      const updatedExpandedCategories = [...expandedCategories];
      updatedExpandedCategories[index] = !updatedExpandedCategories[index]; // Toggle the expanded state
      setExpandedCategories(updatedExpandedCategories);
    };
  
    function excelSerialDateToJSDate(serial) {
      // Excel serial date format has a start date of 1899-12-30
      const daysSinceEpoch = serial - 25569; // Days between 1899-12-30 and 1970-01-01
      const millisecondsPerDay = 86400000; // 24 * 60 * 60 * 1000
    
      // Convert to JavaScript date
      return new Date(daysSinceEpoch * millisecondsPerDay);
    }
    
    // Helper function to format date to readable format, e.g., DD-MM-YYYY
    function formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }

// `${apiUrl}/toptabledata`

// Submit the data to backend
const handleSubmit = async() => {
  const token = await getAccessTokenSilently();

  // Construct the payload
  const payload = {
    parentcompany: parentCompany.name,
    parentcompany_country: parentCompany.country,
    company: selectedCompany.subsidiary_name,
    period: selectedPeriod,
    data: filterdata,
  };

  // Log the payload to the console
  console.log('Payload to be sent to backend:', payload);

  try {
    // const response = await axios.post('http://localhost:5000/api/scopethreespendbaseddata', {
      const response = await axios.post(`${apiUrl}/scopethreespendbaseddata`, {
      payload
    },{
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    // After submission, fetch the updated data
    await fetchScope3EmissionsData(token);
     // Update the view or move to the next step
    //  setCategoryandsectorview(true);
    // console.log(response)
  } catch (error) {
    console.error('Error adding company:', error.response || error.message);
  }

};


    function parseReportingPeriod(period) {
      const [start, end] = period.split(' - ');
      
      // Extract month and year from the period string (e.g., '08/2017')
      const [startMonth, startYear] = start.split('/');
      const [endMonth, endYear] = end.split('/');
      
      // Construct Date objects representing the first day of each month in the period
      const startDate = new Date(`${startYear}-${startMonth}-01`); // e.g., '2017-08-01'
      const endDate = new Date(`${endYear}-${endMonth}-01`); // e.g., '2018-08-01'
    
      return { startDate, endDate };
    }
// Function to parse date from various formats and return { month, year }
const parseMonthYear = (dateString) => {
  if (typeof dateString !== 'string') return null;

  // Define regular expressions for different date formats
  const formats = [
      /^(\d{2})-(\d{2})-(\d{4})$/,    // DD-MM-YYYY
      /^(\d{2})\/(\d{2})\/(\d{4})$/,  // DD/MM/YYYY
      /^(\d{2})-(\d{4})$/,            // MM-YYYY
      /^(\d{2})\/(\d{4})$/,           // MM/YYYY
      /^(\d{2})-(\d{2})$/,            // DD-YYYY (assuming DD-MM, ignore day)
      /^(\d{4})-(\d{2})-(\d{2})$/,    // YYYY-MM-DD
  ];

  for (const regex of formats) {
      const match = dateString.match(regex);
      if (match) {
          let month, year;
          
          // Map regex match groups to month and year based on detected format
          if (regex === formats[0] || regex === formats[1] || regex === formats[5]) { // DD-MM-YYYY or DD/MM/YYYY or YYYY-MM-DD
              month = match[2];
              year = match[3];
          } else if (regex === formats[2] || regex === formats[3]) { // MM-YYYY or MM/YYYY
              month = match[1];
              year = match[2];
          } else if (regex === formats[4]) { // DD-YYYY (assume day irrelevant)
              month = '01'; // Default to January if no month specified
              year = match[2];
          }

          // Ensure month and year are strings with leading zeroes if needed
          return { month: month.padStart(2, '0'), year };
      }
  }

  return null; // Return null if no valid format matched
};

function isDateInReportingPeriod(date, period) {
  const { startDate, endDate } = parseReportingPeriod(period);
  // console.log(date,date >= startDate && date <= endDate )

  return date >= startDate && date <= endDate;
}



const handleScope3CategoryChange = (entryId, newCategory) => {
  // Update the local state
  setData((prevData) =>
    prevData.map((item) => ({
      ...item,
      entries: item.entries.map((entry) =>
        entry.id === entryId ? { ...entry, scope3category: newCategory } : entry
      ),
    }))
  );
};

const fetchScope3EmissionsData = async (token) => {
  try {
    // const responseScope3 = await axios.get('http://localhost:5000/api/fetchscope3spendbasedemissions', {
      const responseScope3 = await axios.get(`${apiUrl}/fetchscope3spendbasedemissions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const separatedScope3Categories = {};
    console.log("responseScope3",responseScope3)
    
    const emissionsData = responseScope3.data.emissions_data;
    setSeparatedScope3Categories(emissionsData);
    console.log("emissionsData",emissionsData)

  } catch (error) {
    console.error('Error fetching Scope 3 emissions data:', error);
  }
};


// Group the data by `scope3categories`
// const groupedData = SeparatedScope3Categories.reduce((acc, row) => {
//   const category = row.scope3categories;
//   if (!acc[category]) {
//     acc[category] = [];
//   }
//   acc[category].push(row);
//   return acc;
// }, {});


// Calculate the total CO2 emissions for the entire dataset
const totalCO2Emissions = SeparatedScope3Categories.reduce(
  (total, row) => total + row.total_emissions_kg_co2e,
  0
);





// Group the data by `scope3categories` and calculate the total emissions per category
// const groupedData = SeparatedScope3Categories.reduce((acc, row) => {
//   const category = row.scope3categories;
//   if (!acc[category]) {
//     acc[category] = { rows: [], totalEmissions: 0 };
//   }
//   acc[category].rows.push(row);
//   acc[category].totalEmissions += row.total_emissions_kg_co2e;
//   return acc;
// }, {});
// // Color mapping for different categories
// const categoryColors = {
//   "Purchased Services": "lightblue",
//   "Capital Goods": "lightgreen",
//   "Upstream Transportation": "lightcoral",
//   // Add more categories and their respective colors
// };


useEffect(() => {
  const grouped = SeparatedScope3Categories.reduce((acc, row) => {
    const category = row.scope3categories;

    // If category doesn't exist, initialize it
    if (!acc[category]) {
      acc[category] = {
        rows: [], 
        totalEmissions: 0, 
        isVisible: true,        // Toggle for category visibility
        rowVisibility: [],     // Array to track individual row visibility
      };
    }

    // Push the row to the corresponding category
    acc[category].rows.push(row);

    // Add the row's emissions to the total emissions of the category
    acc[category].totalEmissions += row.total_emissions_kg_co2e;

    // Initialize the rowVisibility array for new rows, marking them visible initially
    acc[category].rowVisibility.push(true); // New rows start visible

    return acc;
  }, {});

  setGroupedData(grouped);
}, [SeparatedScope3Categories]);

const toggleCategoryVisibility = (category) => {
  setGroupedData((prevGroupedData) => {
    const updatedGroupedData = { ...prevGroupedData };
    updatedGroupedData[category].isVisible = !updatedGroupedData[category].isVisible;
   
      // Deep copy the grouped data to avoid mutating the original state
      const updatedDataAfterUserSel = JSON.parse(JSON.stringify(groupedData));
      setdatafterusersel(updatedDataAfterUserSel)
    return updatedGroupedData;
  });
};

const toggleRowVisibility = (category, rowIndex) => {
  setGroupedData((prevGroupedData) => {
    const updatedGroupedData = { ...prevGroupedData };
    updatedGroupedData[category].rowVisibility[rowIndex] = !updatedGroupedData[category].rowVisibility[rowIndex];
    
    return updatedGroupedData;
  });
};

// State to store user selections for each row
const [selectedCategories, setSelectedCategories] = useState({});
const [datafterusersel, setdatafterusersel] = useState({});


const handleCategoryChange = (rowId, value) => {
  // Update selectedCategories state
  setSelectedCategories((prev) => ({
    ...prev,
    [rowId]: value,
  }));

  // Update datafterusersel state without changing the original grouped data
  setdatafterusersel((prevDataAfterUserSel) => {
    // Deep copy the grouped data to avoid mutating the original state
    const updatedDataAfterUserSel = JSON.parse(JSON.stringify(groupedData));  // Use 'grouped' instead of prevDataAfterUserSel
    console.log("updatedDataAfterUserSel (after deep copy):", updatedDataAfterUserSel);

    // Remove the row from its original category
    Object.entries(updatedDataAfterUserSel).forEach(([category, data]) => {
      // Filter out the row if it matches rowId in the current category
      data.rows = data.rows.filter((row) => row.unique_id !== rowId);

      // If the category is empty after removing the row, remove the category
      if (data.rows.length === 0) {
        delete updatedDataAfterUserSel[category];
      }
    });

    // Add the row to the new category
    if (!updatedDataAfterUserSel[value]) {
      // If the new category doesn't exist, initialize it
      updatedDataAfterUserSel[value] = {
        rows: [],
        isVisible: true,
        rowVisibility: [],
      };
    }

    // Retrieve the original row data (avoiding mutation of the original state)
    const originalRow = Object.values(groupedData)  // Access 'grouped' directly instead of prevDataAfterUserSel
      .flatMap((data) => data.rows)
      .find((row) => row.unique_id === rowId);

    if (originalRow) {
      const updatedRow = { ...originalRow, scope3categories: value };
      updatedDataAfterUserSel[value].rows.push(updatedRow);
      updatedDataAfterUserSel[value].rowVisibility.push(true);
    }

    // Return the updated data state
    return updatedDataAfterUserSel;
  });
};



console.log("groupedData", groupedData)
console.log("datafterusersel", datafterusersel)

//final data for displaying at the last view
const filteredGroupedData = Object.entries(datafterusersel).reduce((acc, [category, data]) => {
  if (data.isVisible) {
    const visibleRows = data.rows.filter((_, rowIndex) => data.rowVisibility[rowIndex]);
    if (visibleRows.length > 0) {
      acc[category] = { ...data, rows: visibleRows };
    }
  }
  return acc;
}, {});

console.log("filteredGroupedData",filteredGroupedData)
// useEffect(() => {
//   const newlyCategorizedData = Object.entries(groupedData).reduce((acc, [category, data]) => {
//     // Check if filteredGroupedData has the same category
//     const updatedCategoryData = filteredGroupedData[category];
//     console.log("updatedCategoryData", updatedCategoryData)
//     if (!updatedCategoryData) return acc; // Skip if no corresponding data in filteredGroupedData
  
//     const categorizedRows = data.rows.filter((row, index) => {
//       // Skip rows that don't need to be categorized
//       if (row.scope3categories === "1") return false;
  
//       // Get the corresponding row from filteredGroupedData
//       const updatedRow = updatedCategoryData?.rows?.[index];
//       if (!updatedRow) return false; // Skip if the updated row doesn't exist
  
//       // Compare scope3categories in both datasets
//       const originalCategories = row.scope3categories.split(';');
//       const updatedCategories = updatedRow.scope3categories.split(';');
  
//       // Check if the row was originally "Uncategorized" (length > 1) and now has been assigned new categories
//       return (
//         originalCategories.length > 1 && updatedCategories.length > 0 && updatedCategories[0] !== "Uncategorized"
//       );
//     });
//     console.log("newlyCategorizedData",categorizedRows)
  
//     return acc.concat(categorizedRows);
//   }, []);  

// }, [lastview]);
// // Send the newly categorized data to the backend
// // sendToBackend(newlyCategorizedData);

// // Send the newly categorized data to the backend
// // sendToBackend(newlyCategorizedData);


useEffect(() => {
  const getChangedRows = (groupedData, filteredGroupedData) => {
    const changedRows = [];
  
    // Iterate through each category in filteredGroupedData
    Object.entries(filteredGroupedData).forEach(([filteredCategory, filteredData]) => {
      // Find the matching category in groupedData
      const matchedCategory = Object.keys(groupedData).find(groupedCategory =>
        groupedCategory === filteredCategory
      );
  
      if (matchedCategory) {
        const originalData = groupedData[matchedCategory];
  
        // Compare rows between groupedData and filteredGroupedData
        filteredData.rows.forEach(filteredRow => {
          // Check if the row already exists in originalData.rows
          const rowExists = originalData.rows.some(originalRow => {
            return Object.keys(filteredRow).every(key => {
              return filteredRow[key] === originalRow[key];
            });
          });
          
          if (!rowExists) {
          const categoryName = filteredRow.scope3categories;  // Get the category name from scope3categories

            // If the row doesn't exist, it's a new row
            changedRows.push({
              // [categoryName]: filteredRow,
              updated_rows:filteredRow,
              // newRow: filteredRow,  // The new row that was added
              // originalCategory: matchedCategory,
            });
          }
        });
      } else {
        // If the category doesn't exist in groupedData, add all rows
        filteredData.rows.forEach(filteredRow => {
          // Dynamically set the category name as the key
          const categoryName = filteredRow.scope3categories;  // Get the category name from scope3categories

          changedRows.push({
            // [categoryName]: filteredRow,
            updated_rows:filteredRow,
            // newRow: filteredRow,  // The new row
            // originalCategory: null, // No original category
          });
        });
      }
    });
  
    return changedRows;
  };
  
  
  const changedRows= getChangedRows (groupedData, filteredGroupedData)
  setUpdatedrow(changedRows)
  console.log("changedRows", changedRows)
  
}, [lastview]);



// Submit the data to backend
const handlefinalSumbit = async() => {
  const token = await getAccessTokenSilently();

  // Construct the payload
  const payload = {
    data: updatedrow,
  };

  // Log the payload to the console
  console.log('Payload to be sent to backend:', payload);

  try {
    // const response = await axios.post('http://localhost:5000/api/scopethreespendbaseddata', {
      const response = await axios.post(`${apiUrl}/scopethreespendbaseddata_update`, {
      payload
    },{
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    // // After submission, fetch the updated data
    // await fetchScope3EmissionsData(token);
    //  // Update the view or move to the next step
    // //  setCategoryandsectorview(true);
    // // console.log(response)
  } catch (error) {
    console.error('Error adding company:', error.response || error.message);
  }

};




    return (
      <Box
      sx={{
        height: "100vh",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#f5f5f5', // Light background
        boxShadow: 3,
        p: 6,
        borderRadius: 2,
        overflow: 'auto', // Allows scrolling if content grows too big
        // maxWidth:'100vh'
        
      }}
    >
      <Box
        sx={{
          height: "100vh",
          width: '100%',
          bgcolor: 'white',
          p: 6,
          boxShadow: 4,
          borderRadius: 2,
          overflowY: 'auto' 
        }}
      >
         <Typography
              variant="h3"
              align="center"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                mb: 4,
              }}
            >
              Spend Based Scope 3 Analysis 
            </Typography>
          
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Centers content horizontally
            alignItems: 'center',    // Centers content vertically
            p: 2,
            width: '100%', // Use full width of the parent container
          }}
        >
        {initialloading  && (
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Centers content horizontally
              alignItems: 'center',    // Centers content vertically
              p: 2,
              height: "100vh", // Use full width of the parent container,
              // width:"100vh"
            }}
          >
            <Loader 
           loadingText="" 
         />
         </Box>
        
        )}
          {reportingPeriods && reportingPeriods.length > 0 && (
            <FormControl
              variant="outlined"
              sx={{
                width: 300, // Customize width of dropdown
              }}
            >
              <InputLabel>Reporting Period</InputLabel>
              <Select
                value={selectedPeriod}
                onChange={(e) => setSelectedPeriod(e.target.value)}
                label="Reporting Period"
                disabled={firstReview || sortreview} // Disable if a period has been selected
              >
                {reportingPeriods.map((period, index) => (
                  <MenuItem key={index} value={period}>
                    {period}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
       {/* Company Dropdown based on selected reporting period */}
       {selectedPeriod && periodCompanies.length > 0 && (
        <FormControl variant="outlined"   sx={{
          width: 300, // Customize width of dropdown
        }}>
          <InputLabel>Company</InputLabel>
          <Select value={selectedCompany} onChange={handleCompanyChange} label="Company" disabled={firstReview || sortreview}>
            {periodCompanies.map((company, index) => (
              <MenuItem key={index} value={company}>
                {company.subsidiary_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      
    </Box>
    
          {/* {data.length <= 0 && (
            <>
            
            <Typography
              variant="h3"
              align="center"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                mb: 4,
              }}
            >
              Selected reporting period: {selectedPeriod} and Company name: {selectedCompany.subsidiary_name}
            </Typography>
            </>
          )} */}
            {/* Excel Uploader */}
            {data.length <= 0 && selectedPeriod && periodCompanies.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
             
              <Button 
                variant="contained" 
                component="label" 
                sx={{ bgcolor: '#1976d2', color: 'white', boxShadow: 3, '&:hover': { bgcolor: '#1565c0' } }}
              >
                Upload Excel File
                <input type="file" hidden accept=".xlsx, .xls" onChange={handleFileUpload} />
              </Button>
            </Box>
            )}
            { !firstReview && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 40, margin:'100px' }}>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                // fontWeight: 'bold',
                mb: 4,
              }}
            >
              Scope 3 Spend-Based Analysis is a method used to estimate greenhouse gas (GHG) emissions 
              by analyzing the spending data associated with goods and services. 
              It is one of the commonly used approaches to quantify emissions for Scope 3 categories, 
              particularly when direct activity data (e.g., fuel usage or electricity consumption) is unavailable.
            </Typography>
              </Box>
            )}

{/* ///////////////////////////////////////// */}
{firstloading && (
  <Box sx={{ mt: 4, maxHeight: '60vh', overflowY: 'auto' }}>
       <Loader 
      loadingText="Uploading Data..." 
    />
  </Box>
)}

{firstReview && !firstloading && (
  <Box sx={{ mt: 4, maxHeight: '60vh', overflowY: 'auto' }}> {/* Limit table height with scroll */}
    <Table sx={{ width: '100%', tableLayout: 'fixed', bgcolor: '#fafafa', boxShadow: 2 }}>
      <TableHead>
        <TableRow sx={{ bgcolor: '#f0f0f0' }}>
          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Category</TableCell>
          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Date</TableCell>
          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Supplier</TableCell>
          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Amount</TableCell>
          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px'}}>Currency</TableCell>
          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Company Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => {
          // Flatten the entries and display them directly
          return item.entries.map((entry, entryIndex) => {
            // Convert the serial date to a JavaScript Date and format it
            const entryDate = excelSerialDateToJSDate(entry.date); // Date object
            const formattedDate = formatDate(entryDate); // e.g., "DD-MM-YYYY" display format

            // Check if the date falls within the reporting period
            const isWithinReportingPeriod = isDateInReportingPeriod(entryDate, selectedPeriod);
            const isCompanySubsidiary = entry.company_name === selectedCompany.subsidiary_name;

            return (
              <TableRow 
              onClick={() => handleCategoryClick(index)}
                key={entryIndex} 
                sx={{ 
                  cursor: 'pointer',
                '&:hover': { bgcolor: '#f5f5f5' },
                  borderBottom: '1px solid #e0e0e0',
                  ...(isCompanySubsidiary && isWithinReportingPeriod
                    ? {} 
                    : { textDecoration: 'line-through', color: 'blue' }) // Apply conditional styling
                }}
              >
                <TableCell sx={{ padding: '8px', fontSize:'18px' }}>{item.category}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize:'18px' }}>{formattedDate}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize:'18px' }}>{entry.supplier}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize:'18px' }}>{entry.amount}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize:'18px' }}>{entry.currency}</TableCell>
                <TableCell sx={{ padding: '8px', fontSize:'18px' }}>{entry.company_name}</TableCell>
              </TableRow>
            );
          });
        })}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    <Button 
      variant="outlined" 
      onClick={() => {setFirstreview(false);setData([]);}} >Back</Button>
    <Button 
      variant="contained" 
      color="primary" 
      onClick={() => { setFirstreview(false); setSortreview(true);
            
      }}
      >
       Sort and review data
       </Button>
  </div>
      </TableBody>
    </Table>
  </Box>
  
)}
{/* ///////////////////////////////////////// */}



{/* ///////////////////////////////////////// */}
{secondloading && (
  <Box sx={{ mt: 4, maxHeight: '60vh', overflowY: 'auto' }}>
       <Loader 
      loadingText="Loading..." 
    />
  </Box>
)}
{data.length > 0 && sortreview && !secondloading &&  (
  <Box sx={{ mt: 4, maxHeight: '60vh'}}> {/* Limit table height with scroll */}
    <Table sx={{ width: '100%', tableLayout: 'fixed', bgcolor: '#fafafa', boxShadow: 2 }}>
      <TableHead>
        <TableRow sx={{ bgcolor: '#f0f0f0' }}>
          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Category</TableCell>
          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Total Spend</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filterdata.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <TableRow
                onClick={() => setExpandedCategory(expandedCategory === index ? null : index)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { bgcolor: '#f5f5f5' },
                  borderBottom: '1px solid #e0e0e0'
                }}
              >
                <TableCell sx={{ padding: '12px', fontSize:'18px'}}>{item.category}</TableCell>
                <TableCell sx={{ padding: '12px', fontSize:'18px' }}>{item.total}</TableCell>
              </TableRow>

              {/* Collapsible Row */}
              <TableRow>
                <TableCell colSpan={2}>
                  {/* <Collapse in={expandedCategory === index} timeout="auto" unmountOnExit> */}
                    <Table size="small" sx={{ bgcolor: '#fff', mt: 2, boxShadow: 1 }}>
                      <TableHead>
                        <TableRow sx={{ bgcolor: '#e0e0e0' }}>
                          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Date</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Supplier</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Amount</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>Currency</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', fontSize:'18px' }}>company_name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {item.entries.map((entry, entryIndex) => (
                <TableRow key={entryIndex}>
                  <TableCell sx={{ padding: '8px' ,fontSize:'18px'}}>{entry.date}</TableCell>
                  <TableCell sx={{ padding: '8px' ,fontSize:'18px'}}>{entry.supplier}</TableCell>
                  <TableCell sx={{ padding: '8px' ,fontSize:'18px'}}>{entry.amount}</TableCell>
                  <TableCell sx={{ padding: '8px' ,fontSize:'18px'}}>{entry.currency}</TableCell>
                  <TableCell sx={{ padding: '8px' ,fontSize:'18px'}}>{entry.company_name}</TableCell>
                </TableRow>
              ))}
                      </TableBody>
                    </Table>
                  {/* </Collapse> */}
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Button 
                  variant="outlined" 
                  onClick={() => { setFirstreview(true); setSortreview(false);
                        
                  }}
                  > Back</Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => { setFirstreview(false); setSortreview(false) ; handleSubmit();
                    setCategoryandsectorview(true)
                  }}
                  >
                  Submit data
                  </Button>
                  </div>
  </Box>
)}
{/* ///////////////////////////////////////// */}




{/* ///////////////////////////////////////// */}
{/* Render the table with percentage bars and dropdowns for categories */}

{thirdloading && !firstloading && !secondloading && (
  <Box sx={{ mt: 4, maxHeight: '60vh', overflowY: 'auto' }}>
       <Loader 
      loadingText="Loading..." 
    />
  </Box>
)}
{categoryandsectorview && !thirdloading && (
  <Box sx={{ mt: 4, maxHeight: '60vh', overflowY: 'auto' }}> {/* Limit table height with scroll */}
    <Table>
      <TableBody>
        {Object.entries(groupedData).map(([category, data]) => (
          <React.Fragment key={category}>
            <TableRow>
              <TableCell colSpan={12}>
                <Accordion
                  expanded={expanded !== category || expanded === category}
                  onChange={() => setExpanded(expanded === category ? null : category)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem', flex: 1 }}>
                      {data.rows && data.rows.some((row) => row.number_of_categories !== "1")
                  ? 'Uncategorized'
                  : category}
              </Typography>
                {/* Switch for toggling category visibility */}
                <Switch
                        checked={data.isVisible}
                        onChange={() => toggleCategoryVisibility(category)}
                        sx={{ marginLeft: 2 }}
                      />
                      {/* Progress bar to show percentage */}
                      <Box
                        sx={{
                          width: '60%',
                          backgroundColor: '#e0e0e0',
                          borderRadius: '4px',
                          overflow: 'hidden',
                          marginLeft: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: `${(data.totalEmissions / totalCO2Emissions) * 100}%`,
                            backgroundColor: '#4caf50',
                            height: '8px',
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{ marginLeft: 1, fontWeight: 'bold', fontSize: '0.9rem' }}
                      >
                        {((data.totalEmissions / totalCO2Emissions) * 100).toFixed(2)}%
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableHead>
                      <TableRow sx={{ bgcolor: '#e0e0e0' }}>
                        <TableCell>Scope 3 Category</TableCell>
                        <TableCell>Sub-Category</TableCell>x  
                        <TableCell>Sector</TableCell>
                        <TableCell>User Category</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Supplier</TableCell>
                        <TableCell>Period</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell align="right">Amount (AUD)</TableCell>
                        <TableCell align="right">Total Emissions (kg CO2e)</TableCell>
                        <TableCell align="right">Total Water (m³)</TableCell>
                        <TableCell align="right">Total Land Use (m²)</TableCell>
                      </TableRow>
                    </TableHead>
                    
                    { data.rows.map((row, rowIndex) => {
                      const categories = row.scope3categories.split(';'); // Split categories
                      const isDropdown = row.number_of_categories !== "1"; // Check if dropdown is needed
                           // Apply greyed-out style if category or row is not visible
                            const rowStyle = (!data.isVisible || !data.rowVisibility[rowIndex]) ? {
                              backgroundColor: '#f5f5f5', // Light grey background
                              opacity: 0.6,               // Reduced opacity
                            } : {};
                             // Check if the current category is "Uncategorized"
                    const isUncategorized = data.rows.some((r) => r.number_of_categories !== "1");
                      return (
                        <TableRow key={rowIndex} sx={rowStyle}>
                          <TableCell>
                            {isDropdown ? (
                              <FormControl fullWidth>
                                <InputLabel>Select Category</InputLabel>
                                <Select
                                      value={selectedCategories[row.unique_id] || ''}
                                      onChange={(e) => handleCategoryChange(row.unique_id, e.target.value)}
                                    >
                                  {categories.map((category, index) => (
                                    <MenuItem key={index} value={category}>
                                      {category}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <Typography>{row.scope3categories}</Typography>
                            )}
                          </TableCell>
                          <TableCell>{!isUncategorized ? row.subCategory : '-'}</TableCell>
                          <TableCell>{!isUncategorized ? row.sector : '-'}</TableCell>
                          <TableCell>{row.user_category}</TableCell>
                          <TableCell>{row.company}</TableCell>
                          <TableCell>{row.supplier}</TableCell>
                          <TableCell>{row.period}</TableCell>
                          <TableCell>{row.country}</TableCell>
                          <TableCell align="right">{row.amount.toLocaleString()}</TableCell>
                          <TableCell align="right">{!isUncategorized ? row.total_emissions_kg_co2e.toFixed(2): '-'}</TableCell>
                          <TableCell align="right">{!isUncategorized ? row.total_water_consumption_m3.toFixed(2): '-'}</TableCell>
                          <TableCell align="right">{!isUncategorized ? row.total_land_use_m2.toFixed(2): '-'}</TableCell>
                            {/* Add Switch for row visibility */}
                            <TableCell>
                            <Switch
                              checked={data.rowVisibility[rowIndex]}
                              onChange={() => toggleRowVisibility(category, rowIndex)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
      <Button
        variant="outlined"
        onClick={() => {
          setFirstreview(false);
          setSortreview(true);
          setCategoryandsectorview(false);
          setGroupedData([]);
        }}
      >
        Back
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setFirstreview(false);
          setSortreview(false);
          // handleSubmit(selectedCategories); // Pass selected categories
          setCategoryandsectorview(false);
          setLastview(true);
        }}
      >
        Save and Continue
      </Button>
    </div>
  </Box>
)}
{/* ///////////////////////////////////////// */}



{/* ///////////////////////////////////////// */}
{lastview &&(
  <Box sx={{ mt: 4 }}>
  <Typography variant="h6" sx={{ mb: 2 }}>
    Final Selected Data
  </Typography>
  <Table>
    <TableHead>
      <TableRow sx={{ bgcolor: '#e0e0e0' }}>
        <TableCell>Scope 3 Category</TableCell>
        <TableCell>Sub-Category</TableCell>
        <TableCell>Sector</TableCell>
        <TableCell>User Category</TableCell>
        <TableCell>Company</TableCell>
        <TableCell>Supplier</TableCell>
        <TableCell>Period</TableCell>
        <TableCell>Country</TableCell>
        <TableCell align="right">Amount (AUD)</TableCell>
        <TableCell align="right">Total Emissions (kg CO2e)</TableCell>
        {/* <TableCell align="right">Total Water (m³)</TableCell> */}
        {/* <TableCell align="right">Total Land Use (m²)</TableCell> */}
      </TableRow>
    </TableHead>
    <TableBody>
      {Object.entries(filteredGroupedData).map(([category, data]) => (
        <React.Fragment key={category}>
          <TableRow>
            <TableCell colSpan={12} sx={{ fontWeight: 'bold', bgcolor: '#f5f5f5' }}>
              {category}
              
            </TableCell>
          </TableRow>
          {data.rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell>{row.scope3categories}</TableCell>
              <TableCell>{row.sub_category}</TableCell>
              <TableCell>{row.sector}</TableCell>
              <TableCell>{row.user_category}</TableCell>
              <TableCell>{row.company}</TableCell>
              <TableCell>{row.supplier}</TableCell>
              <TableCell>{row.period}</TableCell>
              <TableCell>{row.country}</TableCell>
              <TableCell align="right">{row.amount_aud}</TableCell>
              <TableCell align="right">{row.total_emissions_kg_co2e}</TableCell>
              {/* <TableCell align="right">{row.total_water_m3}</TableCell> */}
              {/* <TableCell align="right">{row.total_land_use_m2}</TableCell> */}
            </TableRow>
          ))}
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
  <Button
        variant="outlined"
        onClick={() => {
          setFirstreview(false);
          setSortreview(false);
          setCategoryandsectorview(true);
          setLastview(false);
          handlefinalSumbit();
        }}
      >
        Back
      </Button>
      <Button
        variant="outlined"
        onClick={() => {

          handlefinalSumbit();
          navigate('/app/Scope3Dashboard');
        }}
      >
        Save and View Dashboard
      </Button>
      <Box>
      {/* <Scope3FinalView filteredGroupedData={filteredGroupedData} lastview={lastview} />
      <SupplierTable filteredGroupedData={filteredGroupedData}/> */}
      </Box>
</Box>
)}
          </Box>
        </Box>
      );
    };
export default Spendbased_scope3;