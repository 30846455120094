import React, { useState, useEffect, useContext } from 'react';
import {
    TableContainer,Paper, Grid, FormControlLabel,Drawer, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Tabs, Tab, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
// import Switch from '@mui/material/Switch';
import { Switch } from 'antd';
import { ArrowDownward, Delete } from '@mui/icons-material';
import { SourceContext } from '../contexts/Sourcecontext';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { convertLegacyOperators } from '@mui/x-data-grid/internals';
import statcombution_logo from './Logos/stationary_combustion.png';
const apiUrl = process.env.REACT_APP_API_URL_BACKEND

const PurchasedElectricityDialog = ({ open, onClose, onSubmit, source, month, year, company, reportingPeriod }) => {
    const initialFormData = {
      id: '',
      ElecfacilitiesDescription: '',
      ElecfacilitiesArea: '',
      Electricity: [{ State: '', Consumption: '', units:'', emFactor: '' }],
      renewableConsumption:'',
      renewableunits:'',
      renewableEmFactor:'' ,
      // RenewableElec :[{ renewableCity:'', renewableKWh: '', renewableEmFactor: ''}],
    };
  
    const { parentCompany, elecfacilities = [], setElecFacilities } = useContext(SourceContext);
    const { superStatcombusstate, separatedelectricityFacilitiesT, fetchAndProcessData } = useContext(SourceContext);
    const [currentTab, setCurrentTab] = useState(0);
    const { getAccessTokenSilently } = useAuth0();
    const [dialogData, setDialogData] = useState(null);
    const [showRenewableElecForm, setShowRenewableElecForm] = useState(false);

  
    const handleTabChange = (event, newValue) => {
      setCurrentTab(newValue);
    };
  
    useEffect(() => {
      if (open) {
        fetchAndProcessData();
      }
    }, [open]);
  
    useEffect(() => {
      const key = `purchasedelectricity_${company}_${month}_${reportingPeriod}`;
      setDialogData(separatedelectricityFacilitiesT[key] || []);
    }, [separatedelectricityFacilitiesT, company, month, reportingPeriod]);
  
    useEffect(() => {
      const key = `purchasedelectricity_${company}_${month}_${reportingPeriod}`;
      const dataForKey = separatedelectricityFacilitiesT[key] || [];
      setDialogData(dataForKey);
  
      setElecFacilities(dataForKey.length > 0 ? dataForKey : [{
        id: '',
        data: {
        ElecfacilitiesDescription: '',
        ElecfacilitiesArea: '',
        Electricity: [{ State: '', Consumption: '', units:'', emFactor: '' }],
        renewableConsumption:'',
        renewableunits:'',
        renewableEmFactor:'' ,
        }
      }]); // Reset to empty array if no data
    }, [separatedelectricityFacilitiesT]);
  


    const handleChange = (e, elecfacilitiesIndex, elecIndex = null, renewable = false) => {
      const { name, value } = e.target;
      const updatedFacilities = elecfacilities.map((elecfacilities, index) => {
        if (index === elecfacilitiesIndex) {
          if (name === 'id') {
            // Update the facility id
            return { ...elecfacilities, id: value };
        } else if (elecIndex !== null) {
            const updatedElec = renewable
              ? elecfacilities.data.RenewableElec.map((elec, idx) => {
                  if (idx === elecIndex) {
                    return { ...elec, [name]: value };
                  }
                  return elec;
                })
              : elecfacilities.data.Electricity.map((elec, idx) => {
                  if (idx === elecIndex) {
                    return { ...elec, [name]: value };
                  }
                  return elec;
                });
            return renewable
              ? { ...elecfacilities, data: { ...elecfacilities.data, RenewableElec: updatedElec } }
              : { ...elecfacilities, data: { ...elecfacilities.data, Electricity: updatedElec } };
          }
          return { ...elecfacilities, data: { ...elecfacilities.data, [name]: value } };
        }
        return elecfacilities;
      });
      setElecFacilities(updatedFacilities);
    };
  
    const addRenewableElec = () => {
        setShowRenewableElecForm(true); // Show the renewable electricity form
      // const updatedFacilities = [...elecfacilities];
      // updatedFacilities[currentTab].data.RenewableElec.push({ State: '', KWh: '', emFactor: '' });
      // setElecFacilities(updatedFacilities);
    };

    const addFacility = () => {
      // Generate a new facility ID, ensure it's a string
    const newFacilityId = elecfacilities.length > 0 
    ? (Math.max(...elecfacilities.map(f => parseInt(f.id, 10))) + 1).toString() 
    : " ";

        setElecFacilities([...elecfacilities,{id: newFacilityId, data: initialFormData}]);
        setCurrentTab(elecfacilities.length -1); // Move to the newly added facility tab
      };
  
    const deleteFacility = (facilityId) => {
      console.log("elec facility to be deleted", facilityId)
      const updatedFacilities = elecfacilities.filter(elecfacilities => elecfacilities.id !== facilityId);
      setElecFacilities(updatedFacilities);
      if (currentTab >= updatedFacilities.length) {
        setCurrentTab(updatedFacilities.length - 1);
      }
    };
      const handleRenewableElecChange = (e, field) => {
        const newRenewableElec = {
          ...elecfacilities[currentTab].data.RenewableElec,
          [field]: e.target.value,
        };
      
        setElecFacilities((prev) => ({
          ...prev,
          [currentTab]: {
            ...prev[currentTab],
            data: {
              ...prev[currentTab].data,
              RenewableElec: newRenewableElec,
            },
          },
        }));
      };
  
    const [showEmissionFactor, setShowEmissionFactor] = useState(false); // State for toggle switch
    const [showEmissionFactorRenewable, setShowEmissionFactorRenewable] = useState(false); // State for toggle switch

//   const handleSwitchChange = (event) => {
//     setShowEmissionFactor(event.target.checked); // Update state based on switch
//   };
        const handleSwitchChange = (checked) => {
            setShowEmissionFactor(checked); // Update state based on the checked value
        };

        const handleSwitchChange_renewable = (checked) => {
          setShowEmissionFactorRenewable(checked); // Update state based on the checked value
      };

      useEffect(() => {
        if (elecfacilities[currentTab]?.data?.renewableConsumption) {
            setShowRenewableElecForm(true);
        }
    }, [elecfacilities, currentTab]);

    useEffect(() => {
      if (elecfacilities[currentTab]?.data?.Electricity?.map(elec => elec.emFactor)) {
        setShowEmissionFactor(true);
      }
  }, [elecfacilities, currentTab]);

    const handleSubmit = async() => {
      onSubmit(elecfacilities.map(facility => ({ ...facility.data, month, source })));
      const token = await getAccessTokenSilently();
      
      try {
        console.log("sending data back from purchase electrcity", elecfacilities)
        // const response = await axios.post('http://localhost:5000/api/purchasedelectricity', {
          const response = await axios.post(`${apiUrl}/purchasedelectricity`, {
          parentcompany: parentCompany.name,
          parentcompany_country: parentCompany.country,
          company: company,
          period: reportingPeriod,
          month: month, 
          elecfacilities: elecfacilities,
        },{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error('Error adding company:', error.response || error.message);
      }
      // onClose();
    };

      const statesByCountry = {
        India: [
          'Maharashtra', 'Karnataka', 'Tamil Nadu', 'Gujarat', 'Rajasthan',
          'Uttar Pradesh', 'West Bengal', 'Delhi', 'Punjab', 'Haryana',
          'Bihar', 'Assam', 'Odisha', 'Kerala', 'Jharkhand',
          'Chhattisgarh', 'Himachal Pradesh', 'Uttarakhand', 'Tripura', 'Meghalaya',
          'Manipur', 'Nagaland', 'Arunachal Pradesh', 'Sikkim', 'Goa',
          'Puducherry', 'Andaman and Nicobar Islands', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep',
        ],
        Australia: [
          'New South Wales', 'Victoria', 'Queensland', 'South Australia', 'Western Australia',
          'Tasmania', 'Australian Capital Territory', 'Northern Territory',
        ],
        USA: [
          'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
          'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
          'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
          'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
          'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
          'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
          'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
          'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
          'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
          'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
        ],
        NewZealand: [
          'Auckland', 'Bay of Plenty', 'Canterbury', 'Gisborne', 'Hawke\'s Bay',
          'Manawatu-Wanganui', 'Marlborough', 'Nelson', 'Northland', 'Otago',
          'Southland', 'Taranaki', 'Tasman', 'Waikato', 'Wellington',
        ],
      };

      const country = parentCompany?.country; // Assuming you have parentCompany data available
      const states = statesByCountry[country] || [];

    return (
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box
          sx={{
            width: '40vw',
            padding: 2,
            height: '100%',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
            onClick={onClose}
          >
            <ArrowDownward />
          </IconButton>
          <Tabs value={currentTab} onChange={handleTabChange} aria-label="elecfacilities tabs">
            {elecfacilities.map((elecfacilities, index) => (
              <Tab key={elecfacilities.id} label={`Facilities ${elecfacilities.id}`} />
            ))}
            <Tab label="Add Facilities" onClick={() => addFacility()} />
            <Tab label="Submit Data" />
          </Tabs>
          {currentTab < elecfacilities.length ? (
            <Box p={2}>
                <Typography>
                   Electricity Purchased
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                    Enter Data for Facility {elecfacilities[currentTab].id}
                <DialogTitle>Enter Data for {source}</DialogTitle>
                <Typography>Company: {company}</Typography>
                <Typography>Reporting Period: {reportingPeriod}</Typography>
                <Typography>Month and Year: {month}</Typography>
                {/* <Typography>Year: {year}</Typography> */}
                </Typography>
                <img src={statcombution_logo} alt="Lo go" style={{ height: '170px', marginLeft: '6px'}} />
                    </Box>
                <Typography variant="subtitle1" gutterBottom>
                    {month} {year}
                </Typography>
              <Typography variant="h6" gutterBottom>
                Enter Data for Facility {elecfacilities[currentTab].id}
              </Typography>
              <TextField
              label="Facility ID"
              name="id"
              value={elecfacilities[currentTab].id}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
              <TextField
                label="elecfacilities Description"
                name="ElecfacilitiesDescription"
                value={elecfacilities[currentTab].data.ElecfacilitiesDescription}
                onChange={(e) => handleChange(e, currentTab)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="elecfacilities Area"
                name="ElecfacilitiesArea"
                value={elecfacilities[currentTab].data.ElecfacilitiesArea}
                onChange={(e) => handleChange(e, currentTab)}
                fullWidth
                margin="normal"
              />
                <Typography variant="h6" gutterBottom>
                Electricity Details
                </Typography>

                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>State</TableCell>
                    <TableCell>Consumption</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell>Emission Factor</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {elecfacilities[currentTab].data.Electricity.map((elec, elecIndex) => (
                    <TableRow key={elecIndex}>
                        <TableCell>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id={`state-select-label-${elecIndex}`}>State</InputLabel>
                        <Select
                        labelId={`state-select-label-${elecIndex}`}
                        value={elec.State}
                        onChange={(e) => handleChange(e, currentTab, elecIndex)}
                        name="State"
                        fullWidth
                        >
                        {states.map((state, index) => (
                            <MenuItem key={index} value={state}>
                            {state}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </TableCell>
                        <TableCell>
                        <TextField
                            label="Consumption"
                            name="Consumption"
                            value={elec.Consumption}
                            onChange={(e) => handleChange(e, currentTab, elecIndex)}
                            fullWidth
                            sx={{ width: '100px' }} // Set a specific width
                            margin="normal"
                        />
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth margin="normal" sx={{ width: '200px' }}>
                              <InputLabel id={`units-label-${elecIndex}`}>Units</InputLabel>
                              <Select
                               
                                label="Units"
                                name="units"
                                value={elec.units}
                                onChange={(e) => handleChange(e, currentTab, elecIndex)}
                              >
                                <MenuItem value="GJ">GJ</MenuItem>
                                <MenuItem value="kWh">KWh</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        <TableCell sx={{ width: '63%' }}>
                <Grid container alignItems="center" spacing={2}>
                    {/* Fixed width for Switch */}
                    <Grid item sx={{ width: '150px' }}> 
                    {/* <FormControlLabel
                        control={
                        <Switch
                            checked={showEmissionFactor}
                            onChange={handleSwitchChange}
                            color="secondary"
                            size="small"
                        />
                        }
                        label="Emission Factor"
                        labelPlacement="start"
                    /> */}

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px' }}>Emission Factor</span>
                        <Switch 
                            checked={showEmissionFactor || false}  // Fallback to false if undefined
                            onChange={(checked) => handleSwitchChange(checked)}  // Pass the checked value to the handler
                            size="small" 
                        />
                        </div>
                    </Grid>

    {/* Fixed width for TextField with spacing when the switch is on */}
    
        {showEmissionFactor && (
            <Grid item sx={{ width: '150px',paddingLeft:'150px' }}> 
                <TextField
                label="Emission Factor"
                name="emFactor"
                value={elec.emFactor}
                onChange={(e) => handleChange(e, currentTab, elecIndex)}
                margin="normal"
                fullWidth
                sx={{ maxWidth: '150px' }} // Ensures the text field doesn't exceed 150px
                />
            </Grid>
            )}
        </Grid>
        </TableCell>
        </TableRow>
        ))
        }
            </TableBody>
        </Table>
            <Button
            variant="contained"
            color="primary"
            onClick={addRenewableElec}
            >
            Add Renewable Electricity
            </Button> 
        {showRenewableElecForm && (
          <Box mt={2}>
            {/* <Typography variant="h6">Renewable Electricity Details</Typography> */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Renewable consumption</TableCell>
                  <TableCell>Renewable consumption units</TableCell>
                  <TableCell>Renewable Em. Factor</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                    <TextField
                        label="Renewable Consumption"
                        name="Renewable Consumption"
                        value={elecfacilities[currentTab].data.renewableConsumption}
                        onChange={(e) => handleChange(e, currentTab)}
                        fullWidth
                        margin="normal"
                      />
                    </TableCell>
                    <TableCell>
                    <TextField
                        label="Renewable Em Factor"
                        name="Renewable Em Factor"
                        value={elecfacilities[currentTab].data.renewableEmFactor}
                        onChange={(e) => handleChange(e, currentTab)}
                        fullWidth
                        margin="normal"
                      />
                    </TableCell>
                    <TableCell>
                      {/* <TextField
                        label="Emission Factor"
                        name="renewableEmFactor"
                        value={elecfacilities[currentTab].data.RenewableElec.emFactor || ""}
                        onChange={(e) => handleRenewableElecChange(e, 'emFactor')}
                        fullWidth
                        margin="normal"
                      /> */}

                <TableCell sx={{ width: '63%' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item sx={{ width: '100px', paddingLeft:'150px' }}> {/* Fixed width for Switch */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '8px' }}>Emission Factor</span>
                    <Switch 
                        checked={showEmissionFactorRenewable || false}  // Fallback to false if undefined
                        onChange={(checked) => handleSwitchChange_renewable(checked)}  // Pass the checked value to the handler
                        size="small" 
                    />
                      </div>
                    </Grid>
                    {showEmissionFactorRenewable && (
                        <Grid item sx={{ width: '150px',paddingLeft:'150px' }}> 
                            <TextField
                            label="Renewable Emission Factor"
                            name="Renewable Em Factor"
                            value={elecfacilities[currentTab].data.renewableEmFactor}
                            onChange={(e) => handleChange(e, currentTab)}
                            fullWidth
                            margin="normal"
                            sx={{ maxWidth: '150px' }} // Ensures the text field doesn't exceed 150px
                            />
                        </Grid>
                        )}
                </Grid>
              </TableCell>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
)}
            </Box>
          ):(
            currentTab >= elecfacilities.length && (
                <Box p={2}>
                  <Typography variant="h6" gutterBottom>
                    Summary
                  </Typography>
                  {elecfacilities.map((elecfacility) => (
                    <Box key={elecfacility.id} mb={2} display="flex" alignItems="center">
                      <Box flex={1}>
                        <Typography variant="subtitle1">
                          Electricity Facility {elecfacility.id}:
                        </Typography>
                        <Typography>Parent Company: {parentCompany.name}</Typography>
                        <Typography>Facility Description: {elecfacility.data.ElecfacilitiesDescription}</Typography>
                        <Typography>Facility Area: {elecfacility.data.ElecfacilitiesArea}</Typography>
                        <Typography>Electricity Data:</Typography>
              
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                              <TableCell>State</TableCell>
                              <TableCell>Consumption</TableCell>
                              <TableCell>Units</TableCell>
                              <TableCell>Emission Factor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {elecfacility.data.Electricity.map((elec, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>{elec.State}</TableCell>
                                  <TableCell>{elec.Consumption}</TableCell>
                                  <TableCell>{elec.units}</TableCell>
                                  <TableCell>{elec.emFactor}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
              
                        {/* Renewable Electricity section */}
                        <Typography variant="subtitle1" mt={2}>
                          Renewable Electricity Data:
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Renewable consumption</TableCell>
                                <TableCell>Renewable units</TableCell>
                                <TableCell>Renewable Emission Factor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>{elecfacility.data.renewableConsumption}</TableCell>
                                <TableCell>{elecfacility.data.renewableunits}</TableCell>
                                <TableCell>{elecfacility.data.renewableEmFactor}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      {elecfacilities.length > 1 && (
                    <IconButton onClick={() => deleteFacility(elecfacility.id)}>
                      <Delete />
                    </IconButton>)}
                    </Box>
                  ))}
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                      Submit All Data
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onClose}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
                 
              )  
          )
          
          }
        </Box>
      </Drawer>
    );
  };
  
  export default PurchasedElectricityDialog;
  
