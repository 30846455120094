import React, { useContext } from 'react';
import { SourceContext } from '../contexts/Sourcecontext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL_BACKEND

const CompanyList = ({ reportingPeriod }) => {
  const { companies, deleteCompany, handleCompanySelect, parentCompany } = useContext(SourceContext);

  const periodCompanies = companies[reportingPeriod] || [];
// `${apiUrl}/sourceselection`
  const handleDelete = (companyName) => {
    deleteCompany(reportingPeriod, companyName);
    try {
      // const response =  axios.post('http://localhost:5000/api/deletesubsidiaries', {
        const response =  axios.post(`${apiUrl}/deletesubsidiaries`, {
        parentCompany:parentCompany,
        reportingPeriod: reportingPeriod,
        company: companyName.trim(),
      });
      console.log(response.data); // You can handle the response data here if needed
    } catch (error) {
      console.error('Error adding company:', error.response || error.message);
    }
  };

  return (
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold',fontSize: '0.9rem', }}>Company Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold',fontSize: '0.9rem', }}>Equity (%)</TableCell>
            <TableCell sx={{ fontWeight: 'bold',fontSize: '0.9rem', }}>Operational Percentage (%)</TableCell>
            <TableCell sx={{ fontWeight: 'bold',fontSize: '0.9rem', }}>Financial Percentage (%)</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periodCompanies.map((company, index) => (
            <TableRow key={index} onClick={() => handleCompanySelect(company)}>
              <TableCell sx={{ fontSize: '0.9rem', }}>{company.subsidiary_name}</TableCell>
              <TableCell sx={{fontSize: '0.9rem', }}>{company.equity_percentage}</TableCell>
              <TableCell sx={{ fontSize: '0.9rem', }}>{company. operational_percentage}</TableCell>
              <TableCell sx={{ fontSize: '0.9rem', }}>{company.financial_percentage}</TableCell>
              <TableCell align="center">
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleDelete(company.name)}
                      sx={{ textTransform: 'none', fontSize: '0.9rem', }}
                    >
                      Delete
                    </Button>
                  </TableCell>
              {/* <TableCell>
                <IconButton onClick={() => handleDelete(company.name)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompanyList;
