import React from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useTheme, Box, IconButton, Typography } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Profile from "../../auth/profileshow";
import { useProSidebar } from "react-pro-sidebar";
import { useAuth0 } from "@auth0/auth0-react";
import {BiLogOutCircle} from 'react-icons/bi'
import { useState } from "react";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const { logout } = useAuth0();
  const [selected, setSelected] = useState("Dashboard");


  return (
    <Box display="flex" justifyContent="space-between" p={0.01}
    sx={{ 
      backgroundColor: theme.palette.custom.paperMainBackground}}>
      <Box display="flex" justifyContent="flex-end">
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Profile></Profile>
        
        <IconButton Logout onClick={() => logout()}>
        <BiLogOutCircle style={{ fontSize: "30px" }} />
        </IconButton>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            
           <LightModeOutlinedIcon  style={{ fontSize: "30px" }} />
          ) : (
            <DarkModeOutlinedIcon  style={{ fontSize: "30px" }} />
          )}
        </IconButton>
      </Box>
     
    </Box>
  );
};

export default Topbar;
