import React, { useState, useContext, useEffect } from 'react';
import CompanyForm from '../../components/Companyform';
import CompanyList from '../../components/Companylist';
import { SourceContext } from '../../contexts/Sourcecontext';
import {Grid,Typography ,Button, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Tabs,Tab } from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import CompaniesTabsReportingPeriod from '../../components/CompanTabsReportinPeriod';
import CompaniesTabsMonthlydata from '../../components/ComapniesTabsMonthlydata';
import ReportingPeriodSources from '../../components/ReportinPeriodSources';
import ParentCompanyForm from '../../components/ParentCompanyForm';
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from "@mui/material/styles";



const MainComponent = () => {
  const { reportingPeriods, addReportingPeriod, deleteReportingPeriod, parentCompany, companies } = useContext(SourceContext);
  const [showTabs, setShowTabs] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(reportingPeriods[0]);
  const [startDate, setStartDate] = useState(null); 
  const [endDate, setEndDate] = useState(null);
  const [showCompanyTabs, setShowCompanyTabs] = useState(false); // State to toggle company tabs visibility
  const[startemissiontrack, setstartemissiontrack]=useState(null);
  const [isDataEntryMode, setIsDataEntryMode] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
//useEffect toi show the company list for each reporting period.
  useEffect(() => {
    if (reportingPeriods && reportingPeriods.length > 0) {
      setSelectedPeriod(reportingPeriods[0]);
    }
  }, [reportingPeriods, startemissiontrack ]);
//useEffect to show top of the page on every render of showing the options to select the 
//emission source selection
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [showCompanyTabs]);
  
  const handleShowTabs = () => {
    setShowTabs(true);
  };

  const handleHideTabs = () => {
    setShowTabs(false);
  };

  const handleAddReportingPeriod = () => {
    if (startDate && endDate) {
      const period = `${startDate.format('MM/YYYY')} - ${endDate.format('MM/YYYY')}`;
      addReportingPeriod(period);
    //   try {
    //       const response = axios.post('http://localhost:5000/api/addreportingperiod', { reportingperiod:period});
    // }
    // catch (error) {
    //   // Handle any errors here
    //   console.error(error.response);
    // }
      setSelectedPeriod(period);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleDeleteReportingPeriod = async (period) => {
    const token = await getAccessTokenSilently();
    deleteReportingPeriod(period);
    try {
      const response = await axios.post('http://localhost:5000/api/deletereportingperiod', { 
        deletereportingperiod:period
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      // Handle success
      if (response.status === 200) {
        console.log('Reporting period deleted successfully');
        if (period === selectedPeriod) {
          setSelectedPeriod(null);
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error(error.response);
    }
    if (period === selectedPeriod) {
            setSelectedPeriod(null);
          } 
  };

//   const handleDeleteReportingPeriod = (period) => {
//     deleteReportingPeriod(period);
//     const token = getAccessTokenSilently();
//     try {
//       const response = axios.post('http://localhost:5000/api/deletereportingperiod', { 
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         deletereportingperiod:period});
//   }
// catch (error) {
//   // Handle any errors here
//   console.error(error.response);
// }
//     if (period === selectedPeriod) {
//       setSelectedPeriod(null);
//     } 
//   };

  const handleStartEmissionTracking = (period) => {
//     try {
//       const response = axios.post('http://localhost:5000/api/mainpageapi', { 
//         parentCompany:parentCompany.name,
//         parentCompanycountry:parentCompany.country,
//         parentCompanystate:parentCompany.state,
//         reportingPeriods:reportingPeriods,
//         companies:companies,
//       });
//   }
//   catch (error) {
//   // Handle any errors here
//   console.error(error.response);
// }
    setSelectedPeriod(period); // Set the selected reporting period
    setShowTabs(true); // Hide reporting period buttons
    setShowCompanyTabs(true); // Show company tabs for the selected period
    setstartemissiontrack(true);
  };

  
  const handleTabChange = (event, newValue) => {
    setSelectedPeriod(reportingPeriods[newValue]);
  };

  const handleBackToReportingPeriods = () => {
    setShowTabs(false); // Show reporting period buttons
    setShowCompanyTabs(false); // Hide company tabs
    setSelectedPeriod(null); // Reset selected period
    setstartemissiontrack(false);
  };


  const periodsWithCompanies = reportingPeriods.filter(
    (period) => companies[period] && companies[period].length > 0
  );


  return (
    <Box sx={{ 
      backgroundColor: theme.palette.custom.paperMainBackground,
      // borderRadius: '16px', 
      padding: '32px', 
      // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
      color: 'white', 
      maxWidth:'100%'
    }}>
    <div>
      {!showTabs && (
        <>
         <Typography 
  variant="h3" 
  // fontWeight="bold" 
  gutterBottom 
  sx={{ color: 'rgb(0,0,0)' }}
>
  Dashboard for Emission Tracking
</Typography>
         <Paper
 sx={{
  padding: 4,
  borderRadius: 4,
  backgroundColor: theme.palette.custom.paperBackground,
  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
  marginBottom: 1,
}}
>
<Typography variant="h5" fontWeight="bold" gutterBottom>

    Step 1: Enter the Details of Your Parent Company
  </Typography>

  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    <ParentCompanyForm />
  </Box>
</Paper>

       {/* {parentCompany ? <ParentCompanyForm /> : null} */}
       <Paper
  sx={{
    padding: 4,
    borderRadius: 4,
    backgroundColor: theme.palette.custom.paperBackground,
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
    marginBottom: 1,
  }}
>
  {parentCompany && parentCompany.name && (
    <Box>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Step 2: Define Your Reporting Period
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Reporting for <strong>{parentCompany.name}</strong> situated in{' '}
        <strong>{parentCompany.country}</strong>.
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item>
              <DatePicker
                views={['year', 'month']}
                label="Start Month and Year"
                minDate={dayjs('1900-01-01')}
                maxDate={dayjs()}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{ width: 250 }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1" sx={{ mx: 2 }}>
                to
              </Typography>
            </Grid>
            <Grid item>
              <DatePicker
                views={['year', 'month']}
                label="End Month and Year"
                minDate={dayjs('1900-01-01')}
                maxDate={dayjs()}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{ width: 250 }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ textTransform: 'none' }}
              onClick={handleAddReportingPeriod}
            >
              Add Reporting Period
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    </Box>
  )}

  {reportingPeriods.length > 0 && !startemissiontrack && parentCompany && parentCompany.name && (
    <Box sx={{ mt: 6 }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Existing Reporting Periods
      </Typography>
      <Paper
        elevation={3}
        sx={{
          borderRadius: 4,
          overflow: 'hidden',
        }}
      >
        <TableContainer component={Box} sx={{ maxHeight: 200, padding:1 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold',fontSize: '1.0rem', }}>Reporting Periods</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '1.0rem', }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportingPeriods.map((period, index) => (
                <TableRow key={index}>
                  <TableCell  sx={{
              fontSize: '0.9rem', // Increased font size for body cells
            }}>{period}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleDeleteReportingPeriod(period)}
                      sx={{ textTransform: 'none', fontSize: '0.9rem', }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )}
</Paper>

        </>
      )}

        {/* {!showTabs && reportingPeriods.length > 0 && !startemissiontrack && (
            <div>
              <h2>Step 3: Proceed to Addiding Subsidiaies</h2>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh', padding: 4 }}>

              <Button variant="contained" onClick={handleShowTabs}>
                  Proceed to Adding Subsidiaies
                </Button>  
                </Box>
              </div>
            )} */}
      
      {!showTabs && !startemissiontrack && parentCompany && parentCompany.name && reportingPeriods.length > 0 && (
  <div>
    <Paper
      sx={{
        padding: 4,
        borderRadius: 4,
        backgroundColor: theme.palette.custom.paperBackground,
        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
        marginBottom: 1,
      }}
    >
            <Typography variant="h5" fontWeight="bold" gutterBottom>

        Step 3: Please Select a Reporting Period for {parentCompany.name} to Add Subsidiaries
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Reporting for <strong>{parentCompany.name}</strong> situated in{' '}
        <strong>{parentCompany.country}</strong>.
      </Typography>

      <Box
  // sx={{
  //   mt: 6,
  //   padding: 2,
  //   borderRadius: 4,
  //   backgroundColor: '#f5f5f5',
  //   boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
  // }}
>
  <Paper
    elevation={3}
    sx={{
      borderRadius: 4,
      overflow: 'hidden',
      padding: 2,
    }}
  >
    <Tabs
      value={reportingPeriods.indexOf(selectedPeriod)}
      onChange={handleTabChange}
      centered
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        marginBottom: 2,
        '.MuiTab-root': { fontSize: '1rem', fontWeight: 'bold' },
        '.Mui-selected': { color: '#002b5c' },
      }}
    >
      {reportingPeriods.map((period, index) => (
        <Tab key={index} label={period} />
      ))}
    </Tabs>

    {/* {selectedPeriod && ( */}
      <>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ color: '#003f5c', fontWeight: 'medium', marginBottom: 2 }}
        >
          Reporting Period: {selectedPeriod}
        </Typography>

        {/* CompanyForm outside the scrollable area */}
        <Box
          // sx={{
          //   padding: 2,
          //   backgroundColor: '#ffffff',
          //   borderRadius: 3,
          //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          //   marginBottom: 2,
          // }}
        >
          <CompanyForm reportingPeriod={selectedPeriod} />
        </Box>

        {/* CompanyList inside the scrollable area */}
        <Box
        //  key={selectedPeriod} // Force re-render when selectedPeriod changes
          sx={{
            padding: 2,
            backgroundColor: '#ffffff',
            borderRadius: 3,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            maxHeight: 200, // Scrollable height constraint
            overflowY: 'auto',
          }}
        >
          <CompanyList reportingPeriod={selectedPeriod} />
        </Box>
      </>
    {/* )} */}
  </Paper>
</Box>

    </Paper>
  </div>
)}

        
{!showTabs && !startemissiontrack &&  reportingPeriods.length > 0 &&  (
        <>
         <Paper
      sx={{
        padding: 4,
        borderRadius: 4,
        backgroundColor: theme.palette.custom.paperBackground,
        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
        marginBottom: 4,
      }}
    >

          <Typography variant="h5" fontWeight="bold" gutterBottom>Step 4: Please select a Reporting Period for starting emission tracking
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ minHeight: '10vh' }}>
        {periodsWithCompanies.map((period, index) => (
        <Grid item xs={12} md={2} key={index}>
          {/* <Paper elevation={1} style={{ padding: '16px', marginBottom: '1px', textAlign: 'center' }}> */}
            <Button
              variant="contained"
              onClick={() => handleStartEmissionTracking(period)}
              // style={{
              //   width: '100%',
              //   height: '100px',
              //   fontSize: '14px'
              // }}
              sx={{ textTransform: 'none', fontSize: '14px' }}
            >
              Start Emission Tracking for {period} for {parentCompany.name}
            </Button>
            {/* Uncomment the delete button if needed */}
            {/* <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteReportingPeriod(period)}
              style={{
                width: '100%',
                height: '100px',
                fontSize: '18px',
                marginTop: '16px'
              }}
            >
              Delete {period}
            </Button> */}
          {/* </Paper> */}
        </Grid>
      ))}
    </Grid>
      
 
              {/* <Button variant="contained" onClick={handleHideTabs}>
                Add more reporting periods
              </Button> */}
      
      </LocalizationProvider>
      {/* <Grid item xs={12} md={2}>
        <Paper elevation={1} style={{ padding: '16px', marginBottom: '1px', textAlign: 'center', marginTop:'30px' }}>
          <Button variant="contained" onClick={handleHideTabs}>
                      Go Back to adding more reporting periods
              </Button>
          </Paper>
        </Grid> */}
        </Paper>

        </>
      )}

      {showCompanyTabs && selectedPeriod && startemissiontrack && (
        <>
        <Paper 
        sx={{
          padding: 1, 
          borderRadius: 4,
          backgroundColor: theme.palette.custom.paperMainBackground,
          boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
          marginBottom: 1,
        }}
        >
          <Typography variant="h4" fontWeight="bold" gutterBottom
            sx={{ color: 'black', fontWeight: 'medium', marginBottom: 2 }}>
            Subsidiaries for Reporting Period: {selectedPeriod} and Parent company :{parentCompany.name}
            </Typography>
          <CompaniesTabsReportingPeriod reportingPeriod={selectedPeriod} />
          <ReportingPeriodSources selectedReportingPeriod={selectedPeriod} />
          
          <Box
            sx={{
              backgroundColor: 'black',
              color: 'white',
              textAlign: 'center',
              padding: '12px 24px',
              fontSize: '16px',
              borderRadius: '4px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#333',
              },
            }}onClick={handleBackToReportingPeriods}
          >
            Back to the main page 
          </Box> 
          {/* <CompaniesTabsMonthlydata
        reportingPeriod={reportingPeriods[selectedPeriod]}/> */}
        </Paper>
       </>
      )}
    </div>
    </Box>
  );
};

export default MainComponent;
