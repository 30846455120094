import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { ResponsiveBar } from '@nivo/bar';
import { Grid, Box, Typography, Button, Autocomplete, TextField, Divider, Paper  } from '@mui/material';
// import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { SourceContext } from '../contexts/Sourcecontext';
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { useTheme } from "@mui/material/styles";

import DisplayDashboard_StationaryCombustion from './Dash_StationaryComb';
import DisplayDashboard_MobileCombustion from './Dash_MobileComb';
import DisplayDashboard_FugitiveEmissions from './Dash_FugitiveEmissions';
import DisplayDashboard_Electricity from './Dash_Electricity';
import DisplayDashboard_SHC from './Dash_SHC';
import TotalScope1Trend from './Scope1_Graphs/TotalScope1Trend';
import Scope1DoughnutChart from './Scope1_Graphs/Scope1DoughnutChart';
import Scope1BarChartHorizontal from './Scope1_Graphs/Scope1BarChartHorizontal';
import Scope1StackedBarchart from './Scope1_Graphs/Scope1StackedBarchart';


import TotalScope2Trend from './Scope2_Graphs/TotalScope2Trend';
import Scope2BarChartHorizontal from './Scope2_Graphs/Scope2BarChartHorizontal';
import Scope2DoughnutChart from './Scope2_Graphs/Scope2DoughnutChart';
import Scope2StackedBarChart from './Scope2_Graphs/Scope2StackedBarchart';


import StationaryCombCO2FacilityChart from './StationaryCombustiongraphs/StationaryCombCO2FacilityChart';
import StationaryCombCO2MonthlyDoughnut from './StationaryCombustiongraphs/StationaryCombCO2MonthlyChart';
import StationaryCombCO2MonthlyBarChart from './StationaryCombustiongraphs/StationaryCombCO2MonthlyBarChart';
import StationaryCombCO2CH4N2ODoughnut from './StationaryCombustiongraphs/StationaryCombCO2N2OCH4FacilityDonut';


import MobileCombCO2FleetChart from './MobileCombustiongraphs/MobileCombCO2FleetChart';
import MobileCombCO2MonthlyBarChart from './MobileCombustiongraphs/MobileCombCO2MonthlyBarChart';
import MobileCombCO2MonthlyChart from './MobileCombustiongraphs/MobileCombCO2MonthlyChart';

import FugitiveEmissionsCO2FacilityChart from './FugitiveEmissionsgraphs/FugitiveEmissionsCO2FacilityChart';
import FugitiveEmissionsCO2MonthlyBarChart from './FugitiveEmissionsgraphs/FugitiveEmissionsCO2MonthlyBarChart';
import FugitiveEmissionsCO2MonthlyChart from './FugitiveEmissionsgraphs/FugitiveEmissionsCO2MonthlyChart';

import ElectricityCO2FacilityChart from './ElectricityGraphs/ElectricityCO2FacilityChart';
import ElectricityCO2MonthlyBarChart from './ElectricityGraphs/ElectricityCO2MonthlyBarChart';
import ElectricityCO2MonthlyChart from './ElectricityGraphs/ElectricityCO2MonthlyChart';

import SHCCO2FacilityChart from './SteamheatcoolingGraphs/SHCCO2FacilityChart';
import SHCCO2MonthlyBarChart from './SteamheatcoolingGraphs/SHCCO2MonthlyBarChart';
import SHCCO2MonthlyDoughnut from './SteamheatcoolingGraphs/SHCCO2MonthlyChart';

import Scope1Scope2BarChartHorizontal from './Scope1_Graphs/Scope1BarChartHorizontal';
import Dash_Scope1 from './Dash_Scope1';
import Dash_Scope2 from './Dash_Scope2';
import EmissionDashboard from './EmissionsDashboard';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


Chart.register(ArcElement, Tooltip, Legend);


const DisplayDashboard = () => {
  const { reportingPeriods, companies, superStatcombusstate, superStatmobilecomb, fetchAndProcessData,
    separatedFacilitiesT,
    separatedFleetsT,
    separatedelectricityFacilitiesT,
    separatedFugitiveemissionsT,
    separatedSHCfacilitiesT,groupedScope3Data,
    separatedScope3Categories, fetchScope3EmissionsData
   } = useContext(SourceContext);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const theme = useTheme();

  const [AllScopeView, setAllScopeView]=useState(true);
  const [Scope1View, setScope1View]=useState(false);
  const [Scope2View, setScope2View]=useState(false);
  const [Scope3View, setScope3View]=useState(false);
  const [StationaryCombView, setStationaryCombView]=useState(false);
  const [MobileCombustionView, setMobileCombustionView]=useState(false);
  const [FugitiveEmissionsView, setFugitiveEmissionsView]=useState(false);
  const [ElectricityEmissionsView, setElectricityEmissionsView]=useState(false);
  const [SteamheatcoolingView, setSteamheatcoolingView]=useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchAndProcessData();
    fetchScope3EmissionsData();
  }, []);

  const dataMap = {
    "Stationary Combustion": separatedFacilitiesT,
    "Mobile Combustion": separatedFleetsT,
    "Fugitive Emissions": separatedFugitiveemissionsT,
    "Purchased Electricity": separatedelectricityFacilitiesT,
    "Steam Heating/Cooling": separatedSHCfacilitiesT,
};

// Function to calculate total emissions for each category
const calculateTotalEmissions = (dataMap) => {
    const totalEmissions = {};

    // Iterate over the categories in dataMap
    Object.entries(dataMap).forEach(([category, data]) => {
        let categoryTotal = 0;

        // Iterate over the keys within each category
        Object.entries(data).forEach(([key, facilitiesArray]) => {
            // Loop through the facilities array and calculate emissions
            facilitiesArray.forEach((facility) => {
                const emissions = facility.data.emissions?.reduce(
                    (sum, emission) => sum + (emission.eqco2 || 0), // Sum the emissions (eqCO2)
                    0
                );

                if (emissions) {
                    categoryTotal += emissions; // Accumulate emissions for the category
                }
            });
        });

        // Store the total emissions for each category
        totalEmissions[category] = categoryTotal;
    });

    return totalEmissions; // Return the final total emissions for each category
};

// Calculate total emissions for each category in the dataMap
const totalCategoryEmissions = calculateTotalEmissions(dataMap);

console.log("Total Emissions for each category:", totalCategoryEmissions);

  // Define categories for Scope 1 and Scope 2
const scope1Categories = [
  "Stationary Combustion",
  "Mobile Combustion",
  "Purchased Electricity",
];

const scope2Categories = [
  "Fugitive Emissions",
  "Steam Heating/Cooling",
];


// Calculate total Scope 1 emissions
const scope1Total = scope1Categories.reduce(
  (sum, category) => sum + (totalCategoryEmissions[category] || 0),
  0
);

// Calculate total Scope 2 emissions
const scope2Total = scope2Categories.reduce(
  (sum, category) => sum + (totalCategoryEmissions[category] || 0),
  0
);


  // Scope 3 Total
  const calculateScope3 = () => {
    const upstreamCategories = [
      "Purchased Goods and Services",
      "Capital Goods",
      "Fuel- and Energy-Related Activities",
      "Upstream Transport and Distribution",
      "Business Travel",
      "Employee Commuting",
      "Upstream Leased Assets",
    ];
    const downstreamCategories = [
      "Downstream Transport and Distribution",
      "Processing of Sold Products",
      "Use of Sold Products",
      "End-of-Life Treatment of Sold Products",
      "Downstream Leased Assets",
      "Franchises",
      "Investments",
    ];

    const calculateEmissions = (categories) =>
      categories.reduce((total, category) => {
        switch (category) {
          case "Purchased Goods and Services":
            return (
              total +
              (groupedScope3Data?.["Purchased Goods"]?.totalEmissions || 0) +
              (groupedScope3Data?.["Purchased Services"]?.totalEmissions || 0)
            );
          case "Capital Goods":
            return total + (groupedScope3Data?.["Capital Goods"]?.totalEmissions || 0);
          case "Fuel- and Energy-Related Activities":
            return total + (groupedScope3Data?.fuelEnergyEmissions || 0);
          case "Upstream Transport and Distribution":
            return (
              total +
              (groupedScope3Data?.["Upstream Transportation and Distribution"]?.totalEmissions || 0)
            );
          case "Business Travel":
            return total + (groupedScope3Data?.businessTravelEmissions || 0);
          case "Employee Commuting":
            return total + (groupedScope3Data?.employeeCommutingEmissions || 0);
          case "Upstream Leased Assets":
            return total + (groupedScope3Data?.upstreamLeasedAssetsEmissions || 0);
          case "Downstream Transport and Distribution":
            return (
              total +
              (groupedScope3Data?.["Downstream Transportation and Distribution"]?.totalEmissions || 0)
            );
          case "Processing of Sold Products":
            return total + (groupedScope3Data?.processingSoldProductsEmissions || 0);
          case "Use of Sold Products":
            return total + (groupedScope3Data?.useSoldProductsEmissions || 0);
          case "End-of-Life Treatment of Sold Products":
            return total + (groupedScope3Data?.endOfLifeSoldProductsEmissions || 0);
          case "Downstream Leased Assets":
            return total + (groupedScope3Data?.downstreamLeasedAssetsEmissions || 0);
          case "Franchises":
            return total + (groupedScope3Data?.franchisesEmissions || 0);
          case "Investments":
            return total + (groupedScope3Data?.investmentsEmissions || 0);
          default:
            return total;
        }
      }, 0);

    const upstreamEmissions = calculateEmissions(upstreamCategories);
    const downstreamEmissions = calculateEmissions(downstreamCategories);
    return upstreamEmissions + downstreamEmissions;
  };

  const scope3Total = calculateScope3();

  // Grand Total Emissions
  const grandTotal = scope1Total + scope2Total + scope3Total;

  // Percentages
  const scope1Percent = ((scope1Total / grandTotal) * 100).toFixed(2);
  const scope2Percent = ((scope2Total / grandTotal) * 100).toFixed(2);
  const scope3Percent = ((scope3Total / grandTotal) * 100).toFixed(2);

  // Doughnut Data for Scope 1 and Scope 2
  const scope1and2Data = {
    labels: ["Scope 1", "Scope 2", "Scope 3"],
    datasets: [
      {
        data: [scope1Total, scope2Total, scope3Total ],
        backgroundColor: ["#4CAF50", "#2196F3","#808080"],
      },
    ],
  };


  // Prepare data for the doughnut chart for scope 1 and 2 breakdown
const chartDatasetscope1and2 = {
  labels: Object.keys(totalCategoryEmissions), // Categories as labels
  datasets: [
    {
      data: Object.values(totalCategoryEmissions), // Total emissions as data
      backgroundColor: [
        "#FF6384", // Unique color for each category
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
      ],
      hoverBackgroundColor: [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
      ],
    },
  ],
};
  

  // Doughnut Data for Scope 3 Categories
  const scope3Data = {
    labels: [
      "Purchased Goods and Services",
      "Capital Goods",
      "Fuel- and Energy-Related Activities",
      "Upstream Transport and Distribution",
      "Business Travel",
      "Employee Commuting",
      "Downstream Transport and Distribution",
      "Use of Sold Products",
      "End-of-Life Treatment of Sold Products",
    ],
    datasets: [
      {
        data: [
          groupedScope3Data?.["Purchased Goods"]?.totalEmissions || 0,
          groupedScope3Data?.["Capital Goods"]?.totalEmissions || 0,
          groupedScope3Data?.fuelEnergyEmissions || 0,
          groupedScope3Data?.["Upstream Transportation and Distribution"]?.totalEmissions || 0,
          groupedScope3Data?.businessTravelEmissions || 0,
          groupedScope3Data?.employeeCommutingEmissions || 0,
          groupedScope3Data?.["Downstream Transportation and Distribution"]?.totalEmissions || 0,
          groupedScope3Data?.useSoldProductsEmissions || 0,
          groupedScope3Data?.endOfLifeSoldProductsEmissions || 0,
        ],
        backgroundColor: [
          "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#4CAF50", "#F44336",
          "#607D8B",
        ],
      },
    ],
  };


  useEffect(() => {
    fetchAndProcessData();
  }, []);

  const handlePeriodChange = (event, newValue) => {
    setSelectedPeriod(newValue);
    setSelectedCompany(null); // Reset company selection when period changes
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };


  return (
    <Box 
    sx={{
      height: '100vh', // Full viewport height
      display: 'flex', // Flexbox for layout
      flexDirection: 'column', // Stack content vertically
      gap: '16px', // Spacing between items
      padding: '16px', // Inner padding
      overflow: 'hidden', // Prevent content overflow,
      backgroundColor: theme.palette.custom.paperMainBackground,

    }}
  >
    {/* Outer lower box */}
    <Box
      sx={{
        flex: 1, // Occupies remaining height of the parent
        overflow: 'auto', // Adds scroll if content overflows
        display: 'flex',
        flexDirection: 'column',
        
      }}
    >

      <Typography variant="h4" gutterBottom color='black'>
        Emission Analysis  {selectedPeriod}
      </Typography>
    {!AllScopeView && (
      <Button 
    variant="contained" 
    color="primary" 
    onClick={() => {
      setStationaryCombView(false); 
      setMobileCombustionView(false);
      setFugitiveEmissionsView(false);
      setElectricityEmissionsView(false);
      setSteamheatcoolingView(false);
      setScope1View(false);
      setScope2View(false);
      setAllScopeView(true);

    }}
    >
Back to Main Emissions Dashbaord
 </Button>
  )}


      <Grid item xs={12} md={3}>
          <Autocomplete
            options={reportingPeriods}
            value={selectedPeriod}
            onChange={handlePeriodChange}
            renderInput={(params) => <TextField {...params} label="Select Reporting Period" variant="outlined" />}
          />
        </Grid>

{/* <div> */}
  {/* {Scope1View &&(
  <Button 
  variant="contained" 
  color="primary" 
  onClick={() => {setStationaryCombView(true); 
                setMobileCombustionView(false);
                setFugitiveEmissionsView(false);
                setElectricityEmissionsView(false);
                setSteamheatcoolingView(false);
                setScope1View(false);
                setScope2View(false);
                setAllScopeView(false);
  }}
  >
  Stationary Combustion Drill Down
  </Button>
  )} */}
{StationaryCombView &&(
  <Button 
    variant="contained" 
    color="primary" 
    onClick={() => {
      setStationaryCombView(false); 
      setMobileCombustionView(false);
      setFugitiveEmissionsView(false);
      setElectricityEmissionsView(false);
      setSteamheatcoolingView(false);
      setScope1View(true);
      setScope2View(false);
      setAllScopeView(false);

    }}
    >
Back to Scope 1 Analysis
 </Button>)}
 
 {MobileCombustionView &&(
  <Button 
    variant="contained" 
    color="primary" 
    onClick={() => {
      setStationaryCombView(false); 
      setMobileCombustionView(false);
      setFugitiveEmissionsView(false);
      setElectricityEmissionsView(false);
      setSteamheatcoolingView(false);
      setScope1View(true);
      setScope2View(false);
      setAllScopeView(false);

    }}
    >
Back to Scope 1 Analysis
 </Button>)}
 

 {FugitiveEmissionsView &&(
  <Button 
    variant="contained" 
    color="primary" 
    onClick={() => {
      setStationaryCombView(false); 
      setMobileCombustionView(false);
      setFugitiveEmissionsView(false);
      setElectricityEmissionsView(false);
      setSteamheatcoolingView(false);
      setScope1View(true);
      setScope2View(false);
      setAllScopeView(false);

    }}
    >
Back to Scope 1 Analysis
 </Button>)}
 


 {/* {Scope1View &&(
  <Button 
  variant="contained" 
  color="primary" 
  onClick={() => {setStationaryCombView(false); 
  setMobileCombustionView(true);
  setFugitiveEmissionsView(false);
  setElectricityEmissionsView(false);
  setSteamheatcoolingView(false);
  setScope1View(false);
  setScope2View(false);
  setAllScopeView(false);

  
  }}
  >
  Mobile Combustion Drill Down
  </Button>
 )} */}
  


  {ElectricityEmissionsView &&(
  <Button 
    variant="contained" 
    color="primary" 
    onClick={() => {
      setStationaryCombView(false); 
      setMobileCombustionView(false);
      setFugitiveEmissionsView(false);
      setElectricityEmissionsView(false);
      setSteamheatcoolingView(false);
      setScope1View(false);
      setScope2View(true);
      setAllScopeView(false);

    }}
    >
Back to Scope 2 Analysis
 </Button>)}
 

 {SteamheatcoolingView &&(
  <Button 
    variant="contained" 
    color="primary" 
    onClick={() => {
      setStationaryCombView(false); 
      setMobileCombustionView(false);
      setFugitiveEmissionsView(false);
      setElectricityEmissionsView(false);
      setSteamheatcoolingView(false);
      setScope1View(false);
      setScope2View(true);
      setAllScopeView(false);

    }}
    >
Back to Scope 2 Analysis
 </Button>)}



{Scope1View && (
  <Grid
    container
    spacing={2}
    justifyContent="center"
    alignItems="stretch" // Ensures alignment of items vertically
    sx={{
      height: '90%', // Grid stretches to occupy full parent height
      
    }}
  >
    {/* Trend Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
          backgroundColor: theme.palette.custom.paperBackground,

        }}
      >
        <TotalScope1Trend reportingPeriod={selectedPeriod} />
      </Paper>
    </Grid>

    {/* Doughnut Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => {setStationaryCombView(true); 
                          setMobileCombustionView(false);
                          setFugitiveEmissionsView(false);
                          setElectricityEmissionsView(false);
                          setSteamheatcoolingView(false);
                          setScope1View(false);
                          setScope2View(false);
                          setAllScopeView(false);
            }}
            >
            Stationary Combustion Drill Down
            </Button>

          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => {setStationaryCombView(false); 
            setMobileCombustionView(true);
            setFugitiveEmissionsView(false);
            setElectricityEmissionsView(false);
            setSteamheatcoolingView(false);
            setScope1View(false);
            setScope2View(false);
            setAllScopeView(false);

            
            }}
            >
            Mobile Combustion Drill Down
            </Button>
            <Button 
          variant="contained" 
          color="primary" 
          onClick={() => {setStationaryCombView(false); 
          setMobileCombustionView(false);
          setFugitiveEmissionsView(true);
          setElectricityEmissionsView(false);
          setSteamheatcoolingView(false);
          setScope1View(false);
          setScope2View(false);
          setAllScopeView(false);

          }}
          >
          Fugitive Emissions Drill Down
          </Button>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <Scope1DoughnutChart reportingPeriod={selectedPeriod} />
      </Paper>
    </Grid>

    {/* Bar Chart */}
    <Grid item xs={12} md={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <Scope1BarChartHorizontal reportingPeriod={selectedPeriod} />
      </Paper>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <Scope1StackedBarchart reportingPeriod= {selectedPeriod}/>
      </Paper>
    </Grid>
  </Grid>
)}
  
  {Scope2View &&(
      <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="stretch" // Ensures alignment of items vertically
      sx={{
        height: '90%', // Grid stretches to occupy full parent height
      }}
    >
      
      {/* Trend Chart */}
      <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Paper
          elevation={5}
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Stretch to equal height
          }}
        >
          <TotalScope2Trend reportingPeriod={selectedPeriod} />
        </Paper>
      </Grid>
  
      {/* Doughnut Chart */}
      <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Button 
            variant="contained" 
            color="primary" 
            onClick={() => {setStationaryCombView(false); 
            setMobileCombustionView(false);
            setFugitiveEmissionsView(false);
            setElectricityEmissionsView(true);
            setSteamheatcoolingView(false);
            setScope1View(false);
            setScope2View(false);
            setAllScopeView(false);

            }}
            >
            Electricity Emissions Drill Down
            </Button>
            <Button 
            variant="contained" 
            color="primary" 
            onClick={() => {setStationaryCombView(false); 
            setMobileCombustionView(false);
            setFugitiveEmissionsView(false);
            setElectricityEmissionsView(false);
            setSteamheatcoolingView(true);
            setScope1View(false);
            setScope2View(false);
            setAllScopeView(false);

            }}
            >
            Steam heating cooling Drill Down
            </Button>
        <Paper
          elevation={5}
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Stretch to equal height
          }}
        >
          <Scope2DoughnutChart reportingPeriod={selectedPeriod} />
          
        </Paper>
        
      </Grid>
  
      {/* Bar Chart */}
      <Grid item xs={12} md={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Paper
          elevation={5}
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Stretch to equal height
          }}
        >
          <Scope2BarChartHorizontal reportingPeriod={selectedPeriod} />
        </Paper>
        <Paper
          elevation={5}
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Stretch to equal height
          }}
        >
          {/* <Scope2 reportingPeriod= {selectedPeriod}/> */}
        </Paper>
      </Grid>
    </Grid>
      
      
      )}




  {StationaryCombView &&(
    <Grid
    container
    spacing={2}
    justifyContent="center"
    alignItems="stretch" // Ensures alignment of items vertically
    sx={{
      height: '90%', // Grid stretches to occupy full parent height
    }}
  >
    
    {/* Trend Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <StationaryCombCO2FacilityChart reportingPeriod={selectedPeriod} />
      </Paper>
    </Grid>

    {/* Doughnut Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
    
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <StationaryCombCO2MonthlyDoughnut reportingPeriod={selectedPeriod} />
        
      </Paper>
      
    </Grid>

    {/* Bar Chart */}
    <Grid item xs={12} md={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <StationaryCombCO2MonthlyBarChart reportingPeriod={selectedPeriod} />
      </Paper>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        {/* <Scope2 reportingPeriod= {selectedPeriod}/> */}
      </Paper>
    </Grid>
  </Grid>
  )}

{MobileCombustionView &&(
   <Grid
   container
   spacing={2}
   justifyContent="center"
   alignItems="stretch" // Ensures alignment of items vertically
   sx={{
     height: '90%', // Grid stretches to occupy full parent height
   }}
 >
   
   {/* Trend Chart */}
   <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
     <Paper
       elevation={5}
       sx={{
         padding: 2,
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         alignItems: 'center',
         height: '100%', // Stretch to equal height
       }}
     >
       <MobileCombCO2FleetChart reportingPeriod={selectedPeriod} />
     </Paper>
   </Grid>

   {/* Doughnut Chart */}
   <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
   
     <Paper
       elevation={5}
       sx={{
         padding: 2,
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         alignItems: 'center',
         height: '100%', // Stretch to equal height
       }}
     >
       <MobileCombCO2MonthlyBarChart reportingPeriod={selectedPeriod} />
       
     </Paper>
     
   </Grid>

   {/* Bar Chart */}
   <Grid item xs={12} md={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
     <Paper
       elevation={5}
       sx={{
         padding: 2,
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         alignItems: 'center',
         height: '100%', // Stretch to equal height
       }}
     >
       <MobileCombCO2MonthlyChart reportingPeriod={selectedPeriod} />
     </Paper>
     <Paper
       elevation={5}
       sx={{
         padding: 2,
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         alignItems: 'center',
         height: '100%', // Stretch to equal height
       }}
     >
       {/* <Scope2 reportingPeriod= {selectedPeriod}/> */}
     </Paper>
   </Grid>
 </Grid>
  )}

{FugitiveEmissionsView &&(
    <Grid
    container
    spacing={2}
    justifyContent="center"
    alignItems="stretch" // Ensures alignment of items vertically
    sx={{
      height: '90%', // Grid stretches to occupy full parent height
    }}
  >
    
    {/* Trend Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <FugitiveEmissionsCO2FacilityChart reportingPeriod={selectedPeriod} />
      </Paper>
    </Grid>
 
    {/* Doughnut Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
    
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <FugitiveEmissionsCO2MonthlyBarChart reportingPeriod={selectedPeriod} />
        
      </Paper>
      
    </Grid>
 
    {/* Bar Chart */}
    <Grid item xs={12} md={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <FugitiveEmissionsCO2MonthlyChart reportingPeriod={selectedPeriod} />
      </Paper>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        {/* <Scope2 reportingPeriod= {selectedPeriod}/> */}
      </Paper>
    </Grid>
  </Grid>
  )}

{ElectricityEmissionsView &&(
    <Grid
    container
    spacing={2}
    justifyContent="center"
    alignItems="stretch" // Ensures alignment of items vertically
    sx={{
      height: '90%', // Grid stretches to occupy full parent height
    }}
  >
    
    {/* Trend Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <ElectricityCO2FacilityChart reportingPeriod={selectedPeriod} />
      </Paper>
    </Grid>
 
    {/* Doughnut Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
    
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <ElectricityCO2MonthlyBarChart reportingPeriod={selectedPeriod} />
        
      </Paper>
      
    </Grid>
 
    {/* Bar Chart */}
    <Grid item xs={12} md={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <ElectricityCO2MonthlyChart reportingPeriod={selectedPeriod} />
      </Paper>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
      </Paper>
    </Grid>
  </Grid>
  )}
  {SteamheatcoolingView && (
    <Grid
    container
    spacing={2}
    justifyContent="center"
    alignItems="stretch" // Ensures alignment of items vertically
    sx={{
      height: '90%', // Grid stretches to occupy full parent height
    }}
  >
    
    {/* Trend Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <SHCCO2FacilityChart reportingPeriod={selectedPeriod} />
      </Paper>
    </Grid>
 
    {/* Doughnut Chart */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
    
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <SHCCO2MonthlyBarChart reportingPeriod={selectedPeriod} />
        
      </Paper>
      
    </Grid>
 
    {/* Bar Chart */}
    <Grid item xs={12} md={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
        <SHCCO2MonthlyDoughnut reportingPeriod={selectedPeriod} />
      </Paper>
      <Paper
        elevation={5}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Stretch to equal height
        }}
      >
      </Paper>
    </Grid>
  </Grid>
  )}
{/* </div> */}


{/* Box for lowest part */}

{(AllScopeView &&
  <Grid container  spacing={2} justifyContent="center" alignItems="center" style={{ height: '100vh'}}
  rowSpacing={0} // Smaller vertical space
  columnSpacing={1} // Keep horizontal spacing unchanged
   >
      {/* Scope 1 Box */}
      <Grid item xs={12} md={4}>
        <Paper elevation={5} style={{ padding: 20 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" style={{ fontWeight: 'bold', color: '#721c24' }}>
              Scope 1
            </Typography>
            <Typography variant="h5" style={{ color: '#721c24', marginTop: 10 }}>
              {scope1Total} tCO₂e
            </Typography>
            <Typography variant="h6" style={{ color: '#721c24', marginTop: 5 }}>
              {scope1Percent}% of total
            </Typography>
            
<Button 
    variant="contained" 
    color="primary" 
    onClick={() => {
      setStationaryCombView(false); 
      setMobileCombustionView(false);
      setFugitiveEmissionsView(false);
      setElectricityEmissionsView(false);
      setSteamheatcoolingView(false);
      setScope1View(true);
      setScope2View(false);
      setAllScopeView(false);

    }}
    >
    Scope 1 data analysis
    </Button>
          </Box>
          
        </Paper>
        
      </Grid>

      {/* Scope 2 Box */}
      <Grid item xs={12} md={4}>
        <Paper elevation={5} style={{ padding: 20 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" style={{ fontWeight: 'bold', color: '#004085' }}>
              Scope 2
            </Typography>
            <Typography variant="h5" style={{ color: '#004085', marginTop: 10 }}>
              {scope2Total} tCO₂e
            </Typography>
            <Typography variant="h6" style={{ color: '#004085', marginTop: 5 }}>
              {scope2Percent}% of total
            </Typography>
            
              <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => {
                    setStationaryCombView(false); 
                    setMobileCombustionView(false);
                    setFugitiveEmissionsView(false);
                    setElectricityEmissionsView(false);
                    setSteamheatcoolingView(false);
                    setScope1View(false);
                    setScope2View(true);
                    setAllScopeView(false);
                  }}
                  >
    Scope 2 data analysis
    </Button>
          </Box>
        </Paper>
      </Grid>

      {/* Scope 3 Box */}
      <Grid item xs={12} md={4}>
        <Paper elevation={5} style={{ padding: 20 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" style={{ fontWeight: 'bold', color: '#155724' }}>
              Scope 3
            </Typography>
            <Typography variant="h5" style={{ color: '#155724', marginTop: 10 }}>
              {scope3Total} tCO₂e
            </Typography>
            <Typography variant="h6" style={{ color: '#155724', marginTop: 5 }}>
              {scope3Percent}% of total
            </Typography>
            <Button
        variant="contained" 
        color="primary" 
        onClick={() => {
          navigate('/app/Scope3Dashboard', {
            state: {
              selectedPeriod: reportingPeriods, // Pass the selected reporting period
              // selectedCompany: company,          // Pass selected company if needed
            },
          });
        }}
      >
        Scope 3 Data Analysis
      </Button>
          </Box>
        </Paper>
      </Grid>
      
      {/* Scope 1 and 2 Doughnut */}
      <Grid item xs={12} md={4}  style={{ marginTop: 0 }}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h6">Scope Breakdown</Typography>
            <Doughnut data={scope1and2Data} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h6">Scope 1 and 2 Breakdown</Typography>
            <Doughnut data={chartDatasetscope1and2} />
          </Paper>
        </Grid>

         {/* Scope 3 Doughnut */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h6">Scope 3 Breakdown</Typography>
            <Doughnut data={scope3Data} />
          </Paper>
        </Grid>
    </Grid>
     )}

      {/* </Grid> */}
    {/* </Box> */}

</Box>
    </Box>
  );
};

export default DisplayDashboard;