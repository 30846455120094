import React, { useState, useContext, useEffect } from 'react';
import {
  Grid, Drawer, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Tabs, Tab, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { SourceContext } from '../contexts/Sourcecontext';
import axios from 'axios'


const ParentCompanyForm = () => {
  const [parentCompanyName, setParentCompanyName] = useState('');
  const [parentCompanyCountry, setParentCompanyCountry] = useState('');
  const [parentCompanyState, setParentCompanyState] = useState('');
  const { addParentCompany, deleteParentCompany, parentCompany } = useContext(SourceContext);
  const apiUrl = process.env.REACT_APP_API_URL

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parentCompanyName.trim() && parentCompanyCountry.trim() && parentCompanyState.trim()) {
      addParentCompany({
        name: parentCompanyName.trim(),
        country: parentCompanyCountry.trim(),
        state: parentCompanyState.trim(),
      });
    }
  };

  const handleDelete = () => {
    deleteParentCompany();
  };

  useEffect(() => {
    if (parentCompany) {
      setParentCompanyName(parentCompany.name);
      setParentCompanyCountry(parentCompany.country);
      setParentCompanyState(parentCompany.state);
    } else {
      setParentCompanyName('');
      setParentCompanyCountry('');
      setParentCompanyState('');
    }
  }, [parentCompany]);

  
  const handleRequest = () => {
    try {
        // console.log(selectedParameter)
        // const parameter= selectedParameter
        // const response = await axios.post('http://localhost:5000/hourlydata', { selectedHours:selectedHours,
        // const response = axios.post(apiUrl+'/parentdata', { company:parentCompanyName,
        //   const response = axios.post('http://localhost:5000/api/parentdata', { company:parentCompanyName,
        // country:parentCompanyCountry, state:parentCompanyState });
        // console.log(apiUrl)
        // console.log(parentCompany)
        // console.log(parentCompanyCountry)
        // console.log(parentCompanyState)
        
        
    }
    catch (error) {
      // Handle any errors here
      console.error(error.response);
    }
    };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center" >
        <Grid item xs={12} sm={4}>
          <TextField
            label="Parent Company Name"
            variant="outlined"
            fullWidth
            value={parentCompanyName}
            onChange={(e) => setParentCompanyName(e.target.value)}
            disabled={!!parentCompany}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* <TextField
            label="Country"
            variant="outlined"
            fullWidth
            value={parentCompanyCountry}
            onChange={(e) => setParentCompanyCountry(e.target.value)}
            disabled={!!parentCompany}
          />
           */}
            <FormControl variant="outlined" fullWidth>
            <InputLabel>Country</InputLabel>
            <Select
              value={parentCompanyCountry}
              onChange={(e) => setParentCompanyCountry(e.target.value)}
              label="Country"
              disabled={!!parentCompany}
            >
              <MenuItem value="India">India</MenuItem>
              <MenuItem value="Australia">Australia</MenuItem>
              <MenuItem value="New Zealand">New Zealand</MenuItem>
              <MenuItem value="USA">USA</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="State"
            variant="outlined"
            fullWidth
            value={parentCompanyState}
            onChange={(e) => setParentCompanyState(e.target.value)}
            disabled={!!parentCompany}
          />
        </Grid>
        {/* {!parentCompany ? ( */}
        <Grid container spacing={2} justifyContent="center" marginTop={"1px"}>
      <Grid item>
        <Button 
          variant="contained"
          color="primary"
          size="large"
          onClick={handleRequest}
          sx={{ textTransform: 'none' }}
        >
        
          Add Parent Company
        </Button>
      </Grid>
      <Grid item>
        <Button 
          variant="contained"
          color="error"
          size="large"
          sx={{ textTransform: 'none' }}
          onClick={handleDelete}
        >
          Delete Parent Company
        </Button>
      </Grid>
    </Grid>
        {/* )} */}
      </Grid>
    </form>
  );
};

export default ParentCompanyForm;
