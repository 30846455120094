import React, { useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import { SourceContext } from "../../contexts/Sourcecontext";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FacilityCO2CH4N2ODoughnuts = ({reportingPeriod}) => {
  const { separatedFacilitiesT } = useContext(SourceContext);

  // Aggregate data for CO2, CH4, and N2O
  const chartData = Object.entries(separatedFacilitiesT).reduce(
    (acc, [key, facilitiesArray]) => {

          // Extract the reporting period from the key (key format: "stationarycombustion_Hyper_sub1_July 2018_08/2017 - 08/2018")
      const keyParts = key.split("_");
      const keyPeriod = keyParts[keyParts.length - 1]; // This gives "08/2017 - 08/2018"
      // Skip if the reporting period doesn't match
      if (keyPeriod !== reportingPeriod) {
        return acc;
      }
      facilitiesArray.forEach((facility) => {
        const emissions = facility?.data?.emissions || [];
        const facilityDescription = facility?.facilityDescription || `Facility ${facility.id}`;

        // Calculate totals for each gas
        const totalCO2 = emissions.reduce(
          (sum, emission) => sum + (emission.co2 || 0),
          0
        );
        const totalCH4 = emissions.reduce(
          (sum, emission) => sum + (emission.ch4 || 0),
          0
        );
        const totalN2O = emissions.reduce(
          (sum, emission) => sum + (emission.n20 || 0),
          0
        );

        // Check if the facility is already in the labels array
        const existingIndex = acc.labels.findIndex((label) => label === facilityDescription);

        if (existingIndex > -1) {
          // If the facility already exists, add to its gas values
          acc.values.CO2[existingIndex] += totalCO2;
          acc.values.CH4[existingIndex] += totalCH4;
          acc.values.N2O[existingIndex] += totalN2O;
        } else {
          // Otherwise, add a new entry
          acc.labels.push(facilityDescription);
          acc.values.CO2.push(totalCO2);
          acc.values.CH4.push(totalCH4);
          acc.values.N2O.push(totalN2O);
        }
      });
      return acc;
    },
    { labels: [], values: { CO2: [], CH4: [], N2O: [] } }
  );

  // Shared chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // Only enable for the first chart
        position: "top",
        labels: {
          color: "black", // Legend label color
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `${tooltipItem.label}: ${value.toFixed(2)} units`;
          },
        },
      },
    },
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <h2>Facility Emissions Breakdown</h2>
      <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
        {/* CO2 Doughnut */}
        <div style={{ width: "300px" }}>
          <Doughnut
            data={{
              labels: chartData.labels,
              datasets: [
                {
                  label: "CO2",
                  data: chartData.values.CO2,
                  backgroundColor: chartData.labels.map(
                    (_, index) => `hsl(${(index * 360) / chartData.labels.length}, 100%, 70%)`
                  ),
                  hoverOffset: 4,
                },
              ],
            }}
            options={{
              ...options,
              plugins: { ...options.plugins, legend: { display: true } }, // Legend for this chart
            }}
          />
          <p style={{ textAlign: "center" }}>CO2 Emissions</p>
        </div>

        {/* CH4 Doughnut */}
        <div style={{ width: "300px" }}>
          <Doughnut
            data={{
              labels: chartData.labels,
              datasets: [
                {
                  label: "CH4",
                  data: chartData.values.CH4,
                  backgroundColor: chartData.labels.map(
                    (_, index) => `hsl(${(index * 360) / chartData.labels.length}, 100%, 50%)`
                  ),
                  hoverOffset: 4,
                },
              ],
            }}
            options={{
              ...options,
              plugins: { ...options.plugins, legend: { display: true } }, // No legend for this chart
            }}
          />
          <p style={{ textAlign: "center" }}>CH4 Emissions</p>
        </div>

        {/* N2O Doughnut */}
        <div style={{ width: "300px" }}>
          <Doughnut
            data={{
              labels: chartData.labels,
              datasets: [
                {
                  label: "N2O",
                  data: chartData.values.N2O,
                  backgroundColor: chartData.labels.map(
                    (_, index) => `hsl(${(index * 360) / chartData.labels.length}, 100%, 30%)`
                  ),
                  hoverOffset: 4,
                },
              ],
            }}
            options={{
              ...options,
              plugins: { ...options.plugins, legend: { display: true } }, // No legend for this chart
            }}
          />
          <p style={{ textAlign: "center" }}>N2O Emissions</p>
        </div>
      </div>
    </div>
  );
};

export default FacilityCO2CH4N2ODoughnuts;
