import React, { useState, useContext } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { SourceContext } from '../contexts/Sourcecontext';
import StationaryCombustionDialog from './StationaryCombustionDialog';
import dayjs from 'dayjs';
import MobileResourceDialog from './MobileResourceDialog'
import RefreigerationDialog from './RefrigerationDialog';
import FugitiveDialog from './FugitiveDialog';
import PurchasedElectricityDialog from './PurchasedElectricityDialog';
import PurchasedSteamHeatingCoolingDialog from './PurchasedSteamHeatingCoolingDialog';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import StationaryCombustionExcelUpload from './StationaryCombustionExcelUploader';
import MobileResourceExcelUpload from './MobileResourceExcelUploader';
import FugitiveExcelUpload from './FugitiveExcelUploader';
import ElectricityExcelUpload from './ElectricityExcelUploader';
import PurchasedSteamHeatingCoolingExcelUploader from './PurchaseheatandcoolingExcelUploader';
import statcombution_logo from './Logos/stationary_combustion.png';
import mobilecomb_logo from './Logos/Mobile_sources_logo.png'
import fugitive_logo from './Logos/fugitiveEmission.png'
import { useTheme } from "@mui/material/styles";

 
//scope 2 logos
import electricity_logo from './Logos/electricity_icon.png'
import steam_logo from './Logos/steam.png'

// Import other specialized dialogs as needed

const MonthlyDataEntry = ({ reportingPeriod, company }) => {
  const { sourceSelection } = useContext(SourceContext);
  const { superStatcombusstate } = useContext(SourceContext); // Access the superState
  const { superStatmobilecomb, separatedFacilitiesT, separatedFugitiveemissionsT, separatedelectricityFacilitiesT, separatedSHCfacilitiesT } = useContext(SourceContext); // Access the superState
  const { superState } = useContext(SourceContext); // Access the superState
  const { fetchAndProcessData}= useContext(SourceContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentSource, setCurrentSource] = useState('');
  const [formData, setFormData] = useState({});
  
  // useEffect(() => {
  //   fetchAndProcessData();
  // });

  // Dynamically generate months array based on reporting period
  const generateMonthsArray = () => {
    const startDate = dayjs(reportingPeriod.split(' - ')[0], 'MM/YYYY');
    const endDate = dayjs(reportingPeriod.split(' - ')[1], 'MM/YYYY');
    const months = [];
    let currentMonth = startDate;
    while (currentMonth.isBefore(endDate) || currentMonth.isSame(endDate, 'month')) {
      months.push(currentMonth.format('MMMM YYYY')); // Format month as "Month Year"
      currentMonth = currentMonth.add(1, 'month');
    }
    return months;
  };

  const months = generateMonthsArray();

  const handleOpenDialog = (month, source) => {
    setCurrentMonth(month);
    setCurrentSource(source);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentMonth('');
    setCurrentSource('');
    setFormData({});
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitData = () => {
    console.log(`Data for ${company.subsidiary_name} - ${currentSource} in ${currentMonth}:`, formData);
    handleCloseDialog();
  };

  const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
  const selectedSources = sourceSelection[periodKey] || [];
    const theme = useTheme();
  
  return (
    // <Box p={3}>
      <Grid container spacing={3}>
    {/* Header */}
    <Grid item xs={12}>
      <h3>Monthly Data Entry for {company.subsidiary_name}</h3>
    </Grid>

    {/* Table */}
    <Grid item xs={12}>
      {/* <div>
        <h3>Monthly Data Entry for {company.subsidiary_name}</h3> */}
        <TableContainer
      // component={Paper}
      sx={{
        display: 'block',
        overflowY: 'auto',
        overflowX: 'auto',
        maxHeight: '80vh', // Limit height to 80% of the viewport
        maxWidth: '80%', // Limit width to fit within the viewport
        margin: '0 auto', // Center the table horizontally
      }}
    >
          <Table style={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
              <TableCell
                  style={{
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                    fontWeight: 'bold', // Makes the text bold
                    fontSize: '1.2rem', // Adjusts the font size (you can increase this value as needed)
                  }}
                >
                  Source
                </TableCell>
                {months.map((month) => (
                  <TableCell style={{
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                    fontWeight: 'bold', // Makes the text bold
                    fontSize: '1.0rem', // Adjusts the font size (you can increase this value as needed)
                    // minWidth: 150, // Ensures the Source column has a fixed width
                    }} key={month}>{month}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedSources.map((source, sourceIndex) => (
                <TableRow key={sourceIndex}>
                <TableCell
  style={{
    position: 'sticky',
    // left: 0,
    backgroundColor: 'white',
    // zIndex: 1,
    // minWidth: 150, // Ensures the Source column has a fixed width
    display: 'flex',
    alignItems: 'center', // Align items vertically
    // justifyContent: 'space-between', // Distribute space between elements
    padding: '25px',
  }}
>
  {/* Source name with bold font */}
  <span style={{ fontWeight: 'bold', marginRight: '8px' }}>{source}</span>

  {/* Wrapper for logo and uploader */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    {/* Source Logo */}
    {source === 'Stationary Combustion' && (
      <img
        src={statcombution_logo}
        alt="Stationary Combustion Logo"
        style={{ height: '30px', marginRight: '10px' }}
      />
    )}
    {source === 'Mobile sources' && (
      <img
        src={mobilecomb_logo}
        alt="Mobile Sources Logo"
        style={{ height: '30px', marginRight: '10px' }}
      />
    )}
    {source === 'Fugitive Emissions - Refrigeration' && (
      <img
        src={fugitive_logo}
        alt="Fugitive Emissions Logo"
        style={{ height: '30px', marginRight: '10px' }}
      />
    )}
    {source === 'Purchased Electricity' && (
      <img
        src={electricity_logo}
        alt="Purchased Electricity Logo"
        style={{ height: '30px', marginRight: '10px' }}
      />
    )}
    {source === 'Purchase steam, heat and cooling' && (
      <img
        src={steam_logo}
        alt="Steam Heat Cooling Logo"
        style={{ height: '30px', marginRight: '10px' }}
      />
    )}

    {/* Source-specific Excel uploaders */}
    {source === 'Stationary Combustion' && (
      <StationaryCombustionExcelUpload
        reportingPeriod={reportingPeriod}
        company={company}
      />
    )}
    {source === 'Mobile sources' && (
      <MobileResourceExcelUpload
        reportingPeriod={reportingPeriod}
        company={company}
      />
    )}
    {source === 'Fugitive Emissions - Refrigeration' && (
      <FugitiveExcelUpload
        reportingPeriod={reportingPeriod}
        company={company}
      />
    )}
    {source === 'Purchased Electricity' && (
      <ElectricityExcelUpload
        reportingPeriod={reportingPeriod}
        company={company}
      />
    )}
    {source === 'Purchase steam, heat and cooling' && (
      <PurchasedSteamHeatingCoolingExcelUploader
        reportingPeriod={reportingPeriod}
        company={company}
      />
    )}
  </div>
</TableCell>
                {months.map((month) => {
                  // Only handle "Stationary Combustion" source
                  if (source === 'Stationary Combustion') {
                    const key = `stationarycombustion_${company.subsidiary_name}_${month}_${reportingPeriod}`;
                    const dataEntered = separatedFacilitiesT[key] && separatedFacilitiesT[key].length > 0;
                    const data = separatedFacilitiesT[key] || [];
                    // console.log("dataEntered",superStatcombusstate[key])
                    return (
                      <TableCell style={{
                        position: 'sticky',
                        // left: 0,
                        backgroundColor: 'white'}} key={month}>
                        {dataEntered ? (
                          <div>
                          {(() => {
                            const totalEqco2 = data.reduce((total, item) => {
                              const itemTotal = item.data.emissions.reduce((itemSum, emission) => {
                                return itemSum + (emission.eqco2 || 0);
                              }, 0);
                              return total + itemTotal;
                            }, 0);
                            return <span>{totalEqco2.toFixed(2)}</span>; // Display the total eqco2
                          })()}
                          <Button variant="contained"
                          color="primary"
                          size="large"
                          sx={{ textTransform: 'none' }}
                    onClick={() => handleOpenDialog(month, source)}>EDIT</Button>
                        </div>
                        ) : (
                          <Button 
                          color="primary"
                          size="large"
                          sx={{ textTransform: 'none' }}
                          onClick={() => handleOpenDialog(month, source)}>+</Button>
                        )}
                      </TableCell>
                  );
                  } 
                  if (source === 'Mobile sources') {
                    const key = `mobilesources_${company.subsidiary_name}_${month}_${reportingPeriod}`;
                    const dataEntered = superStatmobilecomb[key] && superStatmobilecomb[key].length > 0;
                    const data = superStatmobilecomb[key] || [];
                    
                    // console.log("dataEntered",superStatmobilecomb[key])
                    return (
                      <TableCell style={{
                        position: 'sticky',
                        // left: 0,
                        backgroundColor: 'white'}} key={month}>
                        {dataEntered ? (
                          <div>
                          {(() => {
                            const totalEqco2 = data.reduce((total, item) => {
                              const itemTotal = item.data.emissions.reduce((itemSum, emission) => {
                                return itemSum + (emission.eqco2 || 0);
                              }, 0);
                              return total + itemTotal;
                            }, 0);
                            return <span>{totalEqco2.toFixed(2)}</span>; // Display the total eqco2
                          })()}
                          <Button onClick={() => handleOpenDialog(month, source)}>EDIT</Button>
                        </div>
                          
                        ) : (
                          <Button onClick={() => handleOpenDialog(month, source)}>+</Button>
                        )}
                      </TableCell>
                    );
                  }
                  if (source === 'Purchased Electricity') {
                    const key = `purchasedelectricity_${company.subsidiary_name}_${month}_${reportingPeriod}`;
                    const dataEntered = separatedelectricityFacilitiesT[key] && separatedelectricityFacilitiesT[key].length > 0;
                    const data = separatedelectricityFacilitiesT[key] || [];

                    // console.log("dataEntered",superStatmobilecomb[key])
                    return (
                      <TableCell style={{
                        position: 'sticky',
                        // left: 0,
                        backgroundColor: 'white'}} key={month}>
                        {dataEntered ? (
                          <div>
                          {(() => {
                            const totalEqco2 = data.reduce((total, item) => {
                              const itemTotal = item.data.emissions.reduce((itemSum, emission) => {
                                return itemSum + (emission.eqco2 || 0);
                              }, 0);
                              return total + itemTotal;
                            }, 0);
                            return <span>{totalEqco2.toFixed(2)}</span>; // Display the total eqco2
                          })()}
                          <Button onClick={() => handleOpenDialog(month, source)}>EDIT</Button>
                        </div>
                          
                        ) : (
                          <Button onClick={() => handleOpenDialog(month, source)}>+</Button>
                        )}
                      </TableCell>
                    );
                  };
                  if (source === 'Fugitive Emissions - Refrigeration') {
                    const key = `fugitiveemissions_${company.subsidiary_name}_${month}_${reportingPeriod}`;
                    const dataEntered = separatedFugitiveemissionsT[key] && separatedFugitiveemissionsT[key].length > 0;
                   
                    const data = separatedFugitiveemissionsT[key] || [];
                    console.log("data from monthly data entry fugitive Em", data)
                    
                    console.log("dataEntered fugitive emissions",separatedFugitiveemissionsT[key])
                    return (
                      <TableCell style={{
                        position: 'sticky',
                        // left: 0,
                        backgroundColor: 'white'}} key={month}>
                        {dataEntered ? (
                          <div>
                          {(() => {
                            const totalEqco2 = data.reduce((total, item) => {
                              const itemTotal = item.data.emissions.reduce((itemSum, emission) => {
                                console.log("emission CO2 from fugitive", emission.eqco2)
                                return itemSum + (emission.eqco2 || 0);
                              }, 0);
                              return total + itemTotal;
                            }, 0);
                            return <span>{totalEqco2.toFixed(2)}</span>; // Display the total eqco2
                          })()}
                          <Button onClick={() => handleOpenDialog(month, source)}>EDIT</Button>
                        </div>
                          
                        ) : (
                          <Button onClick={() => handleOpenDialog(month, source)}>+</Button>
                        )}
                      </TableCell>
                    );
                  }
                  if (source === 'Purchase steam, heat and cooling') {
                    const key = `steamheatingcooling_${company.subsidiary_name}_${month}_${reportingPeriod}`;
                    const dataEntered = separatedSHCfacilitiesT[key] && separatedSHCfacilitiesT[key].length > 0;
                   
                    const data = separatedSHCfacilitiesT[key] || [];
                    console.log("data from monthly data entry fugitive Em", data)
                    
                    console.log("dataEntered fugitive emissions",separatedSHCfacilitiesT[key])
                    return (
                      <TableCell style={{
                        position: 'sticky',
                        // left: 0,
                        backgroundColor: 'white'}} key={month}>
                        {dataEntered ? (
                          <div>
                          {(() => {
                            const totalEqco2 = data.reduce((total, item) => {
                              const itemTotal = item.data.emissions.reduce((itemSum, emission) => {
                                console.log("emission CO2 from fugitive", emission.eqco2)
                                return itemSum + (emission.eqco2 || 0);
                              }, 0);
                              return total + itemTotal;
                            }, 0);
                            return <span>{totalEqco2.toFixed(2)}</span>; // Display the total eqco2
                          })()}
                          <Button onClick={() => handleOpenDialog(month, source)}>EDIT</Button>
                        </div>
                          
                        ) : (
                          <Button onClick={() => handleOpenDialog(month, source)}>+</Button>
                        )}
                      </TableCell>
                    );
                  }
                  else {
                    // For other sources, always show "+"
                    return (
                      <TableCell key={month}>
                        <Button onClick={() => handleOpenDialog(month, source)}>+</Button>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
              ))}
            </TableBody>
          </Table>
          
        </TableContainer>
        </Grid>

      {currentSource === 'Stationary Combustion' && (
       
        <StationaryCombustionDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitData}
        formData={formData}
        handleInputChange={handleInputChange}
        source={currentSource}
        month={currentMonth}
        company={company.subsidiary_name}
        reportingPeriod={reportingPeriod}
      />
      )}
      {currentSource === 'Mobile sources' && (
        <MobileResourceDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitData}
          formData={formData}
          handleInputChange={handleInputChange}
          source={currentSource}
          month={currentMonth}
          company={company.subsidiary_name}
          reportingPeriod={reportingPeriod}
        />
      )}
      {currentSource === 'Fugitive Emissions - Refrigeration' && (
        <FugitiveDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitData}
          formData={formData}
          handleInputChange={handleInputChange}
          source={currentSource}
          month={currentMonth}
          company={company.subsidiary_name}
          reportingPeriod={reportingPeriod}
        />
      )}
      {currentSource === 'Purchased Electricity' && (
        <PurchasedElectricityDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitData}
          formData={formData}
          handleInputChange={handleInputChange}
          source={currentSource}
          month={currentMonth}
          company={company.subsidiary_name}
          reportingPeriod={reportingPeriod}
        />
      )}
      {currentSource === 'Purchase steam, heat and cooling' && (
        <PurchasedSteamHeatingCoolingDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitData}
          formData={formData}
          handleInputChange={handleInputChange}
          source={currentSource}
          month={currentMonth}
          company={company.subsidiary_name}
          reportingPeriod={reportingPeriod}
        />
      )}
      {/* Add more conditional renders for other sources */}
    {/* </div> */}
    </Grid>
    // </Box>
  );
};

export default MonthlyDataEntry;

