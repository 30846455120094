import React, { useState, useEffect, useContext } from 'react';
import {
  TableContainer, Paper,Drawer, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Tabs, Tab, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { ArrowDownward, Delete } from '@mui/icons-material';
import { SourceContext } from '../contexts/Sourcecontext';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { convertLegacyOperators } from '@mui/x-data-grid/internals';
import statcombution_logo from './Logos/stationary_combustion.png';
const apiUrl = process.env.REACT_APP_API_URL_BACKEND
;

const StationaryCombustionDialog = ({ open, onClose, onSubmit, source, month, year, company, reportingPeriod }) => {
  const initialFormData = {
    id: '',
    facilityDescription: '',
    facilityArea: '',
    fuels: [{ fuelCombusted: '', quantityCombusted: '', units: '', heatingvalue:'' }],
  };

  const { parentCompany, facilities = [], setFacilities } = useContext(SourceContext);
  const { superStatcombusstate, separatedFacilitiesT, fetchAndProcessData } = useContext(SourceContext);
  const [currentTab, setCurrentTab] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const [dialogData, setDialogData] = useState(null);
  // const [separatedFacilitiesT, setSeparatedFacilitiesT] = useState({});
  // const { superStatcombusstate, setsuperStatcombusstate } = useContext(SourceContext);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };


  useEffect(() => {
    if (open) {
      fetchAndProcessData();
    }
  }, []);

  useEffect(() => {
    const key = `stationarycombustion_${company}_${month}_${reportingPeriod}`;
    setDialogData(separatedFacilitiesT[key] || []);
  }, [separatedFacilitiesT, company, month, reportingPeriod]);

  useEffect(() => {
    const key = `stationarycombustion_${company}_${month}_${reportingPeriod}`;
    const dataForKey = separatedFacilitiesT[key] || [];
    setDialogData(dataForKey);

    setFacilities(dataForKey.length > 0 ? dataForKey : [{
      id: '',
      data: {
        facilityDescription: '',
        facilityArea: '',
        fuels: [{ fuelCombusted: '', quantityCombusted: '', units: '', heatingvalue: '' }],
      }
    }]); // Reset to empty array if no data
  }, [separatedFacilitiesT]);


  const handleChange = (e, facilityIndex, fuelIndex = null) => {
    const { name, value } = e.target;
    const updatedFacilities = facilities.map((facility, index) => {
      if (index === facilityIndex) {
        if (name === 'id') {
          // Update the facility id
          return { ...facility, id: value };
      } else if (fuelIndex !== null) {
          const updatedFuels = facility.data.fuels.map((fuel, idx) => {
            if (idx === fuelIndex) {
              return { ...fuel, [name]: value };
            }
            return fuel;
          });
          return { ...facility, data: { ...facility.data, fuels: updatedFuels } };
        }
        return { ...facility, data: { ...facility.data, [name]: value } };
      }
      return facility;
    });
    setFacilities(updatedFacilities);
  };

  const addFuel = (facilityIndex) => {
    const updatedFacilities = facilities.map((facility, index) => {
      if (index === facilityIndex) {
        return {
          ...facility,
          data: { ...facility.data, fuels: [...facility.data.fuels, { fuelCombusted: '', quantityCombusted: '', units: '', heatingvalue:'' }] }
        };
      }
      return facility;
    });
    setFacilities(updatedFacilities);
  };

  const deleteFuel = (facilityIndex, fuelIndex) => {
    const updatedFacilities = facilities.map((facility, index) => {
      if (index === facilityIndex) {
        const updatedFuels = facility.data.fuels.filter((_, idx) => idx !== fuelIndex);
        return { ...facility, data: { ...facility.data, fuels: updatedFuels } };
      }
      return facility;
    });
    setFacilities(updatedFacilities);
  };

  
  const addFacility = () => {
    // Generate a new facility ID, ensure it's a string
    const newFacilityId = facilities.length > 0 
      ? (Math.max(...facilities.map(f => parseInt(f.id, 10))) + 1).toString() 
      : " ";
    
    setFacilities([...facilities, { id: newFacilityId, data: initialFormData }]);
    setCurrentTab(facilities.length); // Switch to the new facility tab
  };

  const deleteFacility = (facilityId) => {
    const updatedFacilities = facilities.filter(facility => facility.id !== facilityId);
    setFacilities(updatedFacilities);
    if (currentTab >= updatedFacilities.length) {
      setCurrentTab(updatedFacilities.length - 1);
    }
  };
// console.log("facilities sending back",facilities)
  const handleSubmit = async() => {
    onSubmit(facilities.map(facility => ({ ...facility.data, month, source })));
    const token = await getAccessTokenSilently();
    try {
      // const response = await axios.post('http://localhost:5000/api/stationarycombustion', {
        const response = await axios.post(`${apiUrl}/stationarycombustion`, {
        parentcompany: parentCompany.name,
        parentcompany_country: parentCompany.country,
        company: company,
        period: reportingPeriod,
        month: month, 
        facilities: facilities,
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error adding company:', error.response || error.message);
    }
    onClose();
  };
  const fuelOptionsByCountry = {
    India: [
      'Anthracite Coal', 'Aviation gasoline', 'Biodiesel', 'Biogasoline',
      'Biomass municipal and industrial material (RCPHE)', 'Bitumin',
      'Bituminous coal', 'Blast furnace gas', 'Charcoal', 'Coal briquettes',
      'Coal coke', 'Coal tar', 'Coke oven gas', 'Coking coal', 'Crude oil',
      'Diesel oil', 'Ethane', 'Gas coke', 'Gas works gas', 'Jet gasoline',
      'Jet kerosene', 'Landfill biogas that is captured for combustion (methane only)',
      'Lignite', 'Lignite coke', 'Liquefied petroleum gas', 'Lubricants',
      'Motor gasoline', 'Municipal solid waste', 'Municipal wastes (Biomass fraction)',
      'Naptha', 'Natural Gas', 'Oil shale and tar sands', 'Orimulsion',
      'Other biogas that is captured for combustion (methane only)', 'Other kerosene',
      'Other liquid biofuels', 'Other natural gas liquids', 'Other petroleum based products',
      'Other primary solid biomass fuels', 'Oxygen steel furnace gas', 'Paraffin waxes',
      'Patent fuel', 'Peat', 'Petroleum coke', 'Refinery feedstocks',
      'Refinery gas and liquids', 'Residual Fuel Oil No. 6', 'Shale oil',
      'Sludge biogas that is captured for combustion (methane only)', 'Solvents if mineral turpentine or white spirits',
      'Sub-bituminous coal', 'Sulphite lyes', 'Waste oils', 'Wood or Wood waste'
    ],
    USA : [
      'Anthracite Coal', 'Bituminous Coal', 'Sub-bituminous Coal', 'Lignite Coal', 'Mixed (Commercial Sector)',
      'Mixed (Electric Power Sector)', 'Mixed (Industrial Coking)', 'Mixed (Industrial Sector)', 'Coal Coke', 'Municipal Solid Waste',
      'Petroleum Coke', 'Plastics', 'Tires', 'Agricultural Byproducts', 'Peat', 'Solid Byproducts', 'Wood and Wood Residuals', 'Natural Gas',
      'Blast Furnace Gas', 'Coke Oven Gas',
      'Fuel Gas', 'Propane Gas', 'Landfill Gas', 'Other Biomass Gases', 'Asphalt and Road Oil', 'Aviation Gasoline', 'Butane', 'Butylene',
      'Crude Oil', 'Distillate Fuel Oil No. 1',
      'Distillate Fuel Oil No. 2', 'Distillate Fuel Oil No. 4', 'Ethane', 'Ethylene', 'Heavy Gas Oils', 'Isobutane', 'Isobutylene', 'Kerosene',
      'Kerosene-Type Jet Fuel', 'Liquefied Petroleum Gases (LPG)',
      'Lubricants', 'Motor Gasoline', 'Naphtha (<401 deg F)', 'Natural Gasoline', 'Other Oil (>401 deg F)', 'Pentanes Plus', 'Petrochemical Feedstocks',
      'Propane', 'Propylene', 'Residual Fuel Oil No. 5',
      'Residual Fuel Oil No. 6', 'Special Naphtha', 'Unfinished Oils', 'Used Oil', 'Biodiesel (100%)', 'Ethanol (100%)', 'Rendered Animal Fat', 'Vegetable Oil'
  ],
  Australia: [
      'Bituminous Coal', 'Sub-bituminous Coal', 'Anthracite', 'Brown Coal (Lignite)', 'Coking Coal',
      'Coal Briquettes', 'Coal Coke', 'Coal Tar', 'Other Solid Fossil Fuels', 'Recycled Industrial Materials (fossil-derived)',
      'Recycled Passenger Car Tyres', 'Recycled Truck and Off-road Tyres', 'Non-biomass Municipal Materials', 'Dry Wood',
      'Green and Air Dried Wood', 'Sulphite Lyes', 'Bagasse', 'Biomass (Municipal and Industrial)', 'Charcoal', 'Primary Solid Biomass Fuels',
      'Natural Gas', 'Coal Seam Methane', 'Coal Mine Waste Gas', 'Compressed Natural Gas', 'Unprocessed Natural Gas', 'Ethane',
      'Coke Oven Gas', 'Blast Furnace Gas', 'Town Gas', 'Liquefied Natural Gas',
      'Other Gaseous Fossil Fuels', 'Landfill Biogas (Methane only)', 'Sludge Biogas (Methane only)', 'Other Captured Biogas', 'Biomethane',
      'Petroleum based oils (other than petroleum based oil used as fuel, e.g., lubricants)', 
      'Petroleum based greases', 
      'Crude oil including crude oil condensates', 
      'Other natural gas liquids', 
      'Automotive gasoline/petrol (other than for use as fuel in an aircraft)', 
      'Aviation gasoline', 
      'Kerosene (other than for use as fuel in an aircraft)', 
      'Aviation turbine fuel/kerosene', 
      'Heating oil', 
      'Diesel oil', 
      'Fuel oil', 
      'Liquefied aromatic hydrocarbons', 
      'Solvents: mineral turpentine or white spirits', 
      'Liquefied petroleum gas (LPG)', 
      'Naphtha', 
      'Petroleum coke', 
      'Refinery gas and liquids', 
      'Refinery coke', 
      'Petroleum based products other than mentioned in the items above', 
      'Biodiesel', 
      'Ethanol for use as a fuel in an internal combustion engine', 
      'Biofuels other than those mentioned in the items above and below', 
      'Renewable aviation kerosene', 
      'Renewable diesel'
  ],

  NewZealand:[
      "general_Coal - Default", "general_Coal - Bituminous","general_Coal - Sub-Bituminous",
      "general_Coal - Lignite",
      "commercial_Coal - Default","commercial_Coal - Bituminous",
      "commercial_Coal - Sub-Bituminous","commercial_Coal - Lignite",
      "commercial_Diesel","commercial_LPG", "commercial_Heavy Fuel Oil","commercial_Light Fuel Oil","commercial_Natural Gas",
      "industrial_Coal - Default","industrial_Coal - Bituminous","industrial_Coal - Sub-Bituminous","industrial_Coal - Lignite",
      "industrial_Diesel","industrial_LPG","industrial_Heavy Fuel Oil","industrial_Light Fuel Oil", "industrial_Natural Gas"
  ]
    // Add more countries and their respective fuel options here
  };

  const fuelTypesAndStates = {
    "Agricultural byproducts": "Solid",
    "Anthracite Coal": "Solid",
    "Asphalt and road oil": "Liquid",
    "Bagasse": "Solid",
    "Bamboo": "Solid",
    "Biodiesel": "Liquid",
    "Biogasoline":"Gas",
    "Biomass municipal and industrial material (RCPHE)": "Solid",
    "Biomethane": "Gas",
    "Bitumin": "Solid",
    "Bituminous coal": "Solid",
    "Blast furnace gas": "Gas",
    "Brown coal": "Solid",
    "Butane": "Liquid",
    "Butylene": "Liquid",
    "Charcoal": "Solid",
    "Coking coal": "Solid",
    "Coal briquettes": "Solid",
    "Coal coke": "Solid",
    "Coal mine waste gas that is captured for combustion": "Gas",
    "Coal seam methane that is captured for combustion": "Gas",
    "Coal tar": "Solid",
    "Coke oven gas": "Gas",
    "Compressed natural gas that has reverted to standard conditions": "Gas",
    "Crude oil": "Liquid",
    "Diesel oil": "Liquid",
    "Distillate Fuel Oil No. 1": "Liquid",
    "Distillate Fuel Oil No. 2": "Liquid",
    "Distillate Fuel Oil No. 4": "Liquid",
    "Dry wood": "Solid",
    "Ethane": "Gas",
    "Ethanol": "Liquid",
    "Ethanol for use as a fuel in an internal combustion engine": "Liquid",
    "Ethylene": "Liquid",
    "Fuel gas": "Gas",
    "Fuel oil": "Liquid",
    "Gasoline (other than for use as fuel in an aircraft)": "Liquid",
    "Gasoline for use as fuel in an aircraft": "Liquid",
    "Green and air dried wood": "Solid",
    "Heating oil": "Liquid",
    "Heavy gas oils": "Liquid",
    "Industrial materials that are derived from fossil fuels (RCPHE)": "Solid",
    "Isobutane": "Liquid",
    "Isobutylene": "Liquid",
    "Kerosene (other than for use as fuel in an aircraft)": "Liquid",
    "Kerosene for use as fuel in an aircraft": "Liquid",
    "Landfill biogas that is captured for combustion (methane only)": "Gas",
    "Lignite": "Solid",
    "Liquefied aromatic hydrocarbons": "Liquid",
    "Liquefied natural gas": "Gas",
    "Liquefied petroleum gas": "Liquid",
    "Lubricants": "Liquid",
    "Middling coal": "Solid",
    "Mixed coal (Commercial sector)": "Solid",
    "Mixed coal (Electric power sector)": "Solid",
    "Mixed coal (Industrial coking sector)": "Solid",
    "Mixed coal (Industrial power sector)": "Solid",
    "Motor gasoline": "Liquid",
    "Municipal Solid waste": "Solid",
    "Naptha": "Liquid",
    "Natural Gas": "Gas",
    "Natural gas distributed in a pipeline": "Gas",
    "Natural gasoline": "Liquid",
    "Non-biomass municipal materials (RCPHE)": "Solid",
    "North American Hardwood": "Solid",
    "North American Softwood": "Solid",
    "Other biofuels": "Liquid",
    "Other biogas that is captured for combustion (methane only)": "Gas",
    "Other gaseous fossil fuels": "Gas",
    "Other natural gas liquids": "Liquid",
    "Other oils (>401 F)": "Liquid",
    "Other petroleum based products": "Liquid",
    "Other primary solid biomass fuels": "Solid",
    "Other solid fossil fuels": "Solid",
    "Passenger car tyres (RCPHE)": "Solid",
    "Peat": "Solid",
    "Pentanes Plus": "Liquid",
    "Petrochemical Feedstocks": "Liquid",
    "Petrol": "Liquid",
    "Petroleum based greases": "Liquid",
    "Petroleum based oils (other than petroleum based oil used as fuel)": "Liquid",
    "Petroleum coke": "Liquid",
    "Petroleum coke (solid)": "Solid",
    "Plastics": "Solid",
    "Propane": "Liquid",
    "Propane gas": "Gas",
    "Propylene": "Liquid",
    "Refinery coke": "Liquid",
    "Refinery gas and liquids": "Liquid",
    "Rendered animal fat": "Liquid",
    "Renewable aviation kerosene": "Liquid",
    "Renewable diesel": "Liquid",
    "Residual Fuel Oil No. 5": "Liquid",
    "Residual Fuel Oil No. 6": "Liquid",
    "Slime": "Liquid",
    "Sludge biogas that is captured for combustion (methane only)": "Gas",
    "Solid byproducts": "Solid",
    "Solvents if mineral turpentine or white spirits": "Liquid",
    "Special naptha": "Liquid",
    "Straw": "Solid",
    "Sub-bituminous coal": "Solid",
    "Sulphite lyes": "Solid",
    "Tires": "Solid",
    "Town gas": "Gas",
    "Truck and off-road tyres (RCPHE)": "Solid",
    "Unfinished oils": "Liquid",
    "Unprocessed natural gas": "Gas",
    "Used oils": "Liquid",
    "Vegetable oil": "Liquid",
    "Washed coal": "Solid",
    "Wood and wood residuals": "Solid",
    //Australia
    "Bituminous Coal": "Solid",
    "Sub-bituminous Coal": "Solid",
    "Anthracite": "Solid",
    "Brown Coal (Lignite)": "Solid",
    "Coking Coal": "Solid",
    "Coal Briquettes": "Solid",
    "Coal Coke": "Solid",
    "Coal Tar": "Solid",
    "Other Solid Fossil Fuels": "Solid",
    "Recycled Industrial Materials (fossil-derived)": "Solid",
    "Recycled Passenger Car Tyres": "Solid",
    "Recycled Truck and Off-road Tyres": "Solid",
    "Non-biomass Municipal Materials": "Solid",
    "Dry Wood": "Solid",
    "Green and Air Dried Wood": "Solid",
    "Sulphite Lyes": "Solid",
    "Bagasse": "Solid",
    "Biomass (Municipal and Industrial)": "Solid",
    "Charcoal": "Solid",
    "Primary Solid Biomass Fuels": "Solid",
    "Natural Gas": "Gas",
    "Coal Seam Methane": "Gas",
    "Coal Mine Waste Gas": "Gas",
    "Compressed Natural Gas": "Gas",
    "Unprocessed Natural Gas": "Gas",
    "Ethane": "Gas",
    "Coke Oven Gas": "Gas",
    "Blast Furnace Gas": "Gas",
    "Town Gas": "Gas",
    "Liquefied Natural Gas": "Gas",
    "Other Gaseous Fossil Fuels": "Gas",
    "Landfill Biogas (Methane only)": "Gas",
    "Sludge Biogas (Methane only)": "Gas",
    "Other Captured Biogas": "Gas",
    "Biomethane": "Gas",

    //NewZealand
    "general_Coal - Default": "Solid",
    "general_Coal - Bituminous": "Solid",
    "general_Coal - Sub-Bituminous": "Solid",
    "general_Coal - Lignite": "Solid",
    "commercial_Coal - Default": "Solid",
    "commercial_Coal - Bituminous": "Solid",
    "commercial_Coal - Sub-Bituminous": "Solid",
    "commercial_Coal - Lignite": "Solid",
    "industrial_Coal - Default": "Solid",
    "industrial_Coal - Bituminous": "Solid",
    "industrial_Coal - Sub-Bituminous": "Solid",
    "industrial_Coal - Lignite": "Solid",
    "commercial_Diesel": "Liquid",
    "commercial_LPG": "Liquid",
    "commercial_Heavy Fuel Oil": "Liquid",
    "commercial_Light Fuel Oil": "Liquid",
    "industrial_Diesel": "Liquid",
    "industrial_LPG": "Liquid",
    "industrial_Heavy Fuel Oil": "Liquid",
    "industrial_Light Fuel Oil": "Liquid",
    "commercial_Natural Gas": "Gas",
    "industrial_Natural Gas": "Gas"
  };
  
  const unitsByState = {
    Solid: ['metric ton','kg','pounds','TJ','GJ','MJ','KWh','mmBTU', 'Therm'],
    Liquid: ['liters (L)','barrel(bbl)','gallon','TJ','GJ','MJ','KWh','mmBTU', 'Therm'],
    Gas: ['cubic meters (m3)','foot3','TJ','GJ','MJ','KWh','mmBTU', 'Therm'],
  };
  
  const country = parentCompany.country;
  const fuelOptions = fuelOptionsByCountry[country] || [];

  const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleUnitChange = (e, facilityIndex, fuelIndex) => {
      const { value } = e.target;
      const updatedFacilities = facilities.map((facility, index) => {
          if (index === facilityIndex) {
              const updatedFuels = facility.data.fuels.map((fuel, idx) => {
                  if (idx === fuelIndex) {
                      return { ...fuel, units: value };
                  }
                  return fuel;
              });
              return { ...facility, data: { ...facility.data, fuels: updatedFuels } };
          }
          return facility;
      });
      setFacilities(updatedFacilities);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: '40vw',
          padding: 2,
          height: '100%',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={onClose}
        >
          <ArrowDownward />
        </IconButton>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="facility tabs">
          {facilities.map((facility, index) => (
            <Tab key={facility.id} label={`Facility ${facility.id}`} />
          ))}
          <Tab label="Add Facility" onClick={addFacility} />
          <Tab label="Submit Data" />
        </Tabs>
        {currentTab < facilities.length ? (
          <Box p={2}>
            <Typography>
              Stationary combustion refers to the burning of fossil fuels or 
              biomass in fixed equipment like boilers, 
              furnaces, and generators, used for power generation, 
              heating, or industrial processes.
              </Typography>
             <Box display="flex" justifyContent="space-between" alignItems="center">
             
            <Typography variant="h6" gutterBottom>
            
              Enter Data for Facility {facilities[currentTab].id}
              <DialogTitle>Enter Data for {source}</DialogTitle>
         <Typography>Company: {company}</Typography>
         <Typography>Reporting Period: {reportingPeriod}</Typography>
         <Typography>Month and Year: {month}</Typography>
         {/* <Typography>Year: {year}</Typography> */}
            </Typography>
            <img src={statcombution_logo} alt="Logo" style={{ height: '170px', marginLeft: '6px'}} />
             </Box>
            <Typography variant="subtitle1" gutterBottom>
              {month} {year}
            </Typography>
            <TextField
              label="Facility ID"
              name="id"
              value={facilities[currentTab].id}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Facility Description"
              name="facilityDescription"
              value={facilities[currentTab].data.facilityDescription}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Facility Area"
              name="facilityArea"
              value={facilities[currentTab].data.facilityArea}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <Typography variant="h6" gutterBottom>
              Fuel Details
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fuel Combusted</TableCell>
                  <TableCell>Quantity Combusted</TableCell>
                  <TableCell>Heating Value</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {facilities[currentTab] && facilities[currentTab].data.fuels.map((fuel, fuelIndex) => (
                  <TableRow key={fuelIndex}>
                    <TableCell>
                      <FormControl fullWidth>
                      <InputLabel htmlFor="device-dropdown">Fuel Combusted</InputLabel>
                      <Select
                          name="fuelCombusted"
                          value={fuel.fuelCombusted}
                          onChange={(e) => handleChange(e, currentTab, fuelIndex)}
                          MenuProps={{ PaperProps: { style: { maxHeight: 300, width: 250 } } }}
                          style={{
                              width: '100%',
                              marginTop: '10px',
                              fontSize: '16px',
                              borderRadius: '4px', // Add border radius for a smoother look
                              borderColor: '#ced4da', // Add border color
                              backgroundColor: '#fff', // Set background color
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
                          }}
                          inputProps={{
                              style: { fontSize: '16px' }, // Set font size for consistency
                          }}
                      >
                          {fuelOptions.map((fuel) => (
                              <MenuItem key={fuel} style={{  marginRight: '150px', fontSize: '16px' }} value={fuel}>
                                  {fuel}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
                    </TableCell>
                    <TableCell>
                      {fuel.fuelCombusted &&(
                        
                    <TextField
                        name="quantityCombusted"
                        value={fuel.quantityCombusted}
                        onChange={(e) => handleChange(e, currentTab, fuelIndex)}
                        fullWidth
                        // style={{  height:'200px' }}
                        InputProps={{
                            endAdornment: (
                                // <InputAdornment position="end">
                                //     {unitsByState[fuelTypesAndStates[fuel.fuelCombusted]]}
                                // </InputAdornment>
                                
                                <InputAdornment position="end">
                              <Select
                              value={fuel.units}
                              onChange={(e) => handleUnitChange(e, currentTab, fuelIndex)} // Pass facilityIndex and fuelIndex
                              style={{
                                width: '150px',
                                // marginTop: '10px',
                                fontSize: '14px',
                                borderRadius: '4px', // Add border radius for a smoother look
                                // borderColor: '#ced4da', // Add border color
                                // backgroundColor: '#fff', // Set background color
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
                            }} // Adjust width as needed
                            MenuProps={{
                              style: { width: '120px' }, // Adjust the width of the dropdown menu as needed
                          }}
                          >
                              {(Array.isArray(unitsByState[fuelTypesAndStates[fuel.fuelCombusted]]) ? 
                                  unitsByState[fuelTypesAndStates[fuel.fuelCombusted]] : [unitsByState[fuelTypesAndStates[fuel.fuelCombusted]]]
                              ).map((unit) => (
                                  <MenuItem key={unit} style={{  marginRight: '150px', fontSize: '16px' }} value={unit}>
                                      {unit}
                                  </MenuItem>
                              ))}
                              {/* <MenuItem value="TJ">TJ</MenuItem> */}
                          </Select>

                            </InputAdornment>

                            ),
                        }}
                    />
                  )}
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                      <InputLabel htmlFor="device-dropdown">Fuel Combusted</InputLabel>
                      <Select
                          name="heatingvalue"
                          value={fuel.heatingvalue}
                          onChange={(e) => handleChange(e, currentTab, fuelIndex)}
                          MenuProps={{ PaperProps: { style: { maxHeight: 300, width: 250 } } }}
                          style={{
                              width: '100%',
                              marginTop: '10px',
                              fontSize: '16px',
                              borderRadius: '4px', // Add border radius for a smoother look
                              borderColor: '#ced4da', // Add border color
                              backgroundColor: '#fff', // Set background color
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
                          }}
                          inputProps={{
                              style: { fontSize: '16px' }, // Set font size for consistency
                          }}
                      >
                           <MenuItem style={{  marginRight: '150px', fontSize: '16px' }} value="Lower">Lower</MenuItem>
                           <MenuItem style={{  marginRight: '150px', fontSize: '16px' }} value="Higher">Higher</MenuItem>
                      </Select>
                  </FormControl>

                    </TableCell>
                    {/* <TableCell>
                      <TextField
                        name="units"
                        value={fuel.units}
                        onChange={(e) => handleChange(e, currentTab, fuelIndex)}
                        fullWidth
                      />
                    </TableCell> */}
                    <TableCell>
                      <IconButton onClick={() => deleteFuel(currentTab, fuelIndex)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button variant="contained" color="primary" onClick={() => addFuel(currentTab)}>
              Add Fuel
            </Button>
          </Box>
        ) : (
          currentTab > facilities.length && (
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Summary
              </Typography>
              {facilities.map((facility) => (
                <Box key={facility.id} mb={2} display="flex" alignItems="center">
                  <Box flex={1}>
                    <Typography variant="subtitle1">
                      Facility {facility.id}:
                    </Typography>
                    <Typography>Parent Company: {parentCompany.name}</Typography>
                    <Typography>Facility Description: {facility.data.facilityDescription}</Typography>
                    <Typography>Facility Area: {facility.data.facilityArea}</Typography>
                    <Typography>Fuels:</Typography>

                    <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Fuel Combusted</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Quantity Combusted</TableCell>
                                <TableCell>Units</TableCell>
                                <TableCell>Heating Value</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {facility.data.fuels.map((fuel, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>{fuel.fuelCombusted}</TableCell>
                                  <TableCell>{fuelTypesAndStates[fuel.fuelCombusted]}</TableCell>
                                  <TableCell>{fuel.quantityCombusted}</TableCell>
                                  <TableCell>{fuel.units}</TableCell>
                                  <TableCell>{fuel.heatingvalue}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                  </Box>
                  {facilities.length > 1 && (
                  <IconButton onClick={() => deleteFacility(facility.id)}>
                    <Delete />
                  </IconButton>)}
                </Box>
              ))}
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Submit All Data
                </Button>
                <Button variant="contained" color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          )
        )}
      </Box>
    </Drawer>
  );
};

export default StationaryCombustionDialog;

