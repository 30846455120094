import React, { useState, useEffect, useContext } from 'react';
import {
  TableContainer, Paper,Drawer, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Tabs, Tab, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { ArrowDownward, Delete } from '@mui/icons-material';
import { SourceContext } from '../contexts/Sourcecontext';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { convertLegacyOperators } from '@mui/x-data-grid/internals';
// import FugitiveDialog from './RefrigerationDialog';
import fugitive_logo from './Logos/fugitiveEmission.png';
const apiUrl = process.env.REACT_APP_API_URL_BACKEND


const FugitiveDialog = ({ open, onClose, onSubmit, source, month, year, company, reportingPeriod }) => {
  const initialFormData = {
    id: '',
    fugitivefacilityDescription: '',
    fugitivefacilityArea: '',
    fugitivegases: [{ fugitive_gas: '', initialGas: '',finalgas: '', gaspurchased:'', gassold:'', capacitychange:'' }],
  };

  const { parentCompany, fugitivefacilities = [], setfugitivefacilities } = useContext(SourceContext);
  const { superStatcombusstate, separatedFugitiveemissionsT, fetchAndProcessData } = useContext(SourceContext);
  const [currentTab, setCurrentTab] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const [dialogData, setDialogData] = useState(null);
  // const [separatedFacilitiesT, setSeparatedFacilitiesT] = useState({});

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  

  useEffect(() => {
    if (open) {
      fetchAndProcessData();
    }
  }, []);

  useEffect(() => {
    const key = `fugitiveemissions_${company}_${month}_${reportingPeriod}`;
    setDialogData(separatedFugitiveemissionsT[key] || []);
  }, [separatedFugitiveemissionsT, company, month, reportingPeriod]);

  useEffect(() => {
    const key = `fugitiveemissions_${company}_${month}_${reportingPeriod}`;
    const dataForKey = separatedFugitiveemissionsT[key] || [];
    setDialogData(dataForKey);

    setfugitivefacilities(dataForKey.length > 0 ? dataForKey : [{
      id: '',
      data: {
        fugitivefacilityDescription: '',
        fugitivefacilityArea: '',
        fugitivegases: [{ fugitive_gas: '',initialGas: '',finalgas: '', gaspurchased:'', gassold:'', capacitychange:'' }],
      }
    }]); // Reset to empty array if no data
  }, [separatedFugitiveemissionsT]);

  const handleChange = (e, fugitivefacilityIndex, fugitivegasIndex = null) => {
    const { name, value } = e.target;
    const updatedfugitiveFacilities = fugitivefacilities.map((fugitivefacility, index) => {
      if (index === fugitivefacilityIndex) {
        if (name === 'id') {
          // Update the facility id
          return { ...fugitivefacility, id: value };
      } else if (fugitivegasIndex !== null) {
          const updatedFugitivegases = fugitivefacility.data.fugitivegases.map((fugitivegas, idx) => {
            if (idx === fugitivegasIndex) {
              return { ...fugitivegas, [name]: value };
            }
            return fugitivegas;
          });
          return { ...fugitivefacility, data: { ...fugitivefacility.data, fugitivegases: updatedFugitivegases } };
        }
        return { ...fugitivefacility, data: { ...fugitivefacility.data, [name]: value } };
      }
      return fugitivefacility;
    });
    setfugitivefacilities(updatedfugitiveFacilities);
  };

  const addFugitivegas = (fugitivefacilityIndex) => {
    const updatedfugitiveFacilities = fugitivefacilities.map((fugitivefacility, index) => {
      if (index === fugitivefacilityIndex) {
        return {
          ...fugitivefacility,
          data: { ...fugitivefacility.data, fugitivegases: [...fugitivefacility.data.fugitivegases, { fugitive_gas: '',initialGas: '',finalgas: '', gaspurchased:'', gassold:'', capacitychange:'' }] }
        };
      }
      return fugitivefacility;
    });
    setfugitivefacilities(updatedfugitiveFacilities);
  };

  const deleteFugitivegas = (fugitivefacilityIndex, fugitivegasIndex) => {
    const updatedfugitivefacilities = fugitivefacilities.map((fugitivefacility, index) => {
      if (index === fugitivefacilityIndex) {
        const updatedFugitivegases = fugitivefacility.data.fugitivegases.filter((_, idx) => idx !== fugitivegasIndex);
        return { ...fugitivefacility, data: { ...fugitivefacility.data, fugitivegases: updatedFugitivegases } };
      }
      return fugitivefacility;
    });
    setfugitivefacilities(updatedfugitivefacilities);
  };

  const addFacility = () => {
    // Generate a new facility ID, ensure it's a string
    const newFacilityId = fugitivefacilities.length > 0 
      ? (Math.max(...fugitivefacilities.map(f => parseInt(f.id, 10))) + 1).toString() 
      : " ";
    
      setfugitivefacilities([...fugitivefacilities, { id: newFacilityId, data: initialFormData }]);
    setCurrentTab(fugitivefacilities.length); // Switch to the new facility tab
  };

  const deleteFacility = (facilityId) => {
    const updatedFacilities = fugitivefacilities.filter(facility => facility.id !== facilityId);
    setfugitivefacilities(updatedFacilities);
    if (currentTab >= updatedFacilities.length) {
      setCurrentTab(updatedFacilities.length - 1);
    }
  };
// `${apiUrl}/sourceselection`

console.log("facilities sending back",fugitivefacilities)
  const handleSubmit = async() => {
    onSubmit(fugitivefacilities.map(facility => ({ ...facility.data, month, source })));
    const token = await getAccessTokenSilently();
    try {
      // const response = await axios.post('http://localhost:5000/api/fugitiveemissions', {
        const response = await axios.post(`${apiUrl}/fugitiveemissions`, {
        parentcompany: parentCompany.name,
        parentcompany_country: parentCompany.country,
        company: company,
        period: reportingPeriod,
        month: month, 
        fugitivefacilities: fugitivefacilities,
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log("Fugitive data sending back",response)
    } catch (error) {
      console.error('Error adding company:', error.response || error.message);
    }
    onClose();
  };
  const fugitivegasOptionsByCountry = {
    India: [
      'Anthracite Coal', 'Aviation gasoline', 'Biodiesel', 'Biogasoline',
      'Biomass municipal and industrial material (RCPHE)', 'Bitumin',
      'Bituminous coal', 'Blast furnace gas', 'Charcoal', 'Coal briquettes',
      'Coal coke', 'Coal tar', 'Coke oven gas', 'Coking coal', 'Crude oil',
      'Diesel oil', 'Ethane', 'Gas coke', 'Gas works gas', 'Jet gasoline',
      'Jet kerosene', 'Landfill biogas that is captured for combustion (methane only)',
      'Lignite', 'Lignite coke', 'Liquefied petroleum gas', 'Lubricants',
      'Motor gasoline', 'Municipal solid waste', 'Municipal wastes (Biomass fraction)',
      'Naptha', 'Natural Gas', 'Oil shale and tar sands', 'Orimulsion',
      'Other biogas that is captured for combustion (methane only)', 'Other kerosene',
      'Other liquid biofuels', 'Other natural gas liquids', 'Other petroleum based products',
      'Other primary solid biomass fuels', 'Oxygen steel furnace gas', 'Paraffin waxes',
      'Patent fuel', 'Peat', 'Petroleum coke', 'Refinery feedstocks',
      'Refinery gas and liquids', 'Residual Fuel Oil No. 6', 'Shale oil',
      'Sludge biogas that is captured for combustion (methane only)', 'Solvents if mineral turpentine or white spirits',
      'Sub-bituminous coal', 'Sulphite lyes', 'Waste oils', 'Wood or Wood waste'
    ],
    USA : [
      'Anthracite Coal', 'Bituminous Coal', 'Sub-bituminous Coal', 'Lignite Coal', 'Mixed (Commercial Sector)',
      'Mixed (Electric Power Sector)', 'Mixed (Industrial Coking)', 'Mixed (Industrial Sector)', 'Coal Coke', 'Municipal Solid Waste',
      'Petroleum Coke', 'Plastics', 'Tires', 'Agricultural Byproducts', 'Peat', 'Solid Byproducts', 'Wood and Wood Residuals', 'Natural Gas',
      'Blast Furnace Gas', 'Coke Oven Gas',
      'Fuel Gas', 'Propane Gas', 'Landfill Gas', 'Other Biomass Gases', 'Asphalt and Road Oil', 'Aviation Gasoline', 'Butane', 'Butylene',
      'Crude Oil', 'Distillate Fuel Oil No. 1',
      'Distillate Fuel Oil No. 2', 'Distillate Fuel Oil No. 4', 'Ethane', 'Ethylene', 'Heavy Gas Oils', 'Isobutane', 'Isobutylene', 'Kerosene',
      'Kerosene-Type Jet Fuel', 'Liquefied Petroleum Gases (LPG)',
      'Lubricants', 'Motor Gasoline', 'Naphtha (<401 deg F)', 'Natural Gasoline', 'Other Oil (>401 deg F)', 'Pentanes Plus', 'Petrochemical Feedstocks',
      'Propane', 'Propylene', 'Residual Fuel Oil No. 5',
      'Residual Fuel Oil No. 6', 'Special Naphtha', 'Unfinished Oils', 'Used Oil', 'Biodiesel (100%)', 'Ethanol (100%)', 'Rendered Animal Fat', 'Vegetable Oil'
  ],
  Australia: [
    'Sulphur hexafluoride (SF6)', 'HFC-23 (R-23)', 'HFC-32 (R-32)', 'HFC-41 (R-41)', 'HFC-43-10mee (R-4310mee)', 
    'HFC-125 (R-125)', 'HFC-134 (R-134)', 'HFC-134a (R-134a)', 'HFC-143 (R-143)', 'HFC-143a (R-143a)',
    'HFC-152a (R-152a)', 'HFC-227ea (R-227ea)', 'HFC-236fa (R-236fa)', 'HFC-245ca (R-245ca)', 'HFC-245fa (R-245fa)', 
    'HFC-365mfc (R-365mfc)', 'HCFC-22 (R-22)', 'HCFC-123 (R-123)', 'HCFC-124 (R-124)', 'HCFC-141b (R-141b)',
    'HCFC-142b (R-142b)', 'HCFC-225ca (R-225ca)', 'HCFC-225cb (R-225cb)', 'PFC-14 Perfluoromethane (CF4)', 
    'PFC-116 Perfluoroethane (C2F6)', 'PFC-218 Perfluoropropane (C3F8)', 'PFC-31-10 Perfluorobutane (C4F10)', 
    'PFC-318 Perfluorocyclobutane (c-C4F8)', 'PFC-41-12 Perfluoropentane (C5F12)', 'PFC-51-14 Perfluorohexane (C6F14)',
    'PFC-91-18 Perflunafene (C10F18)', 'R-400', 'R-401A', 'R-401B', 'R-401C', 'R-402A', 
    'R-402B', 'R-403A', 'R-403B', 'R-404A', 'R-405A',
    'R-406A', 'R-407A', 'R-407B', 'R-407C', 'R-407D', 
    'R-407E', 'R-408A', 'R-409A', 'R-409B', 'R-410A', 
    'R-410B', 'R-411A', 'R-411B', 'R-411C', 'R-412A', 
    'R-413A', 'R-414A', 'R-414B', 'R-415A', 'R-415B', 
    'R-416A', 'R-417A', 'R-418A', 'R-419A', 'R-420A', 
    'R-421A', 'R-422A', 'R-422B', 'R-422C', 'R-500', 
    'R-501', 'R-502', 'R-503', 'R-504', 'R-505', 
    'R-506', 'R-507A', 'R-508A', 'R-508B', 'R-509A'
],

  NewZealand:[
      "general_Coal - Default", "general_Coal - Bituminous","general_Coal - Sub-Bituminous",
      "general_Coal - Lignite",
      "commercial_Coal - Default","commercial_Coal - Bituminous",
      "commercial_Coal - Sub-Bituminous","commercial_Coal - Lignite",
      "commercial_Diesel","commercial_LPG", "commercial_Heavy Fuel Oil","commercial_Light Fuel Oil","commercial_Natural Gas",
      "industrial_Coal - Default","industrial_Coal - Bituminous","industrial_Coal - Sub-Bituminous","industrial_Coal - Lignite",
      "industrial_Diesel","industrial_LPG","industrial_Heavy Fuel Oil","industrial_Light Fuel Oil", "industrial_Natural Gas"
  ]
    // Add more countries and their respective fuel options here
  };

  const country = parentCompany.country;
  const fugitivegasOptions = fugitivegasOptionsByCountry[country] || [];

  const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleUnitChange = (e, fugitivefacilityIndex, fugitivegasIndex) => {
      const { value } = e.target;
      const updatedfugitiveFacilities = fugitivefacilities.map((fugitivefacility, index) => {
          if (index === fugitivefacilityIndex) {
              const updatedFugitivegases = fugitivefacility.data.fugitivegases.map((fugitivegas, idx) => {
                  if (idx === fugitivegasIndex) {
                      return { ...fugitivegas, units: value };
                  }
                  return fugitivegas;
              });
              return { ...fugitivefacility, data: { ...fugitivefacility.data, fugitivegases: updatedFugitivegases } };
          }
          return fugitivefacility;
      });
      setfugitivefacilities(updatedfugitiveFacilities);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: '40vw',
          padding: 2,
          height: '100%',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={onClose}
        >
          <ArrowDownward />
        </IconButton>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="facility tabs">
          {fugitivefacilities.map((fugitivefacility, index) => (
            <Tab key={fugitivefacility.id} label={`Facility ${fugitivefacility.id}`} />
          ))}
          <Tab label="Add fugitiveFacility" onClick={addFacility} />
          <Tab label="Submit Data" />
        </Tabs>
        {currentTab < fugitivefacilities.length ? (
          <Box p={2}>
            <Typography>
            Fugitive emissions are unintended leaks of gases or vapors from equipment,
             pipelines, or storage facilities, often occurring in industrial processes.
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom>
              Enter Data for Facility {fugitivefacilities[currentTab].id}
              <DialogTitle>Enter Data for {source}</DialogTitle>
         <Typography>Company: {company}</Typography>
         <Typography>Reporting Period: {reportingPeriod}</Typography>
         <Typography>Month and Year: {month}</Typography>
         {/* <Typography>Year: {year}</Typography> */}
            </Typography>
            <img src={fugitive_logo} alt="Logo" style={{ height: '170px', marginLeft: '6px'}} />
            </Box>
            <Typography variant="subtitle1" gutterBottom>
              {month} {year}
            </Typography>
            <TextField
              label="Facility ID"
              name="id"
              value={fugitivefacilities[currentTab].id}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Facility Description"
              name="fugitivefacilityDescription"
              value={fugitivefacilities[currentTab].data.fugitivefacilityDescription}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Facility Area"
              name="fugitivefacilityArea"
              value={fugitivefacilities[currentTab].data.fugitivefacilityArea}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <Typography variant="h6" gutterBottom>
              Fugitive Gas Details
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fugitive Gas</TableCell>
                  <TableCell>Initial Gas</TableCell>
                  <TableCell>Final Gas</TableCell>
                  <TableCell>Gas Purchased</TableCell>
                  <TableCell>Gas Sold</TableCell>
                  <TableCell>Capacity Change</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fugitivefacilities[currentTab] && fugitivefacilities[currentTab].data.fugitivegases.map((fugitivegas, fugitivegasIndex) => (
                  <TableRow key={fugitivegasIndex}>
                    <TableCell>
                      <FormControl fullWidth>
                      <InputLabel htmlFor="device-dropdown">Fugitive Gas</InputLabel>
                      <Select
                          name="fugitive_gas"
                          value={fugitivegas.fugitive_gas}
                          onChange={(e) => handleChange(e, currentTab, fugitivegasIndex)}
                          MenuProps={{ PaperProps: { style: { maxHeight: 300, width: 250 } } }}
                          style={{
                              width: '100%',
                              marginTop: '10px',    
                              fontSize: '16px',
                              borderRadius: '4px', // Add border radius for a smoother look
                              borderColor: '#ced4da', // Add border color
                              backgroundColor: '#fff', // Set background color
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
                          }}
                          inputProps={{
                              style: { fontSize: '16px' }, // Set font size for consistency
                          }}
                      >
                          {fugitivegasOptions.map((fugitivegas) => (
                              <MenuItem key={fugitivegas} style={{  marginRight: '150px', fontSize: '16px' }} value={fugitivegas}>
                                  {fugitivegas}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="initialgas"
                        value={fugitivegas.initialGas}
                        onChange={(e) => handleChange(e, currentTab, fugitivegasIndex)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="finalgas"
                        value={fugitivegas.finalgas}
                        onChange={(e) => handleChange(e, currentTab, fugitivegasIndex)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="gaspurchased"
                        value={fugitivegas.gaspurchased}
                        onChange={(e) => handleChange(e, currentTab, fugitivegasIndex)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="gassold"
                        value={fugitivegas.gassold}
                        onChange={(e) => handleChange(e, currentTab, fugitivegasIndex)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="capacitychange"
                        value={fugitivegas.capacitychange}
                        onChange={(e) => handleChange(e, currentTab, fugitivegasIndex)}
                        fullWidth
                      />
                    </TableCell>
                    
                    <TableCell>
                      <IconButton onClick={() => deleteFugitivegas(currentTab, fugitivegasIndex)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button variant="contained" color="primary" onClick={() => addFugitivegas(currentTab)}>
              Add Fugitive Gas
            </Button>
          </Box>
        ) : (
          currentTab > fugitivefacilities.length && (
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Summary
              </Typography>
              {fugitivefacilities.map((fugitivefacility) => (
                <Box key={fugitivefacility.id} mb={2} display="flex" alignItems="center">
                  <Box flex={1}>
                    <Typography variant="subtitle1">
                      Fugitive Facility {fugitivefacility.id}:
                    </Typography>
                    <Typography>Parent Company: {parentCompany.name}</Typography>
                    <Typography>Facility Description: {fugitivefacility.data.fugitivefacilityDescription}</Typography>
                    <Typography>Facility Area: {fugitivefacility.data.fugitivefacilityArea}</Typography>
                    <Typography>Fugitivegases:</Typography>
                    <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Fugitive Gas</TableCell>
                                {/* <TableCell>Vehicle Sub-category</TableCell> */}
                                <TableCell>Initial Gas</TableCell>
                                <TableCell>Final Gas</TableCell>
                                <TableCell>Gas Purchased</TableCell>
                                <TableCell>Gas Sold</TableCell>
                                <TableCell>Capacity Change</TableCell>
                                {/* <TableCell>Distance Travelled</TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {fugitivefacility.data.fugitivegases.map((fugitivegas, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>{fugitivegas.fugitive_gas}</TableCell>
                                  {/* <TableCell>{vehicle.vehicleSubcategory}</TableCell> */}
                                  <TableCell>{fugitivegas.initialGas}</TableCell>
                                  <TableCell>{fugitivegas.finalgas}</TableCell>
                                  <TableCell>{fugitivegas.gaspurchased}</TableCell>
                                  <TableCell>{fugitivegas.gassold}</TableCell>
                                  <TableCell>{fugitivegas.capacitychange}</TableCell>
                                  {/* <TableCell>{vehicle.distanceTravelled}</TableCell> */}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                    {/* {fugitivefacility.data.fugitivegases.map((fugitivegas, idx) => (
                      <Box key={idx} ml={2}>
                        <Typography>Fuel Combusted: {fuel.fuelCombusted}</Typography>
                        <Typography>State: {fuelTypesAndStates[fuel.fuelCombusted]}</Typography>
                        <Typography>Quantity Combusted: {fuel.quantityCombusted}</Typography>
                        <Typography>Units: {fuel.units}</Typography>
                        <Typography>Heating Value: {fuel.heatingvalue}</Typography>
                      </Box>
                    ))} */}
                  </Box>
                  {fugitivefacilities.length > 1 && (
                  <IconButton onClick={() => deleteFacility(fugitivefacility.id)}>
                    <Delete />
                  </IconButton>)}
                </Box>
              ))}
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Submit All Data
                </Button>
                <Button variant="contained" color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          )
        )}
      </Box>
    </Drawer>
  );
};

export default FugitiveDialog;

