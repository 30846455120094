// import React, { useState, useRef, useEffect } from "react";
// import "./SupplierForm.css";

// const SupplierForm = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     position: "",
//     email: "",
//     company: "",
//     tradingName: "",
//     questions: Array(18).fill(null),
//   });

//   const [stage, setStage] = useState("info"); // "info" for basic details, "questions" for the questionnaire
//   const [activeQuestion, setActiveQuestion] = useState(0);
//   const questionRefs = useRef([]); // To store refs for each question card

//   const questions = [
//     "Does your company currently measure its annual carbon footprint?",
//     "Are the results externally verified (e.g., EcoVadis, CDP)?",
//     "Can you calculate carbon emissions associated with your goods/services?",
//     "If no to above, do you plan to measure in the next 12 months?",
//     "Which scope of emissions does this include (Scope 1, 2, 3)?",
//     "For Scope 3, what categories of emissions are measured?",
//     "Total carbon emissions associated with goods/services (kgCO2e).",
//     "Do you have arrangements to calculate energy/material usage?",
//     "Material, delivery, electricity, fuel usage details.",
//     "Have you set a carbon reduction target?",
//     "Net zero or carbon neutral commitment?",
//     "Do you offset emissions?",
//     "Offset type: avoid/reduce, remove emissions.",
//     "Offset programmes used.",
//     "Plans to reduce Scope 1 and 2 emissions.",
//     "Summary of improvement in carbon measurements.",
//     "How can we assist you in reducing emissions?",
//     "Any other helpful information about emissions.",
//   ];

//   const answeredCount = formData.questions.filter((q) => q !== null).length;
//   const progressPercentage = Math.round((answeredCount / questions.length) * 100);

//   const handleInputChange = (field, value) => {
//     setFormData((prev) => ({ ...prev, [field]: value }));
//   };

//   const handleQuestionChange = (index, value) => {
//     const updatedQuestions = [...formData.questions];
//     updatedQuestions[index] = value;
//     setFormData((prev) => ({ ...prev, questions: updatedQuestions }));

//     // Move to the next question (if available)
//     const nextQuestionIndex = index + 1 < questions.length ? index + 1 : index;
//     setActiveQuestion(nextQuestionIndex);
//   };

//   useEffect(() => {
//     // Scroll to the current active question
//     if (questionRefs.current[activeQuestion]) {
//       questionRefs.current[activeQuestion].scrollIntoView({
//         behavior: "smooth",
//         block: "center",
//       });
//     }
//   }, [activeQuestion]); // Trigger scroll whenever the active question changes

//   const handleSubmitBasicInfo = (e) => {
//     e.preventDefault();
//     setStage("questions");

//     // Scroll to the first question
//     setTimeout(() => {
//       questionRefs.current[0]?.scrollIntoView({
//         behavior: "smooth",
//         block: "center",
//       });
//     }, 100);
//   };

//   const handleSubmitQuestions = (e) => {
//     e.preventDefault();
//     console.log(formData);
//     // Submit formData to backend
//   };

//   return (
//     <div className="form-container">
//       <h1 className="form-title">Supplier Scope 3 Questionnaire</h1>

//       {stage === "info" && (
//         <form onSubmit={handleSubmitBasicInfo}>
//           <div className="form-section">
//             <label className="form-label">
//               Name of person completing the questionnaire:
//               <input
//                 type="text"
//                 value={formData.name}
//                 onChange={(e) => handleInputChange("name", e.target.value)}
//                 className="form-input"
//               />
//             </label>
//             <label className="form-label">
//               Position within the company:
//               <input
//                 type="text"
//                 value={formData.position}
//                 onChange={(e) => handleInputChange("position", e.target.value)}
//                 className="form-input"
//               />
//             </label>
//             <label className="form-label">
//               Email address:
//               <input
//                 type="email"
//                 value={formData.email}
//                 onChange={(e) => handleInputChange("email", e.target.value)}
//                 className="form-input"
//               />
//             </label>
//             <label className="form-label">
//               Company name:
//               <input
//                 type="text"
//                 value={formData.company}
//                 onChange={(e) => handleInputChange("company", e.target.value)}
//                 className="form-input"
//               />
//             </label>
//             <label className="form-label">
//               Trading name (if different):
//               <input
//                 type="text"
//                 value={formData.tradingName}
//                 onChange={(e) => handleInputChange("tradingName", e.target.value)}
//                 className="form-input"
//               />
//             </label>
//             <button type="submit" className="form-button">
//               Start Questionnaire
//             </button>
//           </div>
//         </form>
//       )}

//       {stage === "questions" && (
//         <div>
//           {/* Progress Bar */}
//           <div className="progress-bar">
//             <div
//               className="progress-bar-fill"
//               style={{ width: `${progressPercentage}%` }}
//             ></div>
//             <span className="progress-text">{`${progressPercentage}% completed`}</span>
//           </div>

//           {/* Questions */}
//           <form onSubmit={handleSubmitQuestions} className="questions-container">
//             {questions.map((question, index) => (
//               <div
//                 key={index}
//                 ref={(el) => (questionRefs.current[index] = el)} // Store ref for each question
//                 className={`question-card ${
//                   index === activeQuestion ? "active" : "disabled"
//                 }`}
//               >
//                 <p>{question}</p>
//                 {index === activeQuestion && (
//                   <div>
//                     <button
//                       type="button"
//                       className="option-button"
//                       onClick={() => handleQuestionChange(index, "yes")}
//                     >
//                       Yes
//                     </button>
//                     <button
//                       type="button"
//                       className="option-button"
//                       onClick={() => handleQuestionChange(index, "no")}
//                     >
//                       No
//                     </button>
//                   </div>
//                 )}
//               </div>
//             ))}
//             <button type="submit" className="submit-button">
//               Submit
//             </button>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SupplierForm;


import React, { useState, useRef, useEffect } from "react";
import "./SupplierForm.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import logo from './Udak_Logo.png'

const apiUrl = process.env.REACT_APP_API_URL_BACKEND;
const SupplierForm = () => {
  const { token } = useParams(); // Assuming the token is part of the URL (e.g., /supplier-form/:token)

  console.log("Extracted Token:", token);
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  // const [formData, setFormData] = useState({
  //   name: "",
  //   position: "",
  //   company: "",
  // });
    const [formData, setFormData] = useState({
    name: "",
    position: "",
    email: "",
    company: "",
    tradingName: "",
    questions: Array(18).fill(null),
  });

  const [stage, setStage] = useState("intro"); // "info" for basic details, "questions" for the questionnaire
  const [activeQuestion, setActiveQuestion] = useState(0);
  const questionRefs = useRef([]); // To store refs for each question card

  const questions = [
    "Does your company currently measure its annual carbon footprint?",
    "Are the results externally verified (e.g., EcoVadis, CDP)?",
    "Can you calculate carbon emissions associated with your goods/services?",
    "If no to above, do you plan to measure in the next 12 months?",
    "Which scope of emissions does this include (Scope 1, 2, 3)?",
    "For Scope 3, what categories of emissions are measured?",
    "Total carbon emissions associated with goods/services (kgCO2e).",
    "Do you have arrangements to calculate energy/material usage?",
    "Material, delivery, electricity, fuel usage details.",
    "Have you set a carbon reduction target?",
    "Net zero or carbon neutral commitment?",
    "Do you offset emissions?",
    "Offset type: avoid/reduce, remove emissions.",
    "Offset programmes used.",
    "Plans to reduce Scope 1 and 2 emissions.",
    "Summary of improvement in carbon measurements.",
    "How can we assist you in reducing emissions?",
    "Any other helpful information about emissions.",
  ];

  const answeredCount = formData.questions.filter((q) => q !== null).length;
  const progressPercentage = Math.round((answeredCount / questions.length) * 100);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions[index] = value;
    setFormData((prev) => ({ ...prev, questions: updatedQuestions }));

    // Move to the next question (if available)
    const nextQuestionIndex = index + 1 < questions.length ? index + 1 : index;
    setActiveQuestion(nextQuestionIndex);
  };

  useEffect(() => {
    // Scroll to the current active question
    if (questionRefs.current[activeQuestion]) {
      questionRefs.current[activeQuestion].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeQuestion]); // Trigger scroll whenever the active question changes

  const handleSubmitBasicInfo = (e) => {
    e.preventDefault();
    setStage("questions");

    // Scroll to the first question
    setTimeout(() => {
      questionRefs.current[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        
        const response = await axios.post(`${apiUrl}/verify_supplier_token`, { token });
        setIsValid(true);
        setEmail(response.data.email);
      } catch (err) {
        setError(err.response?.data?.error || "Invalid or expired token.");
      }
    };

    verifyToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/submit_supplier_form`, {
        ...formData,
        email, // Include the email from the token verification
      });
      alert(response.data.message || "Form submitted successfully!");
    } catch (err) {
      console.error("Error submitting form:", err);
      alert(err.response?.data?.error || "An error occurred. Please try again.");
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!isValid) {
    return <div>Loading...</div>;
  }

  const handleSubmitQuestions = (e) => {
        e.preventDefault();
        console.log(formData);
        // Submit formData to backend
      };

      return (
        <div className="form-container">
          {stage === "intro" && (
            <div className="intro-section">
              <img
              width="160px"
                  height="160px"
                src={logo}// Replace with the actual logo path
                alt="Company Logo"
                className="company-logo"
              />
              <h2 className="form-title">Udak Environment</h2>

              <h1 className="form-title">Welcome to the Supplier Scope 3 Questionnaire</h1>
              <h3 className="intro-text">
                Thank you for helping us understand and manage our environmental
                impact. This questionnaire is designed to collect data related to
                Scope 3 emissions. Please ensure all fields are completed
                accurately. If you have any questions, contact us at{" "}
                <a href="mailto:contact@udakenvironment.com">contact@udakenvironment.com</a>.
              </h3>
              <h3 className="intro-text">
               The questionnaire comprises of 18 questions and all the questions are mandatory. 
               Kindly click on the start button below to start the questionnaire.
                {/* <a href="mailto:contact@udakenvironment.com">contact@udakenvironment.com</a>. */}
              </h3>
              <button
                className="form-button"
                onClick={() => setStage("info")}
              >
                Start
              </button>
            </div>
          )}
    
          {stage === "info" && (
            <form onSubmit={handleSubmitBasicInfo}>
              <img
                src="company-logo.png" // Replace with the actual logo path
                alt="Company Logo"
                className="company-logo"
              />
              <h2 className="form-title">Basic Information</h2>
              <div className="form-section">
                <label className="form-label">
                  Name of person completing the questionnaire:
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                    className="form-input"
                    required
                  />
                </label>
                <label className="form-label">
                  Position within the company:
                  <input
                    type="text"
                    value={formData.position}
                    onChange={(e) => handleInputChange("position", e.target.value)}
                    className="form-input"
                    required
                  />
                </label>
                <label className="form-label">
                  Email address:
                  <input
                    type="email"
                    value={email}
                    className="form-input"
                    readOnly
                  />
                </label>
                <label className="form-label">
                  Company name:
                  <input
                    type="text"
                    value={formData.company}
                    onChange={(e) => handleInputChange("company", e.target.value)}
                    className="form-input"
                    required
                  />
                </label>
                <label className="form-label">
                  Trading name (if different):
                  <input
                    type="text"
                    value={formData.tradingName}
                    onChange={(e) => handleInputChange("tradingName", e.target.value)}
                    className="form-input"
                  />
                </label>
                <button type="submit" className="form-button">
                  Start Questionnaire
                </button>
              </div>
            </form>
          )}
    
          {stage === "questions" && (
            <div>
              <img
                src="company-logo.png" // Replace with the actual logo path
                alt="Company Logo"
                className="company-logo"
              />
              <h2 className="form-title">Scope 3 Questionnaire</h2>
    
              {/* Progress Bar */}
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
                <span className="progress-text">{`${progressPercentage}% completed`}</span>
              </div>
    
              {/* Questions */}
              <form onSubmit={handleSubmitQuestions} className="questions-container">
                {questions.map((question, index) => (
                  <div
                    key={index}
                    className={`question-card ${
                      index === activeQuestion ? "active" : "disabled"
                    }`}
                  >
                    <p>{question}</p>
                    {index === activeQuestion && (
                      <div>
                        <button
                          type="button"
                          className="option-button"
                          onClick={() => handleQuestionChange(index, "yes")}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="option-button"
                          onClick={() => handleQuestionChange(index, "no")}
                        >
                          No
                        </button>
                      </div>
                    )}
                  </div>
                ))}
                <button type="submit" className="submit-button">
                  Submit
                </button>
              </form>
            </div>
          )}
        </div>
      );
    };

export default SupplierForm;
