import React, { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
// const apiUrl = process.env.REACT_APP_API_URL_BACKEND.replace("/api", "/app/dashboard") //.replace(':80', '');
const SourceContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL_BACKEND


const SourceProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  console.log({apiUrl})
  
  const [parentCompany, setParentCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
 
  const [equity, setEquity] = useState('');
  const [operationalPercentage, setOperationalPercentage] = useState('');
  const [financialPercentage, setFinancialPercentage] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [superStatcombusstate, setsuperStatcombusstate] = useState({}); // This will store data for all stationary combustion from the database
  const [superStatmobilecomb, setsuperStatmobilecomb] = useState({}); // This will store data for all mobile combustion from the database
  const [superState, setsuperState] = useState({}); // This will store data for all mobile combustion from the database



   // State variables for scope 3 fecthing
   const [separatedScope3Categories, setSeparatedScope3Categories] = useState([]);
   const [groupedScope3Data, setGroupedScope3Data] = useState({});
  // const [facilities, setFacilities] = useState([{}]);
  const [facilities, setFacilities] = useState([{
    id: ' ',
    data: {
      facilityDescription: '',
      facilityArea: '',
      fuels: [{ fuelCombusted: '', quantityCombusted: '', units: '', heatingvalue: '' }],
    }
  }]);

   // const [facilities, setFacilities] = useState([{}]);
   const [fleets, setFleets] = useState([{
    id: ' ',
    data: {
      fleetDescription: '',
      vehicles: [{ vehicleType: '', vehicleSubcategory: '',vehicleYear: '', vehicleId :'',vehicleFuel :'',fuelQuantity:'',units: '', distanceTravelled:'' }]
    }
  }]);

   // const [facilities, setFacilities] = useState([{}]);
   const [fugitivefacilities, setfugitivefacilities] = useState([{
    id: ' ',
    data: {
      fugitivefacilityDescription: '',
      fugitivefacilityArea: '',
      fugitivegases: [{ gasname: '',initialgas: '',finalgas: '', gaspurchased:'', gassold:'', capacitychange:'' }],
    }
  }]);

  const [elecfacilities, setElecFacilities] = useState([{
      id: ' ',
      data: {
        ElecfacilitiesDescription: '',
        ElecfacilitiesArea: '',
        Electricity: [{ city: '', comsumption: '', units:'', emFactor: '' }],
        renewableComsumption:'',
        renewableunits:'',
        renewableEmFactor:'' ,
      }
  }]);

  const [SHCfacilities, setSHCFacilities] = useState([{
    id: ' ',
      data: {
        SHCfacilityDescription: '',
        SHCfacilityArea: '',
        steamsources: [{ fuelCombusted: '', boilerefficiency: '', steampurhased: '' }],
        coolingElec: '',
        coolingEff: '',
        heatingElec: '',
        heatingEff: ''
      }
  }]);


  // const [sourceSelection, setSourceSelection] = useState(() => {
  //   const storedSourceSelection = JSON.parse(localStorage.getItem('sourceSelection'));
  //   return storedSourceSelection || {};
  // });
  const [reportingPeriods, setReportingPeriods] = useState([]);

  const handlereportingperiods = (data) => {
    // Extract all reporting periods from the data
    const periods = data.map(record => record.reporting_period);
    
    // Remove duplicates by converting the array to a Set, then back to an array
    const uniquePeriods = [...new Set(periods)];
    
    // Log the unique extracted periods
    // console.log('Unique Reporting Periods:', uniquePeriods);
    
    // Set the unique periods into the state
    setReportingPeriods(uniquePeriods);
};
  

  const groupSubsidiariesByReportingPeriod = (data) => {
    // Initialize an empty object to store the grouped subsidiaries
    const groupedSubsidiaries = {};
  
    // Iterate over each record in the data
    data.forEach(record => {
      const { reporting_period, subsidiary_name, equity_percentage, financial_percentage, operational_percentage } = record;
  
      // If the reporting period isn't already a key in the object, add it
      if (!groupedSubsidiaries[reporting_period]) {
        groupedSubsidiaries[reporting_period] = [];
      }
  
      // Add the subsidiary details to the array for the corresponding reporting period
      groupedSubsidiaries[reporting_period].push({
        subsidiary_name,
        equity_percentage,
        financial_percentage,
        operational_percentage
      });
    });
  
    // Log the grouped subsidiaries for verification
    // console.log('Grouped Subsidiaries by Reporting Period:', groupedSubsidiaries);
  
    // You can now set this object to state if needed, e.g., setSubsidiaries(groupedSubsidiaries);
    setCompanies(groupedSubsidiaries);
  };  

// Initialize sourceSelection as an empty object
const [sourceSelection, setSourceSelection] = useState({});

// Function to process and update sourceSelection
const processAndSetSourceSelection = (data) => {
  const sourcesBySubsidiary = data.reduce((acc, record) => {
    const periodSubsidiaryKey = `${record.reporting_period}-${record.subsidiary_name}`;
    const sources = JSON.parse(record.source.replace(/'/g, '"')); // Convert source string to an array

    if (!acc[periodSubsidiaryKey]) {
      acc[periodSubsidiaryKey] = new Set();
    }

    // Merge sources, avoiding duplicates using Set
    sources.forEach(source => acc[periodSubsidiaryKey].add(source));

    return acc;
  }, {});

  // Convert the sets back to arrays for easier usage in your application
  const formattedSources = Object.fromEntries(
    Object.entries(sourcesBySubsidiary).map(([key, value]) => [key, Array.from(value)])
  );

  // Update the sourceSelection state
  setSourceSelection(formattedSources);
  // console.log('Updated sourceSelection:', formattedSources);
};

  
  useEffect(() => {
    const fetchParentCompany = async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log(token)
        // const response = await axios.get('http://localhost:5000/api/toptabledata',{
          const response = await axios.get(`${apiUrl}/toptabledata`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = response.data;
        // console.log("data",data)

        const records = Array.isArray(data) ? data : [data];
    
      if (data.length > 0) {
        const firstRecord = records[0];
        setParentCompany({
          name: firstRecord.parent_company,
          country: firstRecord.parent_company_country,
          state: firstRecord.parent_company_state
        });
       
        // console.log('d.rp',data); // This logs the array of periods
        handlereportingperiods(data);       
        groupSubsidiariesByReportingPeriod(data) 
        processAndSetSourceSelection(data);
      }
    } catch (error) {
      console.error('Error fetching parent company:', error);
    }
    };

fetchParentCompany();
}, [getAccessTokenSilently]);

/////////////////////////////////////
useEffect(() => {
  const addnewcompany = async () => {
    try {
      const token = await  getAccessTokenSilently();
      
      // const response = await axios.post('http://localhost:5000/api/addsubsidiary',{ 
      const response = await axios.post(`${apiUrl}/addsubsidiary`,{ 

        // username: user.name,
        parentCompany:parentCompany.name,
        parentCompanycountry:parentCompany.country,
        parentCompanystate:parentCompany.state,
        reportingPeriods:reportingPeriods,
        companies:companies,
       } , {
        headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  }
   catch (error) {
    console.error('Error adding new company:', error);
  }
  };

  addnewcompany();
}, [companies]);
  // const [reportingPeriods, setReportingPeriods] = useState(() => {
  //   const storedPeriods = JSON.parse(localStorage.getItem('reportingPeriods'));
  //   return storedPeriods || [];
  // });
  const [monthlyData, setMonthlyData] = useState(() => {
    const storedData = JSON.parse(localStorage.getItem('monthlyData'));
    return storedData || {};
  });

  const addParentCompany = (company) => {
    setParentCompany(company);
    // localStorage.setItem('parentCompany', JSON.stringify(company));
  };

  const deleteParentCompany = () => {
    setParentCompany(null);
    // localStorage.removeItem('parentCompany');
    // Clear related data if parent company is deleted
    setCompanies({});
    setSourceSelection({});
    setReportingPeriods([]);
    setMonthlyData({});
    localStorage.removeItem('companies');
    localStorage.removeItem('sourceSelection');
    localStorage.removeItem('reportingPeriods');
    localStorage.removeItem('monthlyData');
  };


  const addCompany = (reportingPeriod, company) => {
    setCompanies(prevCompanies => {
      // Create a copy of the existing companies object
      const updatedCompanies = { ...prevCompanies };

      // Check if the reporting period already exists
      if (!updatedCompanies[reportingPeriod]) {
        updatedCompanies[reportingPeriod] = []; // Initialize the array if it doesn't exist
      }

      // Add the new company to the reporting period's array
      updatedCompanies[reportingPeriod].push(company);

      // Return the updated companies object
      return updatedCompanies;
    });
  };



  const deleteCompany = (reportingPeriod, companyName) => {
    const updatedCompanies = {
      ...companies,
      [reportingPeriod]: companies[reportingPeriod].filter(
        (company) => company.name !== companyName
      ),
    };
    setCompanies(updatedCompanies);
    localStorage.setItem('companies', JSON.stringify(updatedCompanies));
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
  };

  const handleSourceSelect = (reportingPeriod, company, source) => {
    const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
    const companySources = sourceSelection[periodKey] || [];
    const updatedSources = companySources.includes(source)
      ? companySources.filter((s) => s !== source)
      : [...companySources, source];

    const newSourceSelection = {
      ...sourceSelection,
      [periodKey]: updatedSources,
    };

    setSourceSelection(newSourceSelection);
    // localStorage.setItem('sourceSelection', JSON.stringify(newSourceSelection));
  };

  const addReportingPeriod = (period) => {
    if (!reportingPeriods.includes(period)) {
      const updatedPeriods = [...reportingPeriods, period];
      setReportingPeriods(updatedPeriods);
      localStorage.setItem('reportingPeriods', JSON.stringify(updatedPeriods));
    }
  };

  const deleteReportingPeriod = (period) => {
    const updatedPeriods = reportingPeriods.filter((p) => p !== period);
    setReportingPeriods(updatedPeriods);
    localStorage.setItem('reportingPeriods', JSON.stringify(updatedPeriods));
  };

  const handleMonthlyDataEntry = (reportingPeriod, company, source, month, data) => {
    const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
    const sourceKey = `${periodKey}-${source}`;
    const updatedMonthlyData = {
      ...monthlyData,
      [sourceKey]: {
        ...monthlyData[sourceKey],
        [month]: data,
      },
    };

    setMonthlyData(updatedMonthlyData);
    localStorage.setItem('monthlyData', JSON.stringify(updatedMonthlyData));
  };

  useEffect(() => {
    if (selectedCompany && !sourceSelection[selectedCompany.subsidiary_name]) {
      setSourceSelection({
        ...sourceSelection,
        [selectedCompany.subsidiary_name]: [],
      });
    }
  }, [selectedCompany, sourceSelection]);
  const [separatedFacilitiesT, setSeparatedFacilitiesT] = useState({});
  const [separatedFleetsT, setSeparatedFleetsT] = useState({});
  const [separatedelectricityFacilitiesT, setseparatedelectricityFacilitiesT] = useState({});
  const [separatedFugitiveemissionsT, setseparatedFugitiveemissionsT] = useState({});
  const [separatedSHCfacilitiesT, setSeparatedSHCfacilitiesT] = useState({});


    
 // Fetch Scope 3 emissions data
 const fetchScope3EmissionsData = async (reportingPeriod, companyName) => {
  const token = await getAccessTokenSilently();
  try {
    const responseScope3 = await axios.get(`${apiUrl}/fetchscope3spendbasedemissions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        reporting_period: reportingPeriod,
        company_name: companyName,
      },
    });

    console.log("responseScope3 from Dashboard scope 3", responseScope3);
    const emissionsData = responseScope3.data.emissions_data;

    setSeparatedScope3Categories(emissionsData);
    processScope3Data(emissionsData);
  } catch (error) {
    console.error("Error fetching Scope 3 emissions data:", error);
  }
};

// Process Scope 3 emissions data
const processScope3Data = (emissionsData) => {
  const grouped = emissionsData.reduce((acc, row) => {
    const category = row.scope3categories;

    // If category doesn't exist, initialize it
    if (!acc[category]) {
      acc[category] = {
        rows: [],
        totalEmissions: 0,
        isVisible: true, // Toggle for category visibility
        rowVisibility: [], // Array to track individual row visibility
      };
    }

    // Push the row to the corresponding category
    acc[category].rows.push(row);

    // Add the row's emissions to the total emissions of the category
    acc[category].totalEmissions += row.total_emissions_kg_co2e;

    // Initialize the rowVisibility array for new rows, marking them visible initially
    acc[category].rowVisibility.push(true);

    return acc;
  }, {});

  setGroupedScope3Data(grouped);
};
  




const  fetchAndProcessData = async () => {
  try {
    // // Get the token once
    // const token = await getAccessTokenSilently();
      let token;
      try {
        // Try to get the access token with a retry mechanism
        token = await getAccessTokenSilently();
      } catch (error) {
        console.error("Error getting access token:", error);
        if (error.error === 'access_denied' && error.error_description === 'Too Many Requests') {
          alert("Rate limit exceeded. Please try again later.");
          return;
        }
      }
  
      if (!token) {
        console.error("No token available. Cannot proceed with data fetching.");
        return;
      }

 // Fetch stationary combustion data
//  `${apiUrl}/addsubsidiary`
    // const responseStationary = await axios.get('http://localhost:5000/api/fetchstationarycombustion', {
      const responseStationary = await axios.get(`${apiUrl}/fetchstationarycombustion`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("responseStationary",responseStationary)
    const separatedFacilities = {};
    responseStationary.data.facilities.forEach((facility, index) => {
      const key = `stationarycombustion_${facility.data.company}_${facility.data.month}_${facility.data.period}`;
      const facilityId = facility.id || index + 1; // Use index as fallback if `facility.id` is missing
    
      // Ensure the key exists as an array in `separatedFacilities`
      separatedFacilities[key] = separatedFacilities[key] || [];
    
      // Check if a facility with the same facilityId already exists
      const existingFacility = separatedFacilities[key].find(f => f.id === facilityId);
    
      if (existingFacility) {
        // Facility already exists, so combine fuels and emissions
        existingFacility.data.fuels.push(...(facility.data?.fuels || [{
          fuelCombusted: '',
          quantityCombusted: '',
          units: '',
          heatingvalue: ''
        }]));
        
        existingFacility.data.emissions.push(...(facility.data?.emissions || [{
          co2: '',
          n2o: '',
          ch4: '',
          eqco2: ''
        }]));
      } else {
        // Facility does not exist, create a new entry
        separatedFacilities[key].push({
          id: facilityId,
          data: {
            facilityDescription: facility.data?.facilityDescription || '',
            facilityArea: facility.data?.facilityArea || '',
            fuels: facility.data?.fuels || [{
              fuelCombusted: '',
              quantityCombusted: '',
              units: '',
              heatingvalue: ''
            }],
            emissions: facility.data?.emissions || [{
              co2: '',
              n2o: '',
              ch4: '',
              eqco2: ''
            }],
          },
        });
      }
    });
    

     setsuperState(prevState => ({
      ...prevState,
      ...separatedFacilities,
    }));
    setSeparatedFacilitiesT(separatedFacilities);

    // Fetch mobile combustion data
    // const responseMobile = await axios.get('http://localhost:5000/api/fetchmobilecombustion', {
    const responseMobile = await axios.get(`${apiUrl}/fetchmobilecombustion`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("responsemobilefromfetch",responseMobile)
    const separatedFleets = {};
    

    responseMobile.data.fleets.forEach((fleet, index) => {
      const key = `mobilesources_${fleet.data.company}_${fleet.data.month}_${fleet.data.period}`;
      const fleetId = fleet.id || index + 1; // Default to index if `fleet.id` is missing
    
      // Ensure the key is an array in `separatedFleets`
      separatedFleets[key] = separatedFleets[key] || [];
    
      // Find an existing fleet with the same fleetId
      const existingFleet = separatedFleets[key].find(f => f.id === fleetId);
    
      if (existingFleet) {
        // Fleet already exists, so combine vehicles and emissions
        existingFleet.data.vehicles.push(...(fleet.data?.vehicles || [{
          vehicleType: '',
          vehicleSubcategory: '',
          vehicleYear: '',
          vehicleId: '',
          vehicleFuel: '',
          fuelQuantity: '',
          units: '',
          distanceTravelled: ''
        }]));
        
        existingFleet.data.emissions.push(...(fleet.data?.emissions || [{
          co2: '',
          n2o: '',
          ch4: '',
          eqco2: ''
        }]));
      } else {
        // Fleet does not exist, create a new entry
        separatedFleets[key].push({
          id: fleetId,
          data: {
            fleetDescription: fleet.data?.fleetDescription || '',
            vehicles: fleet.data?.vehicles || [{
              vehicleType: '',
              vehicleSubcategory: '',
              vehicleYear: '',
              vehicleId: '',
              vehicleFuel: '',
              fuelQuantity: '',
              units: '',
              distanceTravelled: ''
            }],
            emissions: fleet.data?.emissions || [{
              co2: '',
              n2o: '',
              ch4: '',
              eqco2: ''
            }]
          },
        });
      }
    });

    setsuperStatmobilecomb(prevState => ({
      ...prevState,
      ...separatedFleets,
    }));
    // console.log(separatedFleets)
    setSeparatedFleetsT(separatedFleets);
    // console.log("separatedFleets",separatedFleets);

    
    // Fetch purchased electrcity `${apiUrl}/addsubsidiary`
    // const responsePurchasedElectricity = await axios.get('http://localhost:5000/api/fetchpurchasedelectricity', {
      const responsePurchasedElectricity = await axios.get(`${apiUrl}/fetchpurchasedelectricity`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("responseelectricityfromfetch",responsePurchasedElectricity)
    const separatedElectricityFacilities = {};
    
    console.log(separatedElectricityFacilities)
    responsePurchasedElectricity.data.electricityfacilities.forEach((electricityfacility, index) => {
      const key = `purchasedelectricity_${electricityfacility.data.company}_${electricityfacility.data.month}_${electricityfacility.data.period}`;
      const ElectricityfacilityID = electricityfacility.id || index + 1; // Default to index if `fleet.id` is missing
      // console.log("key", key)
      // Ensure the key is an array in `separatedFleets`
      separatedElectricityFacilities[key] = separatedElectricityFacilities[key] || [];
    
      // Find an existing fleet with the same fleetId
      const existingElectricityfacility = separatedElectricityFacilities[key].find(f => f.id === ElectricityfacilityID);
      
      if (existingElectricityfacility) {
        // Fleet already exists, so combine vehicles and emissions
        existingElectricityfacility.data.electricity.push(...(electricityfacility.data?.electricity || [{
          State: '',
          Consumption: '',
          units: '',
          emFactor:''
        }]));
        existingElectricityfacility.data.push(...(electricityfacility.data || [{
          renewableConsumption: electricityfacility.data?.RenewableElectricityconsumption || '',
          renewableunits: electricityfacility.data?.RenewableElectricityUnits || '',
          renewableEmFactor: electricityfacility.data?.RenewableEmissionfactor || '',
        }]));
        existingElectricityfacility.data.emissions.push(...(electricityfacility.data?.emissions || [{
          eqco2: ''
        }]));

      } else {
        separatedElectricityFacilities[key].push({
          id: ElectricityfacilityID,
          data: {
            ElecfacilitiesDescription: electricityfacility.data?.facilityDescription || '',
            ElecfacilitiesArea: electricityfacility.data?.facilityArea || '',
            renewableConsumption: electricityfacility.data?.RenewableElectricityconsumption || '',
            renewableunits: electricityfacility.data?.RenewableElectricityUnits || '',
            renewableEmFactor: electricityfacility.data?.RenewableEmissionfactor || '',
            Electricity: electricityfacility.data?.electricity || [{
              State: '',
              Consumption: '',
              units: '',
              emFactor: ''
            }],
            emissions: electricityfacility.data?.emissions || [{
              eqco2: '',
            }]
          },
        });
      }
    });

    setsuperState(prevState => ({
      ...prevState,
      ...separatedElectricityFacilities,
    }));
    // console.log(separatedElectricityFacilities)
    setseparatedelectricityFacilitiesT(separatedElectricityFacilities);
    // console.log("separatedElectricityFacilities",separatedElectricityFacilities);




      
 // Fetch fugitive emissions combustion data `${apiUrl}/addsubsidiary`
//  const responsefugitiveemissions = await axios.get('http://localhost:5000/api/fetchfugitiveemissions', {
  const responsefugitiveemissions = await axios.get(`${apiUrl}/fetchfugitiveemissions`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
// console.log("responseStationary",responseStationary)
const separatedfugitiveemissions = {};
responsefugitiveemissions.data.facilities.forEach((facility, index) => {
  const key = `fugitiveemissions_${facility.data.company}_${facility.data.month}_${facility.data.period}`;
  const facilityId = facility.id || index + 1; // Use index as fallback if `facility.id` is missing

  // Ensure the key exists as an array in `separatedFacilities`
  separatedfugitiveemissions[key] = separatedfugitiveemissions[key] || [];

  // Check if a facility with the same facilityId already exists
  const existingseparatedfugitiveemissions = separatedfugitiveemissions[key].find(f => f.id === facilityId);

  if (existingseparatedfugitiveemissions) {
    // Facility already exists, so combine fuels and emissions
    existingseparatedfugitiveemissions.data.fugitivegases.push(...(facility.data?.fugitivegases || [{
      gasname: '',
      initialGas: '',
      finalgas: '',
      gaspurchased: '',
      gassold: '',
      capacitychange:''
    }]));
    
    existingseparatedfugitiveemissions.data.emissions.push(...(facility.data?.emissions || [{
      eqco2: ''
    }]));
  } else {
    // Facility does not exist, create a new entry
    separatedfugitiveemissions[key].push({
      id: facilityId,
      data: {
        fugitivefacilityDescription: facility.data?.facilityDescription || '',
        fugitivefacilityArea: facility.data?.facilityArea || '',
        fugitivegases: facility.data?.fugitivegases || [{
          gasname: '',
          initialGas: '',
          finalgas: '',
          gaspurchased: '',
          gassold: '',
          capacitychange:''
        }],
        emissions: facility.data?.emissions || [{
          eqco2: ''
        }],
      },
    });
  }
});


setsuperState(prevState => ({
  ...prevState,
  ...separatedfugitiveemissions,
}));
setseparatedFugitiveemissionsT(separatedfugitiveemissions);
// console.log("sepertedfugitiveemissions:",separatedfugitiveemissions);



 // Fetch steamheatingcooling combustion data 
//  const responseSteamheatingcooling = await axios.get('http://localhost:5000/api/fetchpurchaedsteamheatingcooling', {
  const responseSteamheatingcooling = await axios.get(`${apiUrl}/fetchpurchaedsteamheatingcooling`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
// console.log("responseStationary",responseStationary)
const separatedSHCFacilities = {};
responseSteamheatingcooling.data.facilities.forEach((facility, index) => {
  const key = `steamheatingcooling_${facility.data.company}_${facility.data.month}_${facility.data.period}`;
  const facilityId = facility.id || index + 1; // Use index as fallback if `facility.id` is missing

  // Ensure the key exists as an array in `existingSHCFacility`
  separatedSHCFacilities[key] = separatedSHCFacilities[key] || [];

  // Check if a facility with the same facilityId already exists
  const existingSHCFacility = separatedSHCFacilities[key].find(f => f.id === facilityId);

  if (existingSHCFacility) {
    // Facility already exists, so combine fuels and emissions
    existingSHCFacility.data.steamsources.push(...(facility.data?.steamsources || [{
      fuelCombusted: '',
      boilerefficiency: '',
      steampurchased: '',

    }]));
    existingSHCFacility.data.push(...(facility.data?.steamsources || [{
      coolingElec:facility.data?.coolingElectricity || '',
      coolingEff:facility.data?.coolingEfficiency || '',
      heatingElectricity:facility.data?.heatingElectricity || '',
      heatingEfficiency:facility.data?.heatingEfficiency || '',
    }]));
    
    existingSHCFacility.data.emissions.push(...(facility.data?.emissions || [{
      co2: '',
    }]));
  } else {
    // Facility does not exist, create a new entry
    separatedSHCFacilities[key].push({
      id: facilityId,
      data: {
        SHCfacilityDescription: facility.data?.facilityDescription || '',
        SHCfacilityArea: facility.data?.facilityArea || '',
        steamsources: facility.data?.steamsources || [{
          fuelCombusted: '',
          boilerefficiency: '',
          steampurchased: '',
        }],
        coolingElec:facility.data?.coolingElectricity || '',
        coolingEff:facility.data?.coolingEfficiency || '',
        heatingElec:facility.data?.heatingElectricity || '',
        heatingEff:facility.data?.heatingEfficiency || '',
        emissions: facility.data?.emissions || [{
          co2: '',
        }],
      },
    });
  }
});


 setsuperState(prevState => ({
  ...prevState,
  ...separatedSHCFacilities,
}));
setSeparatedSHCfacilitiesT(separatedSHCFacilities);
console.log("separatedSHCFacilities",separatedSHCFacilities)

  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// Call the function
// fetchAndProcessData_all();








  ////////////////////////////////
  return (
    <SourceContext.Provider
      value={{
        parentCompany,
        companies,
        selectedCompany,
        sourceSelection,
        reportingPeriods,
        addParentCompany,
        deleteParentCompany,
        addCompany,
        deleteCompany,
        handleCompanySelect,
        handleSourceSelect,
        addReportingPeriod,
        deleteReportingPeriod,
        handleMonthlyDataEntry,
        monthlyData,
        equity,
        setEquity,
        operationalPercentage,
        setOperationalPercentage,
        financialPercentage,
        setFinancialPercentage,
        facilities,
        setFacilities,
        superStatcombusstate,
        setsuperStatcombusstate,
        separatedFacilitiesT,
        // fetchAndProcessData_stationarycombustion,
        fleets,
        setFleets,
        superStatmobilecomb,
        setsuperStatmobilecomb,
        separatedFleetsT,
        // fetchAndProcessData_mobilecombustion,
        fetchAndProcessData,
        separatedelectricityFacilitiesT,
        separatedFugitiveemissionsT,
        separatedSHCfacilitiesT,
        fugitivefacilities,
        setfugitivefacilities,
        elecfacilities,
        setElecFacilities,
        SHCfacilities,
        setSHCFacilities,
        separatedScope3Categories,
        groupedScope3Data,
        fetchScope3EmissionsData,
      }}
    >
      {children}
    </SourceContext.Provider>
  );
};

export { SourceProvider, SourceContext };
