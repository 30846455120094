import React from 'react';
import './loginpage.scss';
import LoginButton from './login';

export default function Login() {
  return(
    <div className="login-wrapper"  > 
       {/* <div style={{fontSize:'50px', color:'black', position:'center'}}>Welcome to HyperAtmos Inc</div> */}
    {/* <h2>Please log in</h2> */}
    <LoginButton></LoginButton>
    </div>
  )
}