import React, { useState, useEffect, useRef, useContext } from 'react';
import { Select, Box, Button, Drawer, Table, TableBody, TableCell, TableHead, TableRow, TextField, MenuItem, Grid  } from '@material-ui/core';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import stringSimilarity from 'string-similarity'; // Import string similarity package
import { useAuth0 } from '@auth0/auth0-react';
import { SourceContext } from '../contexts/Sourcecontext';
import { Avatar, Paper } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload'; // Icon for the upload button
import statcombution_logo from './Logos/stationary_combustion.png'
import { Switch } from 'antd';
const apiUrl = process.env.REACT_APP_API_URL_BACKEND

function FugitiveExcelUpload ({ reportingPeriod, company}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [isReview, setIsReview] = useState(false);
  const [isSorttoSubmit, setisSorttoSubmit] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const { parentCompany } = useContext(SourceContext);
  const { getAccessTokenSilently } = useAuth0();

  // Sample picture for professional look
  const backgroundImageUrl = statcombution_logo
  // Handle Excel File Upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryString = evt.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      // Make a copy of the 'FuelCombusted' column into 'OriginalFugitiveGas'
    const dataWithOriginalFugitiveGas = data.map(row => ({
      ...row,  // Spread the rest of the row data
      OriginalFugitiveGas: row.FugitiveGas || null, // Copy the Fugitive Gas value to a new column
      FugitiveGas: null // Set FugitiveGas to null

    }));
    const updatedData = dataWithOriginalFugitiveGas.map((row) => {
      const closestFugitiveGas = getClosestMatches(row.OriginalFugitiveGas);
      const matchedGas = closestFugitiveGas.includes(row.OriginalFugitiveGas) ? row.OriginalFugitiveGas : ''; // Replace with a default value if no match
      return { ...row, FugitiveGas: matchedGas }; // Add/update the Fugitive Gas column
    });

    // Set the modified data with the original fugitivegas column added
    setParsedData(updatedData);  
      // setParsedData(data);  // Parse and set the data
    //   setDrawerOpen(false);  // Close the drawer after upload
      setIsReview(true);     // Move to review page
      setisSorttoSubmit(false)
    };
    reader.readAsBinaryString(file);
  };

  // Render editable table for review
  const handleDataChange = (index, field, value) => {
    const updatedData = [...parsedData];
    updatedData[index][field] = value;
    setParsedData(updatedData);
  };
// `${apiUrl}/sourceselection`
  
  // Submit the data to backend
  const handleSubmit = async() => {
    const token = await getAccessTokenSilently();

    // Construct the payload
    const payload = {
      parentcompany: parentCompany.name,
      parentcompany_country: parentCompany.country,
      company: company.subsidiary_name,
      period: reportingPeriod,
      Allrows: filteredData,
    };
  
    // Log the payload to the console
    // console.log('Payload to be sent to backend:', payload);
  
    try {
      // const response = await axios.post('http://localhost:5000/api/fugitiveemissionsfromExcel', {
        const response = await axios.post(`${apiUrl}/fugitiveemissionsfromExcel`, {
        payload
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error adding company:', error.response || error.message);
    }

  };

//the subsidiary for which data is being uploaded from the excel
const selectedSubsidiary = company.subsidiary_name

//the first filter filters out any data whcih doesnt belong to this subsidiary
const filteredData = parsedData.filter(row => row.SubsidiaryName === selectedSubsidiary);

const [selectedFugitiveGas, setSelectedFugitiveGas] = useState({}); // Store selected fugitivegas for each row

// // Merge selectedFuel into filtered data
// const mergedData = filteredData.map((row, index) => ({
//   ...row,
//   FuelCombusted: selectedFuel[index] || row.FuelCombusted, // Merge selected fugitivegas if present
// }));

const groupedData = filteredData.reduce((acc, row) => {
    const monthYear = `${row.Month}`;
    const facilityId = row.FacilityID;

    // Initialize month/year if it doesn't exist
    if (!acc[monthYear]) {
        acc[monthYear] = {};
    }

    // Initialize facility if it doesn't exist under the month/year
    if (!acc[monthYear][facilityId]) {
        acc[monthYear][facilityId] = [];
    }

    // Push the row (fugitivegas data) under the facility
    acc[monthYear][facilityId].push(row);

    return acc;
}, {});

// Split the reporting period to extract start and end dates in MM/YYYY format
const [startPeriod, endPeriod] = reportingPeriod.split(' - ');

// Function to parse month/year in different formats
const parseMonthYear = (dateString) => {
    const monthNames = {
        '01': 'January', '02': 'February', '03': 'March',
        '04': 'April', '05': 'May', '06': 'June',
        '07': 'July', '08': 'August', '09': 'September',
        '10': 'October', '11': 'November', '12': 'December',
        'Jan': 'January', 'Feb': 'February', 'Mar': 'March',
        'Apr': 'April', 'May': 'May', 'Jun': 'June',
        'Jul': 'July', 'Aug': 'August', 'Sep': 'September',
        'Oct': 'October', 'Nov': 'November', 'Dec': 'December'
    };

    // Try matching MM/YYYY format
    const mmyyyyRegex = /^(\d{2})\/(\d{4})$/;
    const mmmmyyyyRegex = /^(January|February|March|April|May|June|July|August|September|October|November|December)\/(\d{4})$/;
    const mmmyyyyRegex = /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\/(\d{4})$/;

    let match;

    // Check MM/YYYY format
    if (match = dateString.match(mmyyyyRegex)) {
        const month = match[1];
        const year = match[2];
        return new Date(`${year}-${month}-01`);
    }
    // Check MMMM/YYYY format
    else if (match = dateString.match(mmmmyyyyRegex)) {
        const month = Object.keys(monthNames).find(key => monthNames[key] === match[1]);
        const year = match[2];
        return new Date(`${year}-${month}-01`);
    }
    // Check MMM/YYYY format
    else if (match = dateString.match(mmmyyyyRegex)) {
        const month = Object.keys(monthNames).find(key => monthNames[key] === match[1]);
        const year = match[2];
        return new Date(`${year}-${month}-01`);
    }

    // Return null if no format matched
    return null;
};

// Function to check if a row falls within the reporting period
const checkWithinReportingPeriod = (monthYear) => {
    const entryDate = parseMonthYear(monthYear);
    if (!entryDate) return false; // Invalid date format

    const startDate = parseMonthYear(startPeriod);
    const endDate = parseMonthYear(endPeriod);
    
    // Check if entryDate is within the reporting period (inclusive)
    return entryDate >= startDate && entryDate <= endDate;
};

// // Function to check if a row's Subsidiary_Name matches the selected subsidiary
const checkSubsidiaryName = (subsidiaryName) => {
    return subsidiaryName === selectedSubsidiary;
};


const [viewByFacility, setViewByFacility] = useState(false);

  // Toggle function for switching between views
  const handleViewChange = () => {
    setViewByFacility(!viewByFacility);
  };


const final_filteredData = Object.keys(groupedData).reduce((acc, monthYear) => {
    // console.log("Checking monthYear:", monthYear); // Log the current monthYear being checked
    if (checkWithinReportingPeriod(monthYear)) {
        acc[monthYear] = groupedData[monthYear];
        // console.log("Added to filteredData:", monthYear); // Log when monthYear is added
    } else {
        // console.log("Not in period:", monthYear); // Log when it's not added
    }
    return acc;
}, {});


// Facility-wise data transformation (group by facilityId)
const facilityWiseData = Object.entries(final_filteredData).reduce((acc, [monthYear, facilities]) => {
  Object.entries(facilities).forEach(([facilityId, fugitivegases]) => {
    if (!acc[facilityId]) {
      acc[facilityId] = [];
    }
    acc[facilityId].push({ monthYear, fugitivegases });
  });
  return acc;
}, {});

// console.log("Final Filtered Data:", filteredData); // Log the final filteredData

const fugitivegasList= [
    'Sulphur hexafluoride (SF6)', 'HFC-23 (R-23)', 'HFC-32 (R-32)', 'HFC-41 (R-41)', 'HFC-43-10mee (R-4310mee)', 
    'HFC-125 (R-125)', 'HFC-134 (R-134)', 'HFC-134a (R-134a)', 'HFC-143 (R-143)', 'HFC-143a (R-143a)',
    'HFC-152a (R-152a)', 'HFC-227ea (R-227ea)', 'HFC-236fa (R-236fa)', 'HFC-245ca (R-245ca)', 'HFC-245fa (R-245fa)', 
    'HFC-365mfc (R-365mfc)', 'HCFC-22 (R-22)', 'HCFC-123 (R-123)', 'HCFC-124 (R-124)', 'HCFC-141b (R-141b)',
    'HCFC-142b (R-142b)', 'HCFC-225ca (R-225ca)', 'HCFC-225cb (R-225cb)', 'PFC-14 Perfluoromethane (CF4)', 
    'PFC-116 Perfluoroethane (C2F6)', 'PFC-218 Perfluoropropane (C3F8)', 'PFC-31-10 Perfluorobutane (C4F10)', 
    'PFC-318 Perfluorocyclobutane (c-C4F8)', 'PFC-41-12 Perfluoropentane (C5F12)', 'PFC-51-14 Perfluorohexane (C6F14)',
    'PFC-91-18 Perflunafene (C10F18)', 'R-400', 'R-401A', 'R-401B', 'R-401C', 'R-402A', 
    'R-402B', 'R-403A', 'R-403B', 'R-404A', 'R-405A',
    'R-406A', 'R-407A', 'R-407B', 'R-407C', 'R-407D', 
    'R-407E', 'R-408A', 'R-409A', 'R-409B', 'R-410A', 
    'R-410B', 'R-411A', 'R-411B', 'R-411C', 'R-412A', 
    'R-413A', 'R-414A', 'R-414B', 'R-415A', 'R-415B', 
    'R-416A', 'R-417A', 'R-418A', 'R-419A', 'R-420A', 
    'R-421A', 'R-422A', 'R-422B', 'R-422C', 'R-500', 
    'R-501', 'R-502', 'R-503', 'R-504', 'R-505', 
    'R-506', 'R-507A', 'R-508A', 'R-508B', 'R-509A'
]

// Initialize fugitive gas based on parsedData
useEffect(() => {
  // Ensure selectedFuel array has the same length as parsedData, defaulting to null or original fugitive gas
  const initialSelectedFugitiveGas = parsedData.map(row => row.FugitiveGas || null);
  setSelectedFugitiveGas(initialSelectedFugitiveGas);
  // Trigger validation to ensure all required fields are checked on load
  validateFugitiveGasSelection(initialSelectedFugitiveGas);
}, [parsedData]);


const getClosestMatches = (fugitivegas) => {
    const matches = stringSimilarity.findBestMatch(fugitivegas, fugitivegasList);
    return matches.ratings
      .filter((match) => match.rating > 0.2) // You can set a threshold here
      .sort((a, b) => b.rating - a.rating)
      .map((match) => match.target);
  };

  const handleFugitiveGasChange = (index, value) => {
    // console.log(`Changing fugitive gas at index ${index} to ${value}`); // Add log here

    setSelectedFugitiveGas((prev) => ({
      ...prev,[index]: value,
    }));

     // Validate if all non-strikethrough rows have a selected fugitivegas
    handleDataChange(index, 'FugitiveGas', value);

    // Create a copy of the current SelectedFugitiveGas array and update the relevant index
    const updatedSelectedFugitiveGas = [...selectedFugitiveGas];
    updatedSelectedFugitiveGas[index] = value;
    // console.log("Updated Fugitive Gas Array:", updatedSelectedFugitiveGas);

    validateFugitiveGasSelection(updatedSelectedFugitiveGas);
   
  };

  /// Validate whether all non-strikethrough rows have a fugitivegas selected
const validateFugitiveGasSelection = (updatedSelectedFugitiveGas) => {

  // console.log("Updated Fugitive Gas:", updatedSelectedFugitiveGas); // Debugging
  // console.log("Parsed Data:", parsedData); // Debugging
  const allValid = parsedData.every((row, index) => {
    const isWithinReportingPeriod = checkWithinReportingPeriod(row.Month);
    const iscompanysubsidiary = checkSubsidiaryName(row.SubsidiaryName);

    // Only validate rows that are not strikethrough
    if (isWithinReportingPeriod && iscompanysubsidiary) {
      return updatedSelectedFugitiveGas[index] !== null && updatedSelectedFugitiveGas[index] !== ""; // Ensure a valid fugitivegas is selected for these rows
    }
    return true; // Skip validation for strikethrough rows
  });

  // Disable "Next" button if any relevant row does not have a selected fugitivegas
  setIsNextDisabled(!allValid);
};


const transformData=(excelData)=> {
  const facilitiesMap = {};

  excelData.forEach(entry => {
    const facilityId = entry.FacilityID;

    if (!facilitiesMap[facilityId]) {
      facilitiesMap[facilityId] = {
        id: facilityId,
        data: {
          facilityDescription: entry.FacilityDescription,
          facilityArea: entry.FacilityArea,
          fugitivegases: []
        }
      };
    }

    facilitiesMap[facilityId].data.fugitivegases.push({
      fugitivegas: entry.FugitiveGas,
      initialgas: entry.InitialGas,
      finalgas: entry.FinalGas,
      gaspurchased: entry.GasPurchased,
      gassold: entry.GasSold,
      capacitychange: entry.CapacityChange
    });
  });

  return Object.values(facilitiesMap);
}

const transformedFacilities = transformData(filteredData);



  return (
    <div >
      <Button
        variant="contained"
        size="small"
        sx={{
          textTransform: 'none',
          backgroundColor: 'rgb(0,0,0)', // Set the background color to black
          color: 'white', // Ensure the text color is white to contrast with the black background
          fontSize: '0.5rem',
          '&:hover': {
            backgroundColor: '#333', // Darker shade for the hover state
          },
        }}
        onClick={() => setDrawerOpen(true)}
      >
        Upload Excel
      </Button>
  
  <Drawer anchor="right" 
  // sx={{
  //   width: '700px',
  //   padding: 3,
  //   height: '100%',
  //   backgroundColor: '#f5f5f5',
  // overflowY: 'auto', // Enable vertical scrolling
  //                 padding: '10px',}}
  open={drawerOpen} onClose={() => setDrawerOpen(false)}>
    
  <Box
    sx={{
      width: '700px',
      padding: 3,
      height: '100%',
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: '900px', // Set a max height for the accordion container
      overflowY: 'auto', // Enable vertical scrolling
      overflowX:'auto'
    }}
  >
    {!isReview && !isSorttoSubmit && (
      <Paper
        elevation={10}
        sx={{
          padding: 4,
          maxWidth: '70vh',
          backgroundColor: '#ffffff',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          {/* Left side: Text details */}
          <Grid item xs={6}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
              Upload Excel File For Fugitive Gas
            </Typography>

            <Typography variant="h4" sx={{ fontWeight: 'normal', color: '#333',marginBottom: 2, marginTop:5 }}>
              {`For Subsidiary: ${company.subsidiary_name}`}
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'normal', color: '#333',marginBottom: 2, marginTop:2 }}>
              {`Reporting Period: ${reportingPeriod}`}
            </Typography>

            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              style={{
                display: 'block',
                marginTop: '20px',
                fontSize: '16px',
                padding: '10px',
                width: '100%',
              }}
            />
          </Grid>

          {/* Right side: Professional Image */}
          <Grid item xs={6}>
            <Avatar
              variant="square"
              alt="Excel Upload"
              src={statcombution_logo}
              sx={{
                width: '100%',
                height: 'auto',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Add shadow for depth
                borderRadius: '30%',  // Make it circular like an icon
                backgroundColor: '#339964',  // Background color in case the image has transparency
                padding: '5px',  // Optional padding around the image
              }}
            >
              {/* <statcombution_logo style={{ color: 'green' }}/> */}
             </Avatar>
          </Grid>
        </Grid>
      </Paper>
    )}
      {/* Review Data Page */}
      {isReview && (
        <Grid container spacing={2} style={{ maxWidth: '100%', maxHeight: '100%', overflowX: 'auto', marginTop:'10px' }}>
        <Grid item xs={12}>
          {/* Your content */}
      
        {/* <div>
        <div style={{ padding: '20px' }}> Add padding to the container */}
       <Paper elevation={10} style={{ padding: '20px', marginBottom: '20px', marginTop:'20px'}}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Review data for {company.subsidiary_name} (Fugitive Gas Emissions)
            </Typography>
            <Typography variant="h5" color="textSecondary">
              Reporting Period: {reportingPeriod}
            </Typography>
          </Grid>
          {/* <h3>Review Data for {company.subsidiary_name} for Stationary Combustion for the reporting period : {reportingPeriod}</h3> */}
          <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Month/Year</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Subsidiary Name</TableCell>
                <TableCell>Facility ID</TableCell>
                <TableCell>Facility Description</TableCell>
                <TableCell>Facility Area</TableCell>
                <TableCell>Fugitive Gas</TableCell>
                <TableCell>Initial Gas</TableCell>
                <TableCell>Final Gas</TableCell>
                <TableCell>Gas Purchased</TableCell>
                <TableCell>Gas Sold</TableCell>
                <TableCell>Capacity Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {parsedData.map((row, index) => {
            // Check if the data row is within the reporting period
            const isWithinReportingPeriod = checkWithinReportingPeriod(row.Month);
            const iscompanysubsidiary = checkSubsidiaryName(row.SubsidiaryName);
            // Get closest matches for the fugitivegas from the Excel data
            const closestFugitiveGas = getClosestMatches(row.OriginalFugitiveGas);

            return (
              <TableRow 
                key={index} 
                style={isWithinReportingPeriod && iscompanysubsidiary ? {} : { textDecoration: 'line-through', color: 'blue' }} // Apply strikethrough and color if outside reporting period
              >
                <TableCell>{row.Month}</TableCell>
                <TableCell>{row.Year}</TableCell>
                <TableCell>{row.SubsidiaryName}</TableCell>
                <TableCell>{row.FacilityID}</TableCell>
                <TableCell>{row.FacilityDescription}</TableCell>
                <TableCell>{row.FacilityArea}</TableCell>
                {/* <TableCell>{row.FugitiveGas}</TableCell>
                <TableCell>{row.InitialGas}</TableCell>
                <TableCell>{row.FinalGas}</TableCell>
                <TableCell>{row.GasPurchased}</TableCell>
                <TableCell>{row.GasSold}</TableCell>
                <TableCell>{row.CapacityChange}</TableCell> */}
                <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      Original Value:{row.OriginalFugitiveGas}
                    </Typography>
                      {/* Dropdown for Fuel Combusted */}
                      <Select
                        value={selectedFugitiveGas[index] ||
                          (closestFugitiveGas.includes(row.OriginalFugitiveGas) && row.OriginalFugitiveGas)
                        }
                        onChange={(e) => handleFugitiveGasChange(index, e.target.value)}
                        
                        disabled={!isWithinReportingPeriod || !iscompanysubsidiary} // Disable Select for strikethrough rows

                      >
                          {/* Add a placeholder item for selection
                      <MenuItem value="" disabled>
                        Select Fuel
                      </MenuItem> */}
                        {closestFugitiveGas.map((fugitivegas, i) => (
                          <MenuItem key={i} value={fugitivegas}>
                            {fugitivegas}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                <TableCell>
                  <TextField value={row.InitialGas} onChange={(e) => handleDataChange(index, 'InitialGas', e.target.value)} />
                </TableCell>
                <TableCell>
                  <TextField value={row.FinalGas} onChange={(e) => handleDataChange(index, 'FinalGas', e.target.value)} />
                </TableCell>
                <TableCell>
                  <TextField value={row.GasPurchased} onChange={(e) => handleDataChange(index, 'GasPurchased', e.target.value)} />
                </TableCell>
                <TableCell>
                  <TextField value={row.GasSold} onChange={(e) => handleDataChange(index, 'GasSold', e.target.value)} />
                </TableCell>
                <TableCell>
                  <TextField value={row.CapacityChange} onChange={(e) => handleDataChange(index, 'CapacityChange', e.target.value)} />
                </TableCell>
              </TableRow>
            );
          })}
            </TableBody>
          </Table>
          </div>
          
            
        </Grid>
        </Paper>
      {/* </Grid> */}

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    <Button 
      variant="outlined" 
      onClick={() => setIsReview(false)} >Back</Button>
    <Button 
      variant="contained" 
      color="primary" 
      onClick={() => { setisSorttoSubmit(true); setIsReview(false);
            
      }}disabled={isNextDisabled} // Disable the "Next" button until validation passes
      >
       Sort and review data
       </Button>
  </div>
      
        {/* </div>
        </div> */}
        </Grid>
        </Grid>
      )}

{isSorttoSubmit && (
  <div>
     {/* <div style={{ padding: '20px' }}> Add padding to the container */}
     <Grid container spacing={2} style={{ maxWidth: '100%', maxHeight: '100%', overflowX: 'auto', marginTop:'10px' }}>
     <Grid item xs={12}>
    <Paper elevation={10} style={{ padding: '20px', marginBottom: '20px' }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Review and Submit Data for {company.subsidiary_name} (Fugitive Gas Emissions)
            </Typography>
            <Typography variant="h5" color="textSecondary">
              Reporting Period: {reportingPeriod}
            </Typography>
          </Grid>

          {/* Switch View */}
          <Box sx={{ flexGrow: 1, marginBottom: '20px' }}>
      <Grid container justifyContent="center" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom align="center">
            Toggle between Month-wise or Facility-wise views
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            <Switch
              checked={viewByFacility}
              onChange={handleViewChange}
              inputProps={{ 'aria-label': 'Switch between Month-wise and Facility-wise View' }}
            />
            <Typography variant="body1" style={{ marginLeft: '10px' }}>
              {viewByFacility ? 'Facility-wise View' : 'Month-wise View'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>

          {/* Data display */}
          <Grid item xs={12}>
          <Box
                sx={{
                  maxHeight: '450px', // Set a max height for the accordion container
                  overflowY: 'auto', // Enable vertical scrolling
                  padding: '20px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              >
            {!viewByFacility ? (
              <div>
                {Object.entries(final_filteredData).map(([monthYear, facilities]) => (
                  <Accordion key={monthYear} style={{ marginBottom: '10px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6">{monthYear}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table size="small">
                        <TableBody>
                          {Object.entries(facilities).map(([facilityId, fugitivegases]) => (
                            <React.Fragment key={facilityId}>
                              <TableRow>
                                <TableCell colSpan={9} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                  Facility ID: {facilityId}, Name: {fugitivegases[0].FacilityDescription}, Area: {fugitivegases[0].FacilityArea} m²
                                </TableCell>
                              </TableRow>
                              {fugitivegases.map((fugitivegas, fugitivegasIndex) => (
                              <TableRow key={fugitivegasIndex}>
                                <TableCell></TableCell>
                                <TableCell colSpan={3}>{fugitivegas.FugitiveGas}</TableCell>
                                <TableCell>
                                  <TextField
                                    label="InitialGas"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.InitialGas}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'InitialGas', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="FinalGas"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.FinalGas}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'FinalGas', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Gas Purchased"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.GasPurchased}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'GasPurchased', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Gas Sold"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.GasSold}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'GasSold', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Capacity Change"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.CapacityChange}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'CapacityChange', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            ) : (
              Object.entries(facilityWiseData).map(([facilityId, facilityData]) => (
                <Accordion key={facilityId} style={{ marginBottom: '10px' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Facility ID: {facilityId}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table size="small">
                      <TableBody>
                        {facilityData.map(({ monthYear, fugitivegases }, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell colSpan={9} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                {monthYear}
                              </TableCell>
                            </TableRow>
                            {fugitivegases.map((fugitivegas, fugitivegasIndex) => (
                              <TableRow key={fugitivegasIndex}>
                                <TableCell></TableCell>
                                <TableCell colSpan={3}>{fugitivegas.FugitiveGas}</TableCell>
                                <TableCell>
                                  <TextField
                                    label="InitialGas"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.InitialGas}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'InitialGas', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="FinalGas"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.FinalGas}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'FinalGas', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Gas Purchased"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.GasPurchased}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'GasPurchased', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Gas Sold"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.GasSold}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'GasSold', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Capacity Change"
                                    size="small"
                                    variant="outlined"
                                    value={fugitivegas.CapacityChange}
                                    onChange={(e) => handleDataChange(fugitivegasIndex, 'CapacityChange', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      </Grid>
      </Grid>
      {/* </div> */}
  

  {/* Actions Section */}
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    <Button 
      variant="outlined" 
      onClick={() => { setisSorttoSubmit(false); setIsReview(true); }}
    >
      Back
    </Button>

    <Button 
      variant="contained" 
      color="primary" 
      onClick={handleSubmit}
    >
      Submit Data
    </Button>
  </div>
</div>
)}
</Box>
      </Drawer>
    </div>
  );
}
export default FugitiveExcelUpload;
