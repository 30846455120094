import React from "react";
import "./Loader.css"; // Import CSS for styling
import UdakLogo from "./Udak_Logo.png"; // Import the logo from the same folder

const Loader = ({ loadingText = "Loading..." }) => {
  return (
    <div className="loader-container">
      <div className="logo-wrapper">
        <img src={UdakLogo} alt="Logo" className="logo" />
        <div className="spinner"></div>
      </div>
      <p className="loading-text">{loadingText}</p>
    </div>
  );
};

export default Loader;
