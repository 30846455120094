import React, { useContext } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { SourceContext } from "../../contexts/Sourcecontext";
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Scope1StackedBarchart = ({ reportingPeriod }) => {
  const {
    separatedFacilitiesT,
    separatedFleetsT,
    separatedFugitiveemissionsT,
  } = useContext(SourceContext);

  // Data mapping
  const dataMap = {
    "Stationary Combustion": separatedFacilitiesT,
    "Mobile Combustion": separatedFleetsT,
    "Fugitive Emissions": separatedFugitiveemissionsT,
  };

  // Extract month-year from the key
  const extractMonthYear = (key) => {
    const regex = /([A-Za-z]+ \d{4})/;
    const match = key.match(regex);
    return match ? match[1] : "Unknown";
  };

  // Calculate total emissions per month per category
  const calculateEmissions = (dataMap, period) => {
    const monthlyEmissions = {};

    Object.entries(dataMap).forEach(([category, data]) => {
      Object.entries(data).forEach(([key, facilitiesArray]) => {
        const keyParts = key.split("_");
        const keyPeriod = keyParts[keyParts.length - 1];

        // Only include data for the selected reporting period
        if (keyPeriod === period) {
          const monthYear = extractMonthYear(key);

          if (!monthlyEmissions[monthYear]) {
            monthlyEmissions[monthYear] = {};
          }

          const totalEmissions = facilitiesArray.reduce((sum, facility) => {
            const emissions = facility.data.emissions?.reduce(
              (acc, emission) => acc + (emission.eqco2 || 0),
              0
            );
            return sum + (emissions || 0);
          }, 0);

          // Assign emissions to the corresponding category
          monthlyEmissions[monthYear][category] = (monthlyEmissions[monthYear][category] || 0) + totalEmissions;
        }
      });
    });

    return monthlyEmissions;
  };

  // Prepare chart data
  const monthlyEmissions = calculateEmissions(dataMap, reportingPeriod);

  const labels = Object.keys(monthlyEmissions);
  const datasets = Object.keys(dataMap).map((category, index) => {
    return {
      label: category,
      data: labels.map((month) => monthlyEmissions[month][category] || 0),
      backgroundColor: [
        "rgba(75, 192, 192, 0.6)", // Stationary Combustion
        "rgba(153, 102, 255, 0.6)", // Mobile Combustion
        "rgba(255, 159, 64, 0.6)", // Fugitive Emissions
      ][index],
      borderColor: [
        "rgba(75, 192, 192, 1)", // Stationary Combustion
        "rgba(153, 102, 255, 1)", // Mobile Combustion
        "rgba(255, 159, 64, 1)", // Fugitive Emissions
      ][index],
      borderWidth: 1,
    };
  });

  const chartData = {
    labels,
    datasets,
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: true },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        stacked: true,
        title: { display: true, text: "Months" },
      },
      y: {
        stacked: true,
        title: { display: true, text: "Total Emissions (eqCO2)" },
      },
    },
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <h3>Monthly Emissions Breakdown</h3>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default Scope1StackedBarchart;
