import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Drawer,
  TextField,
} from "@mui/material";
import { SourceContext } from "../contexts/Sourcecontext";
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const apiUrl = process.env.REACT_APP_API_URL_BACKEND;


const SupplierEngagementForm = () => {
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [showEmissions, setShowEmissions] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [editingSupplier, setEditingSupplier] = useState(null);
  const [userEnteredData, setUserEnteredData] = useState({}); // Track user-entered data
  const { getAccessTokenSilently } = useAuth0();

  const [formUrl, setFormUrl] = useState("");
  const { groupedScope3Data, fetchScope3EmissionsData } = useContext(SourceContext);



  useEffect(() => {
    fetchScope3EmissionsData();
    
  }, []);
  useEffect(() => {
    if (!groupedScope3Data) return;

    // Process supplier data
    const suppliers = [];
    Object.entries(groupedScope3Data).forEach(([_, data]) => {
      if (data.rows && Array.isArray(data.rows)) {
        data.rows.forEach((row) => {
          suppliers.push({
            supplier: row.supplier,
            total_emissions: row.total_emissions_kg_co2e || 0,
          });
        });
      } else {
        console.warn("Skipping data as rows are not valid:", data);
      }
    });

    // Rank suppliers by CO₂e emissions
    const totalCO2e = suppliers.reduce((sum, s) => sum + s.total_emissions, 0);
    const rankedSuppliers = suppliers
      .sort((a, b) => b.total_emissions - a.total_emissions)
      .map((supplier, index) => ({
        ...supplier,
        percentage: ((supplier.total_emissions / totalCO2e) * 100).toFixed(2),
      }));

    setSelectedSuppliers(rankedSuppliers);
  }, [groupedScope3Data]);

  const maxEmissions = Math.max(...selectedSuppliers.map((s) => s.total_emissions));

  const barColors = [
    "#4caf50",
    "#ff9800",
    "#2196f3",
    "#f44336",
    "#9c27b0",
    "#00bcd4",
    "#ffc107",
    "#8bc34a",
    "#03a9f4",
    "#e91e63",
  ];

  const handleOpenDrawer = (supplier) => {
    const existingData = userEnteredData[supplier] || {
      name: "",
      email: "",
      position: "",
      company: supplier,
    };
    setFormData(existingData);
    setEditingSupplier(supplier);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setFormData({});
    setEditingSupplier(null);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const handleSubmit = async () => {
    console.log(formData)
    const token = await getAccessTokenSilently();
    // Validate that all fields are filled
    if (!formData.name || !formData.email || !formData.position || !formData.company) {
      alert("All fields are required!");
      return;
    }
    try {
      // Send the collected user info to the backend
      const response = await axios.post(`${apiUrl}/supplier_information`, formData); 
        // formData, 
        
        // {
        //   headers: {
        //     'Authorization': `Bearer ${token}`,
        //   },
        // }
      // );
      setFormUrl(response.data.form_url);
      // Extract and set the form URL
      const data = await response.data.json();
      console.log("Response from backend:", data);
      console.log("Response from backend:", response.data);

      // Extract and set the form URL
      setFormUrl(data.form_url);
      console.log("FORM URL", formUrl)
      if (response.status === 200) {
        alert(response.data.message || "Data submitted successfully!");
        setUserEnteredData((prev) => ({
          ...prev,
          [editingSupplier]: formData,
        }));
        handleCloseDrawer();
      } else {
        alert(response.data.error || "An error occurred. Please try again.");
      }
    } catch (error) {
      console.error('Error adding company:', error.response || error.message);
      alert(error.response?.data?.error || "An error occurred. Please try again.");
    }
    
  };

  return (
    <Box>
      {/* Table */}
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#f5f5f5", padding: 0 }}>
            <TableCell sx={{ padding: 0.5 }}>Supplier</TableCell>
            <TableCell align="center" sx={{ padding: 0.5 }}>
              <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                <Button
                  variant={showEmissions ? "contained" : "outlined"}
                  onClick={() => setShowEmissions(true)}
                >
                  EMISSIONS
                </Button>
                <Button
                  variant={!showEmissions ? "contained" : "outlined"}
                  onClick={() => setShowEmissions(false)}
                >
                  PERCENTAGE
                </Button>
              </Box>
            </TableCell>
            <TableCell align="center" sx={{ padding: 0.5 }}>
              User Entered Info
            </TableCell>
            <TableCell align="center" sx={{ padding: 0.5 }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedSuppliers.map((supplier, index) => {
            const barWidth = (supplier.total_emissions / maxEmissions) * 100;
            const barColor = barColors[index % barColors.length];
            const supplierHasInfo = userEnteredData[supplier.supplier];

            return (
              <TableRow key={supplier.supplier}>
                <TableCell sx={{ padding: 0.5 }}>
                  <Box sx={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                    <Typography sx={{ ml: 1, fontSize: "14px" }}>
                      {supplier.supplier}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: 0.5 }}>
                  <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Box
                      sx={{
                        width: `${barWidth}%`,
                        height: "12px",
                        backgroundColor: barColor,
                        borderRadius: "4px",
                        position: "relative",
                      }}
                    />
                    <Typography sx={{ ml: 1, fontSize: "12px" }}>
                      {showEmissions
                        ? `${supplier.total_emissions.toFixed(2)} kg CO₂e`
                        : `${supplier.percentage}%`}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: 0.5 }}>
                  {supplierHasInfo ? (
                    <Box>
                      <Typography variant="body2">
                        <strong>Name:</strong> {userEnteredData[supplier.supplier].name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Email:</strong> {userEnteredData[supplier.supplier].email}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Position:</strong> {userEnteredData[supplier.supplier].position}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body2">No Information Entered</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ padding: 0.5, textAlign: "center" }}>
                  <Button
                    variant={supplierHasInfo ? "outlined" : "contained"}
                    onClick={() => handleOpenDrawer(supplier.supplier)}
                  >
                    {supplierHasInfo ? "Edit Information" : "Add Information"}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {/* Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <Box sx={{ width: 300, p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {editingSupplier ? "Edit Supplier Information" : "Add Supplier Information"}
          </Typography>
          <TextField
            label="Name"
            name="name"
            value={formData.name || ""}
            onChange={handleFormChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email || ""}
            onChange={handleFormChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Position"
            name="position"
            value={formData.position || ""}
            onChange={handleFormChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Company"
            name="company"
            value={formData.company || ""}
            onChange={handleFormChange}
            fullWidth
            disabled
            sx={{ mb: 2 }}
          />
          <Button variant="contained" fullWidth onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SupplierEngagementForm;
