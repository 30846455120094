import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, Button } from '@mui/material';
import { FaTruck, FaShoppingCart, FaHome, FaPlane, FaCar, FaBuilding, FaDollarSign, FaRecycle } from 'react-icons/fa'; // Icons for illustration
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const Scope3CategoriesTable = ({ groupedData }) => {

    const [showEmissions, setShowEmissions] = useState(true); // Toggle for emissions
    const [showPercentage, setShowPercentage] = useState(false); // Toggle for percentage

    const categories = [
    {
      name: "Purchased Goods and Services",
      icon: <FaShoppingCart />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: (groupedData?.["Purchased Goods"]?.totalEmissions || 0) + (groupedData?.["Purchased Services"]?.totalEmissions || 0),
    },
    {
      name: "Capital Goods",
      icon: <FaHome />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.["Capital Goods"]?.totalEmissions || 0,
    },
    {
      name: "Fuel- and Energy-Related Activities",
      icon: <FaBuilding />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.fuelEnergyEmissions || 0,
    },
    {
      name: "Upstream Transport and Distribution",
      icon: <FaTruck />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.["Upstream Transportation and Distribution"]?.totalEmissions || 0,
    },
    {
      name: "Business Travel",
      icon: <FaPlane />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.businessTravelEmissions || 0,
    },
    {
      name: "Employee Commuting",
      icon: <FaCar />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.employeeCommutingEmissions || 0,
    },
    {
      name: "Upstream Leased Assets",
      icon: <FaHome />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.upstreamLeasedAssetsEmissions || 0,
    },
    {
      name: "Downstream Transport and Distribution",
      icon: <FaTruck />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.["Downstream Transportation and Distribution"]?.totalEmissions || 0,
    },
    {
      name: "Processing of Sold Products",
      icon: <FaRecycle />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.processingSoldProductsEmissions || 0,
    },
    {
      name: "Use of Sold Products",
      icon: <FaHome />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.useSoldProductsEmissions || 0,
    },
    {
      name: "End-of-Life Treatment of Sold Products",
      icon: <FaRecycle />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.endOfLifeSoldProductsEmissions || 0,
    },
    {
      name: "Downstream Leased Assets",
      icon: <FaHome />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.downstreamLeasedAssetsEmissions || 0,
    },
    {
      name: "Franchises",
      icon: <FaBuilding />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.franchisesEmissions || 0,
    },
    {
      name: "Investments",
      icon: <FaDollarSign />,
      calculationMethods: ["Hybrid", "Spend-Based", "Activity-Based"],
      emissions: groupedData?.investmentsEmissions || 0,
    },
  ];


  // Calculate total Scope 3 emissions
  const totalEmissions = categories.reduce((total, category) => total + category.emissions, 0);

  // Prepare data for the bar chart for each category
  const getChartData = (emissions) => ({
    labels: ['Emissions'],
    datasets: [
      {
        label: 'Percentage of Total Emissions',
        data: [((emissions / totalEmissions) * 100).toFixed(2)],
        backgroundColor: '#4caf50',
      },
    ],
  });


   // Prepare the colors for each category bar
   const categoryColors = [
    '#FF5733', '#33FF57', '#FF33FF', '#57FF33', '#33A1FF', '#FF8C00', 
    '#FF0055', '#33FFCC', '#FF3366', '#CC33FF', '#00FFFF', '#FF0099', 
    '#FF6600', '#FFFF00', '#00FF66'
  ];

   
       // Calculate percentage for each category
  const percentageData = categories.map((cat) => ({
    ...cat,
    percentage: ((cat.emissions / totalEmissions) * 100).toFixed(2),
  }));

  // Prepare data for each category
  const getBarWidthPercentage = (emissions) => (emissions / totalEmissions) * 100;
  return (
    <Box>
  <Typography variant="h6" sx={{ mb: 0 }}>
    Scope 3 Emissions Categories and Calculation Methods
  </Typography>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Category</TableCell>
        <TableCell>Calculation Methods</TableCell>
        <TableCell sx={{ padding: 0 }}>
  {/* Toggle Buttons */}
  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
    <Button
      variant={showEmissions ? 'contained' : 'outlined'}
      onClick={() => {
        setShowEmissions(true);
        setShowPercentage(false);
      }}
    >
      EMISSIONS
    </Button>
    <Button
      variant={showPercentage ? 'contained' : 'outlined'}
      onClick={() => {
        setShowPercentage(true);
        setShowEmissions(false);
      }}
    >
      PERCENTAGE
    </Button>
  </Box>
</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {categories.map((category, index) => {
        const barWidth = getBarWidthPercentage(category.emissions);
        const barColor = categoryColors[index % categoryColors.length]; // Cycle through the colors
        return (
          <TableRow key={index}>
              <TableCell sx={{  padding: 0.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                {category.icon}
                <Typography sx={{ ml: 1, fontSize: '14px' }}>{category.name}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{  padding: 0.5 }}>
              {category.calculationMethods.join(', ')}
            </TableCell>
            <TableCell sx={{  padding: 0.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Box
                  sx={{
                    width: `${barWidth}%`,
                    height: '12px',
                    backgroundColor: barColor,
                    borderRadius: '4px',
                    position: 'relative',
                  }}
                />
                <Typography sx={{ ml: 1, fontSize: '12px' }}>
                  {showPercentage
                    ? `${barWidth.toFixed(2)}%`
                    : `${(category.emissions / 100000).toFixed(6)} M`}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</Box>
  );
};

export default Scope3CategoriesTable;
