import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { SourceContext } from "../../contexts/Sourcecontext";
ChartJS.register(...registerables);

const TotalScope2Trend = ({ reportingPeriod }) => {
  const {
    separatedelectricityFacilitiesT,
    separatedSHCfacilitiesT,
  } = useContext(SourceContext);

  // Map of state data with labels
  const dataMap = {
    "Steam Heating Cooling": separatedSHCfacilitiesT,
    "Electricity Emissions": separatedelectricityFacilitiesT,  };

   // Your extractMonthYear function
   const extractMonthYear = (key) => {
    const regex = /([A-Za-z]+ \d{4})/;
    const match = key.match(regex);
    return match ? match[1] : "Unknown";
  };
  

  const calculateMonthlyTotal = (dataMap, period) => {
    const monthlyTotals = {};
  
    // Iterate over the categories in dataMap
    Object.entries(dataMap).forEach(([category, data]) => {
      // Iterate over the keys within each category
      Object.entries(data).forEach(([key, facilitiesArray]) => {
        const monthYear = extractMonthYear(key); // Extract month-year from the key
        const keyParts = key.split("_");
        const keyPeriod = keyParts[keyParts.length - 1]; // Get the last part of the key (the period)
        
        console.log("keyPeriod:", keyPeriod); // Debugging: Log keyPeriod to verify it's correct
  
        // Check if the keyPeriod matches the provided period
        if (keyPeriod === period) {
          console.log("Matched total scope 1:", keyPeriod); // Debugging: Log if the periods match
  
          // Loop through the facilities array and calculate emissions
          facilitiesArray.forEach((facility) => {
            const emissions = facility.data.emissions?.reduce(
              (sum, emission) => sum + (emission.eqco2 || 0), // Sum the emissions (eqCO2)
              0
            );
  
            if (emissions) {
              // Update the monthlyTotals object with the sum of emissions for the monthYear
              monthlyTotals[monthYear] = (monthlyTotals[monthYear] || 0) + emissions;
              console.log("Updated monthlyTotals:", monthlyTotals); // Debugging: Log the updated totals
            }
          });
        }
      });
    });
  
    return monthlyTotals; // Return the final monthlyTotals
  };
  
 

  const monthlyEmissions = calculateMonthlyTotal(dataMap, reportingPeriod);
 
  // Calculate the total Scope 1 emissions over the entire reporting period
  const totalEmissions = Object.values(monthlyEmissions).reduce((sum, val) => sum + val, 0);

  // Prepare data for the trend line
  const labels = Object.keys(monthlyEmissions); // Months
  const trendData = labels.map((month) => monthlyEmissions[month]);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Total Scope 2 Emissions (eqCO2)",
        data: trendData,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        tension: 0.4, // Smooth curve
        fill: true, // Shaded area under the curve
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to fill the container
    plugins: {
      tooltip: { enabled: true },
      legend: { display: false }, // Only show total emissions in the box
    },
    scales: {
      x: {
        title: { display: true, text: "Month-Year" },
      },
      y: {
        title: { display: true, text: "Total Emissions (eqCO2)" },
      },
    },
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center",  width: "100%" }}>
      <div
        style={{
          marginBottom: "20px",
          padding: "10px",
          background: "#f5f5f5",
          borderRadius: "8px",
          textAlign: "center",
          width: "300px",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h3 style={{ margin: 0, color: "#4CAF50" }}>Total Scope 2 Emissions</h3>
        <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          {totalEmissions.toFixed(2)} eqCO2
        </p>
      </div>
      <div style={{ width: "100%", height: "400px" }}> {/* Increased height */}
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default TotalScope2Trend;
