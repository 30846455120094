// //this is diplaying the reporting periods and the companies within and the sources selected for each


import React, { useContext } from 'react';
import { SourceContext } from '../contexts/Sourcecontext';
import CompanySourcesTable from './CompanySourcesTable';
import {Typography, Paper } from '@mui/material';
import { useTheme } from "@mui/material/styles";


const ReportingPeriodSources = ({ selectedReportingPeriod }) => {
  const { companies, sourceSelection } = useContext(SourceContext);
    const theme = useTheme();
  
  return (
     <Paper
      sx={{
        padding: 4,
        borderRadius: 0,
        backgroundColor: theme.palette.custom.paperBackground,
        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
        marginBottom: 1,
      }}
    >
    
      <Typography variant="h4" fontWeight="bold" gutterBottom
            sx={{ color: 'black', fontWeight: 'medium', marginBottom: 2 }}>Reporting Period: {selectedReportingPeriod}
      </Typography>
      
      <CompanySourcesTable
        reportingPeriod={selectedReportingPeriod}
        companies={companies}
        sourceSelection={sourceSelection}
      />
    
    </Paper>
  );
};

export default ReportingPeriodSources;

