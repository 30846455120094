import React, { useContext, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Typography, FormControlLabel, Switch } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { SourceContext } from "../../contexts/Sourcecontext";
// Register Chart.js modules
ChartJS.register(ArcElement, Tooltip, Legend);

const Scope1DoughnutChart = ({ reportingPeriod }) => {
  const {
    separatedelectricityFacilitiesT,
    separatedSHCfacilitiesT,
  } = useContext(SourceContext);

  // Map of state data with labels
  const dataMap = {
    "Steam Heating Cooling": separatedSHCfacilitiesT,
    "Electricity Emissions": separatedelectricityFacilitiesT,
  };

  // Helper function to calculate emissions for a specific reporting period
  const calculateEmissionsForPeriod = (data, period) => {
    return Object.entries(data).reduce((acc, [key, facilitiesArray]) => {
      const keyParts = key.split("_");
      const keyPeriod = keyParts[keyParts.length - 1];

      // Normalize both periods for comparison
      if (keyPeriod === period) {
        facilitiesArray.forEach((facility) => {
          const emissions = facility.data.emissions?.reduce(
            (sum, emission) => sum + (emission.eqco2 || 0),
            0
          );
          acc += emissions || 0;
        });
      }
      return acc;
    }, 0);
  };

  // Calculate emissions and percentages for Scope 1
  const calculateEmissions = (dataMap, period) => {
    const emissions = {};
    Object.keys(dataMap).forEach((key) => {
      emissions[key] = calculateEmissionsForPeriod(dataMap[key], period);
    });
    const totalEmissions = Object.values(emissions).reduce((sum, val) => sum + val, 0);
    const percentages = {};
    Object.keys(emissions).forEach((key) => {
      percentages[key] = ((emissions[key] / totalEmissions) * 100).toFixed(2);
    });
    return { emissions, percentages };
  };

  const { emissions, percentages } = calculateEmissions(dataMap, reportingPeriod);

  // Data for the doughnut chart
  const chartData = {
    labels: Object.keys(percentages), // Categories
    datasets: [
      {
        data: Object.values(percentages), // Percentages
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)", // Color for Stationary Combustion
          "rgba(153, 102, 255, 0.6)", // Color for Mobile Combustion
          "rgba(255, 99, 132, 0.6)", // Color for Fugitive Emissions
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Typography variant="h6" align="center">
        Scope 1 Emissions Distribution for Reporting Period: {reportingPeriod}
      </Typography>
      <Doughnut data={chartData} />
    </div>
  );
};

export default Scope1DoughnutChart;
