// import React, { useContext } from 'react';
// import { Doughnut } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
// } from 'chart.js';
// import { SourceContext } from '../contexts/Sourcecontext';
// // Register the necessary Chart.js components
// ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale);

// const StationaryCombCO2MonthlyDoughnut = () => {
//     const { separatedFacilitiesT } = useContext(SourceContext);
//     console.log(separatedFacilitiesT)
//     // Reduce the separatedFacilitiesT data to aggregate eqCO2 by month/year
//     const chartData = Object.entries(separatedFacilitiesT).reduce(
//       (acc, [key, facilitiesArray]) => {
//         facilitiesArray.forEach((facility) => {
//           const emissions = facility?.data?.emissions || [];
  
//           // Debugging: Log the key and extract month/year properly
//         //   console.log("Processing key:", key);
  
//           // Extract month and year based on the expected key format
//           // Example: stationarycombustion_Hyper_sub1_April 2018_08/2017 - 08/2018
//           const keyParts = key.split("_");
//           const monthYear = keyParts[keyParts.length - 2]; // This should give "April 2018"
  
//           const [month, year] = monthYear.split(" ");
  
//           // Calculate the total eqCO2 for this facility
//           const facilityTotalCO2 = emissions.reduce(
//             (sum, emission) => sum + (emission.eqco2 || 0),
//             0
//           );
  
//           // Check if the month/year already exists in the labels array
//           const existingIndex = acc.labels.findIndex(
//             (label) => label === `${month} ${year}`
//           );
  
//           if (existingIndex > -1) {
//             // If the month already exists, add to its eqCO2 value
//             acc.values[existingIndex] += facilityTotalCO2;
//           } else {
//             // Otherwise, add a new month/year entry
//             acc.labels.push(`${month} ${year}`);
//             acc.values.push(facilityTotalCO2);
//           }
//         });
  
//         return acc;
//       },
//       { labels: [], values: [] }
//     );
  
//     // console.log("Aggregated Data:", chartData); // Debugging: check the aggregated data
  
//   const eqCO2ChartData = {
//     labels: chartData.labels, // Month-year labels
//     datasets: [
//       {
//         data: chartData.values, // eqCO2 values
//         backgroundColor: chartData.labels.map((_, index) => `hsl(${(index * 360) / chartData.labels.length}, 100%, 60%)`), // Dynamic color for each segment
//       },
//     ],
//   };
  
//   return (
//     <div>
//       <h2>Total eqCO2 for Each Month</h2>
//       <Doughnut data={eqCO2ChartData} />
//     </div>
//   );
// };
// export default StationaryCombCO2MonthlyDoughnut;

import React, { useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import { SourceContext } from "../../contexts/Sourcecontext";

const SHCCO2MonthlyDoughnut = ({ reportingPeriod }) => {
  const { separatedFacilitiesT } = useContext(SourceContext);

  const chartData = Object.entries(separatedFacilitiesT).reduce(
    (acc, [key, facilitiesArray]) => {
      // Extract the reporting period from the key
      const keyParts = key.split("_");
      const keyPeriod = keyParts[keyParts.length - 1]; // Extract the reporting period (e.g., "08/2017 - 08/2018")

      // Skip if the reporting period doesn't match
      if (keyPeriod !== reportingPeriod) {
        return acc;
      }

      facilitiesArray.forEach((facility) => {
        const emissions = facility?.data?.emissions || [];

        // Extract month and year based on the key format
        const monthYear = keyParts[keyParts.length - 2]; // Extract "April 2018" part
        const [month, year] = monthYear.split(" ");

        // Calculate the total eqCO2 for this facility
        const facilityTotalCO2 = emissions.reduce(
          (sum, emission) => sum + (emission.eqco2 || 0),
          0
        );

        // Check if the month/year already exists in the labels array
        const existingIndex = acc.labels.findIndex(
          (label) => label === `${month} ${year}`
        );

        if (existingIndex > -1) {
          // If the month already exists, add to its eqCO2 value
          acc.values[existingIndex] += facilityTotalCO2;
        } else {
          // Otherwise, add a new month/year entry
          acc.labels.push(`${month} ${year}`);
          acc.values.push(facilityTotalCO2);
        }
      });

      return acc;
    },
    { labels: [], values: [] }
  );

  const data = {
    labels: chartData.labels, // Month/Year labels
    datasets: [
      {
        label: "CO2 Emissions (eqCO2) by Month",
        data: chartData.values, // eqCO2 values
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        hoverOffset: 4,
      },
    ],
  };

  // Return a message if there is no data for the selected reporting period
  if (chartData.labels.length === 0) {
    return <p>No data available for the selected reporting period.</p>;
  }

  return (
    <div>
      <h3>CO2 Emissions (eqCO2) by Month for Reporting Period: {reportingPeriod}</h3>
      <Doughnut data={data} />
    </div>
  );
};

export default SHCCO2MonthlyDoughnut;
