import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";

const SupplierTable = ({ filteredGroupedData }) => {
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [showEmissions, setShowEmissions] = useState(true);

  useEffect(() => {
    if (!filteredGroupedData) return;

    // Process supplier data
    const suppliers = [];
    Object.entries(filteredGroupedData).forEach(([_, data]) => {
      if (data.rows && Array.isArray(data.rows)) {
        data.rows.forEach((row) => {
          suppliers.push({
            supplier: row.supplier,
            total_emissions: row.total_emissions_kg_co2e || 0,
          });
        });
      } else {
        console.warn("Skipping data as rows are not valid:", data);
      }
    });

    // Rank suppliers by CO₂e emissions
    const totalCO2e = suppliers.reduce((sum, s) => sum + s.total_emissions, 0);
    const rankedSuppliers = suppliers
      .sort((a, b) => b.total_emissions - a.total_emissions)
      .map((supplier, index) => ({
        ...supplier,
        percentage: ((supplier.total_emissions / totalCO2e) * 100).toFixed(2),
      }));

    setSelectedSuppliers(rankedSuppliers);
  }, [filteredGroupedData]);

  const maxEmissions = Math.max(...selectedSuppliers.map((s) => s.total_emissions));

  const barColors = [
    "#4caf50",
    "#ff9800",
    "#2196f3",
    "#f44336",
    "#9c27b0",
    "#00bcd4",
    "#ffc107",
    "#8bc34a",
    "#03a9f4",
    "#e91e63",
  ];

  return (
    <Box>
      {/* Toggle Buttons */}
      

      {/* Table */}
      <Table>
  <TableHead>
    <TableRow sx={{ bgcolor: "#f5f5f5", padding: 0 }}>
      <TableCell sx={{ padding: 0.5 }}>Supplier</TableCell>
      <TableCell align="center" sx={{ padding: 0.5 }}>
        <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
          <Button
            variant={showEmissions ? "contained" : "outlined"}
            onClick={() => setShowEmissions(true)}
          >
            EMISSIONS
          </Button>
          <Button
            variant={!showEmissions ? "contained" : "outlined"}
            onClick={() => setShowEmissions(false)}
          >
            PERCENTAGE
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {selectedSuppliers.map((supplier, index) => {
      const barWidth = (supplier.total_emissions / maxEmissions) * 100;
      const barColor = barColors[index % barColors.length];

      return (
        <TableRow key={supplier.supplier}>
          <TableCell sx={{ padding: 0.5 }}>
            <Box sx={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
              <Typography sx={{ ml: 1, fontSize: "14px" }}>
                {supplier.supplier}
              </Typography>
            </Box>
          </TableCell>
          <TableCell sx={{ padding: 0.5 }}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Box
                sx={{
                  width: `${barWidth}%`,
                  height: "12px",
                  backgroundColor: barColor,
                  borderRadius: "4px",
                  position: "relative",
                }}
              />
              <Typography sx={{ ml: 1, fontSize: "12px" }}>
                {showEmissions
                  ? `${supplier.total_emissions.toFixed(2)} kg CO₂e`
                  : `${supplier.percentage}%`}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      );
    })}
  </TableBody>
</Table>
    </Box>
  );
};

export default SupplierTable;
