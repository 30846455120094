//Selection of the Sources for each copnay for each reporting period
//At the end it is also showing the table for each reporting period and the sources selected for each company
// components/SourceSelection.js


import React, { useContext } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { SourceContext } from '../contexts/Sourcecontext';
import { FaFire } from 'react-icons/fa';
import StatBox from './StatBox';
import Header from './Header';
import InformationButton from './Informationbutton';
import ReportingPeriodSources from './ReportinPeriodSources';
import CompanySourcesTable from './CompanySourcesTable';
import ExcelUploader from './Exceluploader';
import { useTheme } from "@mui/material/styles";
//scope 1 logos
import statcombution_logo from './Logos/stationary_combustion.png';
import mobilecomb_logo from './Logos/Mobile_sources_logo.png'
import fugitive_logo from './Logos/fugitiveEmission.png'
 
//scope 2 logos
import electricity_logo from './Logos/electricity_icon.png'
import steam_logo from './Logos/steam.png'


//scope 3 logos
import { FaTruck, FaShoppingCart, FaHome, FaPlane, FaCar, FaBuilding, FaDollarSign, FaRecycle } from 'react-icons/fa'; // Icons for illustration
import { FaH } from 'react-icons/fa6';
import purchasedgoods_logo from './Logos/PurchasedGoodsServices_logo.png'
import capitalgoods_logo from './Logos/capitalgoods_logo.png'
import FuelAndEnergy_logo from './Logos/FuelAndEnergy_logo.png'
import Upstreamtrans_logo from './Logos/Upstreamtrans_logo.png'
import wastegenerated_logo from './Logos/wastegenerated_logo.png'


const SourceSelection = ({ company, reportingPeriod }) => {
  const { sourceSelection, handleSourceSelect } = useContext(SourceContext);
    const theme = useTheme();
  
  const availableSources = {
    scope1: [
      {
        title: 'Stationary Combustion',
        description: 'Fuels like oil and gas burned in the buildings or equipment owned by your organization',
        icon: <img src={statcombution_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,
      },
      {
        title: 'Mobile sources',
        description: 'Purchased fuel by your organization for owned or leased vehicles and mobile equipment',
        icon: <img src={mobilecomb_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,
      },
      {
        title: 'Fugitive Emissions - Refrigeration',
        description: 'Chemical releases from AC and refrigeration equipment',
        icon: <img src={fugitive_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,

      },
    ],
    scope2: [
      {
        title: 'Purchased Electricity',
        description: 'Purchased electricity from a utility company or other suppliers.',
        icon: <img src={electricity_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,
      },
      {
        title: 'Purchase steam, heat and cooling',
        description: 'Steam, heat or cooling generated off-site and used in buildings or equipment operated by your organization',
        icon: <img src={steam_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,

      },
    ],
    scope3: [
      {
        title: 'Purchased Goods and Services',
        description: 'Emissions from the production of goods and services purchased by your organization',
        icon: <img src={purchasedgoods_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,
      },
      {
        title: 'Capital Goods',
        description: 'Emissions from the production of capital goods, such as buildings, machinery, and equipment',
        icon: <img src={capitalgoods_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,


      },
      {
        title: 'Fuel- and Energy-Related Activities (Not Included in Scope 1 or 2)',
        description: 'Emissions related to the production and transportation of fuels and energy purchased and consumed by your organization',
        icon: <img src={FuelAndEnergy_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,
        
      },
      {
        title: 'Upstream Transportation and Distribution',
        description: 'Emissions from the transportation and distribution of products purchased by your organization, including inbound logistics',
        icon: <img src={Upstreamtrans_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,
      
      },
      {
        title: 'Waste Generated in Operations',
        description: 'Emissions from the disposal and treatment of waste generated by your organization’s operations',
        icon: <img src={wastegenerated_logo} alt="Logo" style={{ height: '50px', marginLeft: '6px' }} />,

      },
      {
        title: 'Business Travel',
        description: 'Emissions from employee travel for business purposes in vehicles not owned or controlled by your organization',
      },
      {
        title: 'Employee Commuting',
        description: 'Emissions from employee commuting to and from work',
      },
      {
        title: 'Upstream Leased Assets',
        description: 'Emissions from the operation of assets leased by your organization (upstream in the value chain)',
      },
      {
        title: 'Downstream Transportation and Distribution',
        description: 'Emissions from the transportation and distribution of products sold by your organization',
      },
      {
        title: 'Processing of Sold Products',
        description: 'Emissions from the processing of products sold by your organization by third parties',
      },
      {
        title: 'Use of Sold Products',
        description: 'Emissions from the use of products sold by your organization by consumers or other end users',
      },
      {
        title: 'End-of-Life Treatment of Sold Products',
        description: 'Emissions from the waste disposal and treatment of products sold by your organization at the end of their life',
      },
      {
        title: 'Downstream Leased Assets',
        description: 'Emissions from the operation of assets leased to other parties by your organization',
      },
      {
        title: 'Franchises',
        description: 'Emissions from the operation of franchises under your organization’s control',
      },
      {
        title: 'Investments',
        description: 'Emissions associated with investments made by your organization, such as portfolio holdings',
      },
      
    ],
  };

  const handleChange = (sourceTitle) => {
    handleSourceSelect(reportingPeriod, company, sourceTitle);
  };

  if (!company) {
    return <div>Please select a company to see source options.</div>;
  }

  const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
  const selectedSourcesCount = sourceSelection[periodKey]?.length || 0;

  return (
    <div>
       <Typography variant="h4" fontWeight="bold" gutterBottom
                  sx={{ color: 'black', fontWeight: 'medium', marginBottom: 2 }}>Source Selection for {company.subsidiary_name}
        </Typography>
      <p>Number of Sources Selected: {selectedSourcesCount}</p>
      <Grid container spacing={2}>
        {/* Scope 1 */}
        <Grid item xs={12} sm={12} md={4} borderRight={'2px solid black'}>
          <Box margin="2px 5px" marginBottom="20px">
          <Typography variant="h5" sx={{ marginBottom: "4px" }}>
              Scope 1
            </Typography>
            <Typography variant="h6" >
              Direct emissions from owned or controlled sources.
            </Typography>
          </Box>
          {availableSources.scope1.map((source, index) => (
            <Box
            sx={{
              backgroundColor: sourceSelection[periodKey]?.includes(source.title)
                ? 'rgb(227,234,240)' // Light background when selected
                : 'white', // Default background color
              border: sourceSelection[periodKey]?.includes(source.title)
                ? '2px solid rgb(0, 0, 253)': 'rgb(227,234,240)', // Selected border color
              borderRadius: '8px', // Rounded corners for a modern look
              // transition: 'all 0.3s ease-in-out', // Smooth transition for border and background
              padding: '2px', // Optional: Add padding for content inside the box
              cursor: 'pointer', // Optional: Make it visually clickable
              "&:hover": {
                  backgroundColor:'rgb(227,234,240)', // Subtle hover effect
                },
              
            }}
              key={index}
              // backgroundColor="#C0C0C0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="2px 5px"
              // borderRadius={3}
              marginBottom="20px"
              
              // border={sourceSelection[periodKey]?.includes(source.title) ? '2px solid #1b546e' : '2.5px solid #000'}
            
              onClick={() => handleChange(source.title)}
            >
              <StatBox
                title={source.title}
                description={source.description}
                icon={source.icon}
              />
            </Box>
          ))}
        </Grid>

        {/* Scope 2 */}
        <Grid item xs={12} sm={12} md={4} borderRight={'2px solid black'}>
        <Box margin="2px 5px" marginBottom="20px">
          <Typography variant="h5" sx={{ marginBottom: "4px" }}>
              Scope 2
            </Typography>
            <Typography variant="h6" >
            Indirect emissions from purchased electricity, heating, cooling and steam.
            </Typography>
          </Box>
          {availableSources.scope2.map((source, index) => (
            <Box
            sx={{
              backgroundColor: sourceSelection[periodKey]?.includes(source.title)
                ? 'rgb(227,234,240)' // Light background when selected
                : 'white', // Default background color
              border: sourceSelection[periodKey]?.includes(source.title)
                ? '2px solid rgb(0, 0, 253)': 'rgb(227,234,240)', // Selected border color
              borderRadius: '8px', // Rounded corners for a modern look
              // transition: 'all 0.3s ease-in-out', // Smooth transition for border and background
              padding: '2px', // Optional: Add padding for content inside the box
              cursor: 'pointer', // Optional: Make it visually clickable
              "&:hover": {
                  backgroundColor:'rgb(227,234,240)', // Subtle hover effect
                },
              
            }}
              key={index}
              // backgroundColor="#C0C0C0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="2px 5px"
              // borderRadius={3}
              marginBottom="20px"
              
              // border={sourceSelection[periodKey]?.includes(source.title) ? '2px solid #1b546e' : '2.5px solid #000'}
            
              onClick={() => handleChange(source.title)}
            >
              <StatBox
                title={source.title}
                description={source.description}
                icon={source.icon}
              />
            </Box>
          ))}
        </Grid>

        {/* Scope 3 */}
        <Grid item xs={12} sm={12} md={4}>
        <Box margin="2px 5px" marginBottom="20px">
          <Typography variant="h5" sx={{ marginBottom: "4px" }}>
              Scope 3
            </Typography>
            <Typography variant="h6" >
           Other indirect emissions from activities not owned or controlled.
            </Typography>
          </Box>
          {availableSources.scope3.map((source, index) => (
            <Box
            sx={{
              backgroundColor: sourceSelection[periodKey]?.includes(source.title)
                ? 'rgb(227,234,240)' // Light background when selected
                : 'white', // Default background color
              border: sourceSelection[periodKey]?.includes(source.title)
                ? '2px solid rgb(0, 0, 253)': 'rgb(227,234,240)', // Selected border color
              borderRadius: '8px', // Rounded corners for a modern look
              // transition: 'all 0.3s ease-in-out', // Smooth transition for border and background
              padding: '2px', // Optional: Add padding for content inside the box
              cursor: 'pointer', // Optional: Make it visually clickable
              "&:hover": {
                  backgroundColor:'rgb(227,234,240)', // Subtle hover effect
                },
              
            }}
              key={index}
              // backgroundColor="#C0C0C0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="2px 5px"
              // borderRadius={3}
              marginBottom="20px"
              
              // border={sourceSelection[periodKey]?.includes(source.title) ? '2px solid #1b546e' : '2.5px solid #000'}
            
              onClick={() => handleChange(source.title)}
            >
              <StatBox
                title={source.title}
                description={source.description}
                icon={source.icon}
              />
            </Box>
          ))}
        </Grid>
      </Grid>

      {/* <CompanySourcesTable reportingPeriod={reportingPeriod} companies={company.name} sourceSelection={sourceSelection} /> */}
      {/* <ReportingPeriodSources /> */}
      
    </div>
  );
};

export default SourceSelection;
