// import React, { useContext } from 'react';
// import { Doughnut } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
// } from 'chart.js';
// import { SourceContext } from '../contexts/Sourcecontext';
// import { color } from 'd3-color';
// // Register the necessary Chart.js components
// ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale);

// const FacilityCO2Doughnut = () => {
//   const { separatedFacilitiesT } = useContext(SourceContext);

//   const chartData = Object.entries(separatedFacilitiesT).reduce(
//     (acc, [key, facilitiesArray]) => {
//       facilitiesArray.forEach((facility) => {
//         const emissions = facility?.data?.emissions || [];
//         const facilityTotalCO2 = emissions.reduce(
//           (sum, emission) => sum + (emission.eqco2 || 0),
//           0
//         );
  
//         // Check if the facility is already in the labels array
//         const existingIndex = acc.labels.findIndex(
//           (label) => label === (facility?.facilityDescription || `Facility ${facility.id}`)
//         );
  
//         if (existingIndex > -1) {
//           // If the facility already exists, add to its CO2 value
//           acc.values[existingIndex] += facilityTotalCO2;
//         } else {
//           // Otherwise, add a new entry
//           acc.labels.push(facility?.facilityDescription || `Facility ${facility.id}`);
//           acc.values.push(facilityTotalCO2);
//         }
//       });
//       return acc;
//     },
//     { labels: [], values: [] }
//   );
//   const data = {
//     labels: chartData.labels, // Facility names
//     datasets: [
//       {
//         label: 'Total CO2 (eqCO2)',
//         data: chartData.values, // Total CO2 values
//         backgroundColor: [
//           '#FF6384',
//           '#36A2EB',
//           '#FFCE56',
//           '#4BC0C0',
//           '#9966FF',
//           '#FF9F40',
//         ],
//         hoverOffset: 4,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         display: true,
//         position: 'top',
//         labels: {
//           color: 'white', // Make legend labels white
//         },
//       },
//       tooltip: {
//         callbacks: {
//           label: (tooltipItem) =>
//             `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)} CO2e`,
//         },
//       },
//     },
//   };

//   return (
//     <div style={{ height: '400px', width: '400px', margin: 'auto' }}>
//       <Doughnut data={data} options={options} />
//     </div>
//   );
// };

// export default FacilityCO2Doughnut;

import React, { useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import { SourceContext } from "../../contexts/Sourcecontext";


const MobileCombCO2FleetChart = ({ reportingPeriod }) => {
  const { separatedFleetsT } = useContext(SourceContext);

  const chartData = Object.entries(separatedFleetsT).reduce(
    (acc, [key, facilitiesArray]) => {
      // Extract the reporting period from the key (key format: "stationarycombustion_Hyper_sub1_July 2018_08/2017 - 08/2018")
      const keyParts = key.split("_");
      const keyPeriod = keyParts[keyParts.length - 1]; // This gives "08/2017 - 08/2018"

      // Check if the reporting period matches the one passed as a prop
      if (keyPeriod !== reportingPeriod) {
        return acc; // Skip if the reporting period doesn't match
      }

      facilitiesArray.forEach((fleet) => {
        const emissions = fleet?.data?.emissions || [];
        const facilityTotalCO2 = emissions.reduce(
          (sum, emission) => sum + (emission.eqco2 || 0),
          0
        );

        // Check if the facility is already in the labels array
        const existingIndex = acc.labels.findIndex(
          (label) => label === (fleet?.fleetDescription || `fleet ${fleet.id}`)
        );

        if (existingIndex > -1) {
          // If the facility already exists, add to its CO2 value
          acc.values[existingIndex] += facilityTotalCO2;
        } else {
          // Otherwise, add a new entry
          acc.labels.push(fleet?.fleetDescription || `fleet ${fleet.id}`);
          acc.values.push(facilityTotalCO2);
        }
      });
      return acc;
    },
    { labels: [], values: [] }
  );

  const data = {
    labels: chartData.labels, // Facility names
    datasets: [
      {
        label: "Total CO2 (eqCO2)",
        data: chartData.values, // Total CO2 values
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        hoverOffset: 4,
      },
    ],
  };

  // Return a message if there is no data for the selected reporting period
  if (chartData.labels.length === 0) {
    return <p>No data available for the selected reporting period.</p>;
  }

  return (
    <div>
      <h3>CO2 Emissions for Reporting Period: {reportingPeriod}</h3>
      <Doughnut data={data} />
    </div>
  );
};

export default MobileCombCO2FleetChart;

