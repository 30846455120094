import React, { useContext, useEffect } from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { SourceContext } from "../contexts/Sourcecontext";

Chart.register(ArcElement, Tooltip, Legend);

const EmissionDashboard = ({ groupedData }) => {
  const {
    separatedFacilitiesT,
    separatedFleetsT,
    separatedelectricityFacilitiesT,
    separatedFugitiveemissionsT,
    separatedSHCfacilitiesT,
    fetchAndProcessData,
  } = useContext(SourceContext);

  useEffect(() => {
    fetchAndProcessData();
  }, []);

  // Scope 1 Total
  const scope1Total =
    (separatedFacilitiesT?.total || 0) +
    (separatedFleetsT?.total || 0) +
    (separatedelectricityFacilitiesT?.total || 0);

  // Scope 2 Total
  const scope2Total =
    (separatedFugitiveemissionsT?.total || 0) +
    (separatedSHCfacilitiesT?.total || 0);

  // Scope 3 Total
  const calculateScope3 = () => {
    const upstreamCategories = [
      "Purchased Goods and Services",
      "Capital Goods",
      "Fuel- and Energy-Related Activities",
      "Upstream Transport and Distribution",
      "Business Travel",
      "Employee Commuting",
      "Upstream Leased Assets",
    ];
    const downstreamCategories = [
      "Downstream Transport and Distribution",
      "Processing of Sold Products",
      "Use of Sold Products",
      "End-of-Life Treatment of Sold Products",
      "Downstream Leased Assets",
      "Franchises",
      "Investments",
    ];

    const calculateEmissions = (categories) =>
      categories.reduce((total, category) => {
        switch (category) {
          case "Purchased Goods and Services":
            return (
              total +
              (groupedData?.["Purchased Goods"]?.totalEmissions || 0) +
              (groupedData?.["Purchased Services"]?.totalEmissions || 0)
            );
          case "Capital Goods":
            return total + (groupedData?.["Capital Goods"]?.totalEmissions || 0);
          case "Fuel- and Energy-Related Activities":
            return total + (groupedData?.fuelEnergyEmissions || 0);
          case "Upstream Transport and Distribution":
            return (
              total +
              (groupedData?.["Upstream Transportation and Distribution"]?.totalEmissions || 0)
            );
          case "Business Travel":
            return total + (groupedData?.businessTravelEmissions || 0);
          case "Employee Commuting":
            return total + (groupedData?.employeeCommutingEmissions || 0);
          case "Upstream Leased Assets":
            return total + (groupedData?.upstreamLeasedAssetsEmissions || 0);
          case "Downstream Transport and Distribution":
            return (
              total +
              (groupedData?.["Downstream Transportation and Distribution"]?.totalEmissions || 0)
            );
          case "Processing of Sold Products":
            return total + (groupedData?.processingSoldProductsEmissions || 0);
          case "Use of Sold Products":
            return total + (groupedData?.useSoldProductsEmissions || 0);
          case "End-of-Life Treatment of Sold Products":
            return total + (groupedData?.endOfLifeSoldProductsEmissions || 0);
          case "Downstream Leased Assets":
            return total + (groupedData?.downstreamLeasedAssetsEmissions || 0);
          case "Franchises":
            return total + (groupedData?.franchisesEmissions || 0);
          case "Investments":
            return total + (groupedData?.investmentsEmissions || 0);
          default:
            return total;
        }
      }, 0);

    const upstreamEmissions = calculateEmissions(upstreamCategories);
    const downstreamEmissions = calculateEmissions(downstreamCategories);
    return upstreamEmissions + downstreamEmissions;
  };

  const scope3Total = calculateScope3();

  // Grand Total Emissions
  const grandTotal = scope1Total + scope2Total + scope3Total;

  // Percentages
  const scope1Percent = ((scope1Total / grandTotal) * 100).toFixed(2);
  const scope2Percent = ((scope2Total / grandTotal) * 100).toFixed(2);
  const scope3Percent = ((scope3Total / grandTotal) * 100).toFixed(2);

  // Doughnut Data for Scope 1 and Scope 2
  const scope1and2Data = {
    labels: ["Scope 1", "Scope 2"],
    datasets: [
      {
        data: [scope1Total, scope2Total],
        backgroundColor: ["#4CAF50", "#2196F3"],
      },
    ],
  };

  // Doughnut Data for Scope 3 Categories
  const scope3Data = {
    labels: [
      "Purchased Goods and Services",
      "Capital Goods",
      "Fuel- and Energy-Related Activities",
      "Upstream Transport and Distribution",
      "Business Travel",
      "Employee Commuting",
      "Downstream Transport and Distribution",
      "Use of Sold Products",
      "End-of-Life Treatment of Sold Products",
    ],
    datasets: [
      {
        data: [
          groupedData?.["Purchased Goods"]?.totalEmissions || 0,
          groupedData?.["Capital Goods"]?.totalEmissions || 0,
          groupedData?.fuelEnergyEmissions || 0,
          groupedData?.["Upstream Transportation and Distribution"]?.totalEmissions || 0,
          groupedData?.businessTravelEmissions || 0,
          groupedData?.employeeCommutingEmissions || 0,
          groupedData?.["Downstream Transportation and Distribution"]?.totalEmissions || 0,
          groupedData?.useSoldProductsEmissions || 0,
          groupedData?.endOfLifeSoldProductsEmissions || 0,
        ],
        backgroundColor: [
          "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#4CAF50", "#F44336",
          "#607D8B",
        ],
      },
    ],
  };

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        {/* Total Emissions Section */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h6">Total Emissions</Typography>
            <Typography>Scope 1: {scope1Total} tCO₂e ({scope1Percent}%)</Typography>
            <Typography>Scope 2: {scope2Total} tCO₂e ({scope2Percent}%)</Typography>
            <Typography>Scope 3: {scope3Total} tCO₂e ({scope3Percent}%)</Typography>
          </Paper>
        </Grid>

        {/* Scope 1 and 2 Doughnut */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h6">Scope 1 & 2 Breakdown</Typography>
            <Doughnut data={scope1and2Data} />
          </Paper>
        </Grid>

        {/* Scope 3 Doughnut */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h6">Scope 3 Breakdown</Typography>
            <Doughnut data={scope3Data} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmissionDashboard;
