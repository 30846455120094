import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core';
import { useTheme } from "@mui/material/styles";


const CompanySourcesTable = ({ reportingPeriod, companies, sourceSelection }) => {
  const periodCompanies = companies[reportingPeriod] || [];
  const theme = useTheme();

  return (
  
        <TableContainer component={Box}  sx={{
          maxHeight: 200,
          padding: 0,
          backgroundColor: '#ffffff', 
          borderRadius:10,// Set background color to white
        }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold'}}>Subsidiary Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '1.0rem', }}>
                  Sources selected
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
          {periodCompanies.map((company) => {
            const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
            return (  
              <TableRow key={company.subsidiary_name}>
                <TableCell sx={{
              fontSize: '0.9rem', // Increased font size for body cells
            }}>{company.subsidiary_name}</TableCell>
                <TableCell sx={{
              fontSize: '0.9rem', // Increased font size for body cells
            }}>
                  {sourceSelection[periodKey] ? sourceSelection[periodKey].join(', ') : 'None'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>



  );
};

export default CompanySourcesTable;
