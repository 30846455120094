// components/CompaniesTabs.js
import React, { useContext, useState } from 'react';
import { Tabs, Tab, Box, Paper, Button } from '@mui/material';
import { SourceContext } from '../contexts/Sourcecontext';
import SourceSelection from './CompaniesTabs';
import MonthlyDataEntry from './MonthlyDataEntry';
import axios from 'axios';
import { useTheme } from "@mui/material/styles";

const apiUrl = process.env.REACT_APP_API_URL_BACKEND

const CompaniesTabsReportingPeriod = ({ reportingPeriod }) => {
  const { companies, handleCompanySelect } = useContext(SourceContext);
  const { sourceSelection, parentCompany } = useContext(SourceContext);
  const periodCompanies = companies[reportingPeriod] || [];
  const [selectedTab, setSelectedTab] = useState(0);
  const [monthlydataentry, setmonthlydataentry] =useState(false)
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    handleCompanySelect(periodCompanies[newValue]);
  };
// `${apiUrl}/toptabledata`
  const handlemonthlydataentry = () =>{
    setmonthlydataentry(true);
    try {
      // const response =  axios.post('http://localhost:5000/api/sourceselection',{
        const response =  axios.post(`${apiUrl}/sourceselection`,{
        parentCompany:parentCompany,
        sourceselection: sourceSelection,
      });
      console.log(response.data); // You can handle the response data here if needed
    } catch (error) {
      console.error('Error adding company:', error.response || error.message); 
    }
  }

  const handlebacktosourceselection = () =>{
    setmonthlydataentry(false);
  }
  // console.log(periodCompanies)
  // console.log(sourceSelection)
  return (
    <Paper
    sx={{
      // padding: 4,
      // borderRadius: 4,
      backgroundColor: theme.palette.custom.paperBackground,
      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
      marginBottom: 1,
      // maxWidth:'100vh',
    }}
    >
      <Tabs
      value={selectedTab}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
  sx={{
    backgroundColor: theme.palette.custom.paperBackground,
    '.MuiTabs-indicator': {
      backgroundColor: '#032536', // Color for the active tab indicator
      height: '4px', // Make the indicator thicker,
      marginTop: '16px', // Add a gap between the top of the box and the tabs
      
    },
    '.MuiTab-root': {
      textTransform: 'none', // Disable uppercase text
      fontSize: '1rem', // Adjust font size for readability
      fontWeight: 500, // Make text slightly bold
      padding: '12px 16px', // Increase padding for better clickability
      borderRadius: '12px', // Add rounded corners
      margin: '0 8px', // Add spacing between tabs
      color: '#5a5a5a', // Default text color
      transition: 'all 0.3s ease', // Smooth hover effect
    },
    '.MuiTab-root:hover': {
      backgroundColor: '#e3eaf0', // Add hover background color
    },
    '.Mui-selected': {
      color: '#003f5c', // Active tab text color
      backgroundColor: '#abb3b8', // Active tab background
      fontWeight: 'bold', // Highlight the active tab
    },
    '.MuiTabs-scrollButtons': {
      color: '#003f5c', // Style scroll buttons
    },
  }}
>
  {periodCompanies.map((company, index) => (
    <Tab key={index} label={company.subsidiary_name} />
  ))}
          <Box
  onClick={handlebacktosourceselection}
  sx={{
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '12px 24px',
    fontSize: '16px',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#333',
    },
  }}
>
  Back to source selection
</Box>

<Box
  sx={{
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '12px 24px',
    fontSize: '16px',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#333',
    },
  }}
  onClick={handlemonthlydataentry}
>
  Proceed to monthly data Entry
  </Box>
  
</Tabs>
      {periodCompanies.length > 0 && !monthlydataentry && (
        <Box p={3}sx={{
          // padding: 4,
          // borderRadius: 4,
          backgroundColor: theme.palette.custom.paperBackground,
          // boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
          marginBottom: 1,
          
        }}>
          <SourceSelection company={periodCompanies[selectedTab]} reportingPeriod={reportingPeriod} />
          <Button variant="contained" onClick={handlemonthlydataentry}
           style={{
            width: '100%',
            height: '50px',
            fontSize: '14px'
          }}>
         Proceed to monthly data Entry
       </Button>
        </Box>
      )}
      {periodCompanies.length > 0 && monthlydataentry && (
        <Box p={3}
        sx={{
          // padding: 4,
          // borderRadius: 4,
          backgroundColor: theme.palette.custom.paperBackground,
          // boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
          marginBottom: 1,
          // maxWidth:'90%'
        }}>
          {/* <SourceSelection company={periodCompanies[selectedTab]} reportingPeriod={reportingPeriod} /> */}
          <MonthlyDataEntry reportingPeriod={reportingPeriod} company={periodCompanies[selectedTab]} />

      </Box>
      )}
    </Paper>
  );
};

export default CompaniesTabsReportingPeriod;