import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Scope3FinalView from './Scope3FinalView';
import SupplierTable from '../components/SupplierTable';
import { Box, Grid, Typography } from '@mui/material';
import Loader from '../Loadinganimation/Loader';
import { SourceContext } from '../contexts/Sourcecontext';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Scope3CategoriesTable from './Scope3categorytable';
import SupplierTableforScope3Dash from '../components/SupplierTableforScope3Dash';
import Scope3Summary from './Scope3FinalView';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL_BACKEND;

const Scope3Dashboard = () => {
  // const [separatedScope3Categories, setSeparatedScope3Categories] = useState([]);
  const [finalScope3Data, setFinalScope3Data] = useState({});
  // const [groupedScope3Data, setGroupedData] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setloading] = useState(true);
  const { parentCompany, company, reportingPeriods, companies, handleCompanySelect, groupedScope3Data,
    separatedScope3Categories, fetchScope3EmissionsData } = useContext(SourceContext);

  const location = useLocation();
  const { selectedPeriod } = location.state || {};

   // State for selected company and reporting period
   const [selectedCompany, setSelectedCompany] = React.useState('');
  //  const [selectedPeriod, setSelectedPeriod] = React.useState('');
   const [initialloading, setinitialLoading] = useState(false);
   const periodCompanies = companies[selectedPeriod] || [];

   const navigate = useNavigate();

   useEffect(() => {
    fetchScope3EmissionsData();
    // setGroupedData({})
  }, []);

  useEffect(() => {
    console.log(groupedScope3Data)
    // Automatically trigger the firstReview after 5 seconds
    if (groupedScope3Data) {
        setloading(false);
  }

  }, [separatedScope3Categories]);
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    handleCompanySelect(event.target.value); // Trigger any selection logic
  };

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // Automatically trigger the firstReview after 5 seconds
      setinitialLoading(true);
    const timer = setTimeout(() => {
      // setFirstReview(true);
      setinitialLoading(false); // Stop loading after the review is triggered
    }, 1000);

    // Clean up the timer in case the component unmounts or something changes
    return () => clearTimeout(timer);
  
  }, []);

  // Function to process the Scope 3 emissions data
  const processScope3Data = (data) => {
    const finalData = {};
    const supplierData = [];

    // Loop through the emissions data and organize it
    data.forEach((item) => {
      const category = item.scope3categories;
      const totalEmissions = item.total_emissions_kg_co2e;
      const supplier = item.supplier;
      const amount = item.amount;

      // Accumulate data for final view
      if (!finalData[category]) {
        finalData[category] = 0;
      }
      finalData[category] += totalEmissions;

      // Add supplier info
      supplierData.push({
        supplier,
        category,
        amount,
        emissions: totalEmissions,
      });
    });

    // Update state with processed data
    setFinalScope3Data(finalData);
    setSuppliers(supplierData);
  };

  // Call the fetch function when the component mounts
  // useEffect(() => {
  //   fetchScope3EmissionsData();
  //   setGroupedData({})
  // }, []);

  // Render the scope3Dashboard
  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#f9f9f9',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "column", // Stack children vertically
      }}
    >
         <Typography variant="h4" sx={{ mb: 1, textAlign: "center" }}>
    Scope 3 Emissions Dashboard
  </Typography>
  <Button 
    variant="contained" 
    color="primary" 
    onClick={() => {
      navigate('/app/displaydashboard', {
        // state: {
        //   selectedPeriod: reportingPeriods, // Pass the selected reporting period
        //   // selectedCompany: company,          // Pass selected company if needed
        // },
      });
    }}
    >
Back to Main Emissions Dashbaord
 </Button>
      <Paper
        elevation={3}
        sx={{
          width: '99%',
          height:'99%',
          padding: 3,
          borderRadius: 2,
        }}
      >
        
        
        {loading && ( 
            <Loader/>
        )}
<Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Centers content horizontally
            alignItems: 'center',    // Centers content vertically
            // p: 2,
            width: '100%', // Use full width of the parent container
          }}
        >
        {initialloading  && (
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Centers content horizontally
              alignItems: 'center',    // Centers content vertically
              p: 2,
              height: "100vh", // Use full width of the parent container,
              // width:"100vh"
            }}
          >
            <Loader 
           loadingText="" 
         />
         </Box>
        
        )}
          {reportingPeriods && reportingPeriods.length > 0 && (
            <FormControl
              variant="outlined"
              sx={{
                width: 300, // Customize width of dropdown
              }}
            >
              <InputLabel>Reporting Period</InputLabel>
              {/* <Select
                value={selectedPeriod}
                onChange={(e) => setSelectedPeriod(e.target.value)}
                label="Reporting Period"
                // disabled={firstReview || sortreview} // Disable if a period has been selected
              >
                {reportingPeriods.map((period, index) => (
                  <MenuItem key={index} value={period}>
                    {period}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          )}
       {/* Company Dropdown based on selected reporting period */}
       {selectedPeriod && periodCompanies.length > 0 && (
        <FormControl variant="outlined"   sx={{
          width: 300, // Customize width of dropdown
        }}>
          <InputLabel>Company</InputLabel>
          <Select value={selectedCompany} onChange={handleCompanyChange} label="Company" >
            {periodCompanies.map((company, index) => (
              <MenuItem key={index} value={company}>
                {company.subsidiary_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      
    </Box>
        {!loading && selectedPeriod && periodCompanies.length > 0 && ( 
        <Grid container spacing={2}>
          {/* Scope3FinalView Section */}
          <Grid item xs={12} md={3}>
            <Paper
              elevation={2}
              sx={{
                // padding: 2,
                backgroundColor: '#ffffff',
                borderRadius: 2,
                height: '100%',
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Final View
              </Typography>
              <Scope3FinalView groupedData={groupedScope3Data}/>
              {/* <Scope3FinalView filteredGroupedData={groupedScope3Data}/> */}

            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
          <Paper
            elevation={2}
            sx={{
            //   padding: 2,
              backgroundColor: '#ffffff',
              borderRadius: 2,
              height: '100%',
            }}
          >
 
        <Box
          sx={{
              // height: "100vh",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#f5f5f5', // Light background
              boxShadow: 3,
              p: 6,
              borderRadius: 2,
              overflow: 'auto', // Allows scrolling if content grows too big
              // maxWidth:'100vh'
              
          }}
          >
                <Scope3CategoriesTable groupedData={groupedScope3Data} />

           
        {/* <SupplierTable filteredGroupedData={groupedScope3Data}/> */}
        </Box>
          </Paper>
          
        </Grid>




          {/* SupplierTable Section */}
          <Grid item xs={12} md={3}>
          
            {/* <Paper
              elevation={2}
              sx={{
                // padding: 2,
                backgroundColor: '#ffffff',
                borderRadius: 2,
                height: '100%',
              }}
            > */}
                 {/* <Typography variant="h6" sx={{ mb: 2 }}>
                Supplier Table
              </Typography> */}
                <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // bgcolor: '#f5f5f5', // Light background
                boxShadow: 3,
                p: 2,
                borderRadius: 2,
                overflow: 'auto', // Allows scrolling if content grows too big
                
            }}
            >
             <SupplierTableforScope3Dash filteredGroupedData={groupedScope3Data}/>
          {/* <SupplierTable filteredGroupedData={groupedScope3Data}/> */}
          </Box>
            {/* </Paper> */}
            
          </Grid>
        </Grid>
        )}
      </Paper>
      
    </Box>
  );
};
export default Scope3Dashboard;