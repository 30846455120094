import React, { useContext, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Switch, FormControlLabel, Typography } from "@mui/material";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { SourceContext } from "../../contexts/Sourcecontext";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Scope1BarChartHorizontal = ({ reportingPeriod }) => {
  const {
    separatedFacilitiesT,
    separatedFleetsT,
    separatedFugitiveemissionsT,
  } = useContext(SourceContext);

  // Map of state data with labels
  const dataMap = {
    "Stationary Combustion": separatedFacilitiesT,
    "Mobile Combustion": separatedFleetsT,
    // "Purchased electricity": separatedelectricityFacilitiesT,
    "Fugitive Emissions": separatedFugitiveemissionsT,
    // "Steam Heating/Cooling emissions": separatedSHCfacilitiesT,
  };
//   console.log("dataMap", dataMap)working
  // Scope mapping
  const scope1Sources = ["Facilities Data", "Fleet Data", "Fugitive Emissions Data"];
  const scope2Sources = ["Electricity Data", "Steam Heating/Cooling Data"];

  // Helper function to filter and calculate emissions for a specific reporting period
  const calculateEmissionsForPeriod = (data, period) => {
    return Object.entries(data).reduce((acc, [key, facilitiesArray]) => {
      // console.log("Key:", key); // Log the key
      const keyParts = key.split("_");
      const keyPeriod = keyParts[keyParts.length - 1];
  
  
      // Normalize both periods for comparison
      if (keyPeriod=== period) {
     
        facilitiesArray.forEach((facility) => {
          const emissions = facility.data.emissions?.reduce(
            (sum, emission) => sum + (emission.eqco2 || 0),
            0
          );
          // console.log("Emissions for facility:", emissions);
          acc += emissions || 0;
        });
      }
      return acc;
    }, 0);
  };
  

  // Calculate emissions and percentages
  const calculateEmissions = (dataMap, period) => {
    const emissions = {};
    Object.keys(dataMap).forEach((key) => {
      emissions[key] = calculateEmissionsForPeriod(dataMap[key], period);
    });
    const totalEmissions = Object.values(emissions).reduce((sum, val) => sum + val, 0);
    const percentages = {};
    Object.keys(emissions).forEach((key) => {
      percentages[key] = ((emissions[key] / totalEmissions) * 100).toFixed(2);
    });
    return { emissions, percentages };
  };

  const { emissions, percentages } = calculateEmissions(dataMap, reportingPeriod);
  // console.log(emissions, percentages) // not working
  // State for toggling between actual and percentage
  const [showPercentage, setShowPercentage] = useState(false);

  // Data for the bar chart
  const chartData = {
    labels: Object.keys(dataMap),
    datasets: [
      {
        label: showPercentage ? "Percentage of Total Emissions (%)" : "Actual Emissions (eqCO2)",
        data: showPercentage
          ? Object.keys(percentages).map((key) => percentages[key])
          : Object.keys(emissions).map((key) => emissions[key]),
        backgroundColor: Object.keys(dataMap).map((key) =>
          scope1Sources.includes(key) ? "rgba(75, 192, 192, 0.6)" : "rgba(153, 102, 255, 0.6)"
        ),
        borderColor: Object.keys(dataMap).map((key) =>
          scope2Sources.includes(key) ? "rgba(75, 192, 192, 1)" : "rgba(153, 102, 255, 1)"
        ),
        borderWidth: 1,
        // borderRadius: 10, // Rounded corners for sleekness
        hoverBackgroundColor: "rgba(255, 159, 64, 0.6)", // Hover effect
        hoverBorderColor: "rgba(255, 159, 64, 1)", // Hover border effect
        barThickness: 30, // Makes the bars thicker for a sleeker look
      },
    ],
  };


  
  // Chart options
  const chartOptions = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: { display: true },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: showPercentage ? "Percentage (%)" : "Emissions (eqCO2)",
        },
      },
    },

  indexAxis: "y", // Horizontal bars
    onClick: (event, elements) => {
      if (elements.length) {
        const clickedIndex = elements[0].index;
        const clickedLabel = chartData.labels[clickedIndex];
        // console.log(`Bar clicked: ${clickedLabel}`);

        // Toggle between showing percentage and emissions
        setShowPercentage(!showPercentage);
      }
    },
  };

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <Typography variant="h6" align="center">
        Emissions Data for Reporting Period: {reportingPeriod}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={showPercentage}
            onChange={() => setShowPercentage((prev) => !prev)}
            color="primary"
          />
        }
        label={showPercentage ? "Show Actual Emissions" : "Show Percentage"}
      />
      <Bar data={chartData} options={chartOptions} />
      
    </div>
  );
};

export default Scope1BarChartHorizontal;
